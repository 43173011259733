import { createAsyncThunk, isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../config/config";
import handleError from "./handleError";

const EService_API = config.EService_API;
const Finance_API = config.Finance_API;

export const authToken = createAsyncThunk(
  "auth/token",
  async (data, { rejectWithValue }) => {
    try {
      const refreshToken = data.refreshToken;
      const url = `${EService_API}/api/auth/finance`;
      const method = "GET";
      const headers = {
        "Content-Type": "application/json",
      };
      if (refreshToken) {
        headers["x-auth-token"] = refreshToken;
      }
      // console.log("headers: " + JSON.stringify(headers));
      try {
        const response = await axios({ url, method, headers });
        if (response.data) {
          const responseData = response.data;
          return responseData;
        }
      } catch (error) {
        handleError(error, rejectWithValue);
      }
    } catch (error) {
      console.log(error);
      const errorM =
        error.response && error.response.data
          ? error.response.data.errorMessage
          : "An unexpected error occurred. Please try again.";

      return rejectWithValue(errorM);
    }
  }
);

export const getDataUser = createAsyncThunk(
  "auth/getDataUser",
  async (data, { rejectWithValue }) => {
    try {
      const token = data.refreshToken;
      const url = `${Finance_API}/api/auth/finance`;
      const method = "POST";
      const headers = {
        "Content-Type": "application/json",
      };
      const body = {
        username: data.username,
        userfk: data.userfk,
      };
      // ถึงนี่
      if (token) {
        headers["x-auth-token"] = token;
      }

      try {
        const response = await axios({ url, method, headers, data: body });
        if (response.data) {
          const responseData = response.data;
          // console.log("responseData: " + JSON.stringify(responseData));
          return responseData;
        }
      } catch (error) {
        console.log(error);
        const errorM =
          error.response && error.response.data
            ? error.response.data.errorMessage
            : "An unexpected error occurred. Please try again.";

        return rejectWithValue(errorM);
      }
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);
