import { saveAs } from "file-saver";

export const generateCSV = (reportData) => {
  let csvContent =
    "data:text/csv;charset=utf-8,budgetYear,,,,,,งบตั้งต้น,การโอน,การเบิก,งบคงเหลือ\n";

  // Add year level data
  csvContent += `${reportData.budgetYear},,,,,,${reportData.yearInitAmount},${
    reportData.yearTransfer?.amount || 0
  },${reportData.yearWithdrawal},${reportData.yearRemainAmount}\n`;

  // Add income level data with proper indentation and null check
  if (reportData.incomeList && Array.isArray(reportData.incomeList)) {
    reportData.incomeList.forEach((income) => {
      csvContent += `,${income.incomeName},,,,,${
        income.incomeInitAmount || 0
      },${income.incomeTransfer?.amount || 0},${income.incomeWithdrawal || 0},${
        income.incomeRemainAmount || 0
      }\n`;

      // Add budget level data with indentation and null check
      if (income.budgetList && Array.isArray(income.budgetList)) {
        income.budgetList.forEach((budget) => {
          csvContent += `,,${budget.budgetName},,,,${
            budget.budgetInitAmount || 0
          },${budget.budgetTransfer?.amount || 0},${
            budget.budgetWithdrawal || 0
          },${budget.budgetRemainAmount || 0}\n`;

          // Add expenditure level data with further indentation
          if (budget.expenditureList && Array.isArray(budget.expenditureList)) {
            budget.expenditureList.forEach((expenditure) => {
              csvContent += `,,,${expenditure.expenditureName},,,${
                expenditure.expenditureInitAmount || 0
              },${expenditure.expenditureTransfer?.amount || 0},${
                expenditure.expenditureWithdrawal || 0
              },${expenditure.expenditureRemainAmount || 0}\n`;

              // Add project level data with additional indentation
              if (
                expenditure.projectList &&
                Array.isArray(expenditure.projectList)
              ) {
                expenditure.projectList.forEach((project) => {
                  csvContent += `,,,,${project.projectName},,${
                    project.projectInitAmount || 0
                  },${project.projectTransfer?.amount || 0},${
                    project.projectWithdrawal || 0
                  },${project.projectRemainAmount || 0}\n`;

                  // Add activity level data with maximum indentation
                  if (
                    project.activityList &&
                    Array.isArray(project.activityList)
                  ) {
                    project.activityList.forEach((activity) => {
                      csvContent += `,,,,,${activity.activityName},${
                        activity.activityInitAmount || 0
                      },${activity.activityTransfer?.amount || 0},${
                        activity.activityWithdrawal || 0
                      },${activity.activityRemainAmount || 0}\n`;
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  // Convert CSV content to a file and trigger download
  const encodedUri = encodeURI(csvContent);
  saveAs(new Blob([decodeURIComponent(encodedUri)]), "report.csv");
};
