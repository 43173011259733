import React, { useState } from "react";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import "./Filter.css"; // Import the CSS file

const Filter = ({
  onFilter,
  availableWithdrawalTypes = [],
  availableStatusStages = [],
  availableStatusNames = [],
}) => {
  const initialFilters = {
    withdrawalName: "",
    withdrawalUsername: "",
    withdrawalType: "",
    amountMin: "",
    amountMax: "",
    statusStage: "",
    statusName: "",
  };

  const [filters, setFilters] = useState(initialFilters);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter(filters);
  };

  const handleReset = () => {
    setFilters(initialFilters);
    onFilter(initialFilters);
  };

  return (
    <div className="withdrawal-filter">
      <Form onSubmit={handleSubmit}>
        <Row form>
          <Col md={4}>
            <FormGroup>
              <Label for="withdrawalName">ชื่อรายการ</Label>
              <Input
                type="text"
                name="withdrawalName"
                id="withdrawalName"
                value={filters.withdrawalName}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="withdrawalUsername">ผู้เบิก</Label>
              <Input
                type="text"
                name="withdrawalUsername"
                id="withdrawalUsername"
                value={filters.withdrawalUsername}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="withdrawalType">ประเภทการเบิก</Label>
              <Input
                type="select"
                name="withdrawalType"
                id="withdrawalType"
                value={filters.withdrawalType}
                onChange={handleInputChange}
              >
                <option value="">เลือกประเภทการเบิก</option>
                {availableWithdrawalTypes.map((type, index) => (
                  <option key={index} value={type}>
                    {type}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={2}>
            <FormGroup>
              <Label for="amountMin">จำนวนเงินเบิก (ต่ำสุด)</Label>
              <Input
                type="number"
                name="amountMin"
                id="amountMin"
                value={filters.amountMin}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={2}>
            <FormGroup>
              <Label for="amountMax">จำนวนเงินเบิก (สูงสุด)</Label>
              <Input
                type="number"
                name="amountMax"
                id="amountMax"
                value={filters.amountMax}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="statusStage">ขั้นตอน</Label>
              <Input
                type="select"
                name="statusStage"
                id="statusStage"
                value={filters.statusStage}
                onChange={handleInputChange}
              >
                <option value="">เลือกขั้นตอน</option>
                {availableStatusStages.map((stage, index) => (
                  <option key={index} value={stage}>
                    {stage}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="statusName">สถานะ</Label>
              <Input
                type="select"
                name="statusName"
                id="statusName"
                value={filters.statusName}
                onChange={handleInputChange}
              >
                <option value="">เลือกสถานะ</option>
                {availableStatusNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Button type="submit" color="primary">
          ค้นหา
        </Button>{" "}
        <Button type="button" color="danger" onClick={handleReset}>
          รีเซ็ต
        </Button>
      </Form>
    </div>
  );
};

export default Filter;
