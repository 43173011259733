import React, { useState } from "react";
import { Button } from "reactstrap";
import DeleteActivityForm from "./DeleteActivityForm";

const DeleteActivityButton = ({
  hasProjects,
  expenditureId,
  refreshProjects,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <Button
        color="danger"
        disabled={!hasProjects}
        style={{ width: "100%", marginBottom: "10px" }}
        outline
        onClick={toggle}
      >
        ลบกิจกรรม
      </Button>
      <DeleteActivityForm
        isOpen={isOpen}
        toggle={toggle}
        expenditureId={expenditureId}
        refreshProjects={refreshProjects}
      />
    </>
  );
};

export default DeleteActivityButton;
