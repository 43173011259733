import React, { useState } from "react";
import {
  ModalContainer,
  ModalHeader,
  ModalFooter,
} from "./Modal/ModalStructure";
import ModalBody from "./Modal/ModalBody";
import ChangeStatusSection from "./Modal/ChangeStatusSection";

const WithdrawalModal = ({ isOpen, toggle, withdrawal, year, session }) => {
  const [activeTab, setActiveTab] = useState("1");

  if (!withdrawal) return null;

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const shouldHideChangeStatus =
    withdrawal.status.name === "เบิกเงินสำเร็จ" ||
    withdrawal.status.name === "อนุมัติการเบิก" ||
    withdrawal.status.name === "ยกเลิกการเบิก";

  return (
    <ModalContainer isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle} title="รายละเอียดการเบิก" />
      <ModalBody
        activeTab={activeTab}
        toggleTab={toggleTab}
        withdrawal={withdrawal}
        year={year}
        session={session}
      />
      {!shouldHideChangeStatus && (
        <ChangeStatusSection withdrawal={withdrawal} />
      )}
      <ModalFooter toggle={toggle} />
    </ModalContainer>
  );
};

export default WithdrawalModal;
