import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Alert,
} from "reactstrap";
import { resetState } from "../reducers/reportReducer";
import TypeReportDropdown from "../components/Report/TypeReportDropdown";
import YearReportSection from "../components/Report/YearReportSection";

const ReportHeader = ({ title }) => {
  return (
    <Row className="mb-4">
      <Col className="text-center">
        <h4>{title}</h4>
      </Col>
    </Row>
  );
};

const ReportPage = () => {
  const dispatch = useDispatch();
  const [reportType, setReportType] = useState("");
  const [reportData, setReportData] = useState(null);
  const { error } = useSelector((state) => state.reportState);
  const { position } = useSelector((state) => state.authState);

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  const handleReportTypeChange = (selectedType) => {
    setReportType(selectedType);
    setReportData(null);
  };

  if (
    position === "ADMIN" ||
    position === "MANAGEMENT" ||
    position === "HEADFINANCE" ||
    position === "PLANNER"
  ) {
    return (
      <Container>
        <Breadcrumb>
          <BreadcrumbItem>
            {" "}
            <a href="/">Home</a>
          </BreadcrumbItem>
          <BreadcrumbItem active>Report</BreadcrumbItem>
        </Breadcrumb>

        <ReportHeader title="รายงานสรุปงบประมาณ" />

        <TypeReportDropdown
          selectedType={reportType}
          onChange={handleReportTypeChange}
        />

        {error && typeof error === "string" && (
          <Alert color="danger">{error}</Alert>
        )}

        {reportType === "yearly" && (
          <YearReportSection
            reportData={reportData}
            setReportData={setReportData}
          />
        )}
      </Container>
    );
  } else {
    return <div>คุณไม่มีสิทธิ์ในการเข้าถึงหน้านี้</div>;
  }
};

export default ReportPage;
