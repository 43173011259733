import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  pdf,
} from "@react-pdf/renderer";

import NotoSansThai from "../../../fonts/NotoSans/NotoSansThai-Regular.ttf";

// Register the Noto Sans Thai font
Font.register({
  family: "NotoSansThai",
  src: NotoSansThai,
});

const styles = StyleSheet.create({
  page: {
    padding: 20,
    size: "A4",
  },
  section: {
    marginBottom: 10,
  },
  table: {
    display: "table",
    width: "100%", // Ensure the table fits the entire page width
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "20%", // Adjusted width for columns to fit within the page
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 4,
    fontFamily: "NotoSansThai",
    fontSize: 8, // Reduced font size to fit more content
    fontWeight: "bold",
    textAlign: "center",
    backgroundColor: "#f0f0f0",
  },
  tableCol: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 4,
    fontFamily: "NotoSansThai",
    fontSize: 8, // Reduced font size for table rows
    textAlign: "right", // Default text alignment for numeric columns
  },
  tableColLeft: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 4,
    fontFamily: "NotoSansThai",
    fontSize: 8, // Reduced font size for names
    textAlign: "left",
  },
  headerText: {
    fontFamily: "NotoSansThai",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: 10,
  },
  nestedRow: {
    paddingLeft: 10, // Slight indentation for nested items
  },
});

const PDFDocument = ({ reportData }) => (
  <Document>
    <Page style={styles.page} size="A4">
      <View style={styles.section}>
        <Text style={styles.headerText}>รายงานงบประมาณ</Text>
        <Text style={styles.headerText}>
          ประจำปีการศึกษา {reportData.budgetYear}
        </Text>
      </View>
      <View style={styles.table}>
        <View style={styles.tableRow}>
          <Text style={styles.tableColHeader}>รายการ</Text>
          <Text style={styles.tableColHeader}>งบตั้งต้น</Text>
          <Text style={styles.tableColHeader}>การโอน</Text>
          <Text style={styles.tableColHeader}>การเบิก</Text>
          <Text style={styles.tableColHeader}>งบคงเหลือ</Text>
        </View>

        {/* Year level */}
        <View style={styles.tableRow}>
          <Text
            style={styles.tableColLeft}
          >{`ปีการศึกษา ${reportData.budgetYear}`}</Text>
          <Text style={styles.tableCol}>{reportData.yearInitAmount || 0}</Text>
          <Text style={styles.tableCol}>
            {reportData.yearTransfer?.amount || 0}
          </Text>
          <Text style={styles.tableCol}>{reportData.yearWithdrawal || 0}</Text>
          <Text style={styles.tableCol}>
            {reportData.yearRemainAmount || 0}
          </Text>
        </View>

        {/* Income List level */}
        {reportData.incomeList.map((income, index) => (
          <React.Fragment key={index}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableColLeft, styles.nestedRow]}>
                {income.incomeName}
              </Text>
              <Text style={styles.tableCol}>
                {income.incomeInitAmount || 0}
              </Text>
              <Text style={styles.tableCol}>
                {income.incomeTransfer?.amount || 0}
              </Text>
              <Text style={styles.tableCol}>
                {income.incomeWithdrawal || 0}
              </Text>
              <Text style={styles.tableCol}>
                {income.incomeRemainAmount || 0}
              </Text>
            </View>

            {/* Budget List level */}
            {income.budgetList &&
              income.budgetList.map((budget) => (
                <React.Fragment key={budget.budgetId}>
                  <View style={styles.tableRow}>
                    <Text
                      style={[
                        styles.tableColLeft,
                        styles.nestedRow,
                        { paddingLeft: 20 },
                      ]}
                    >
                      {budget.budgetName}
                    </Text>
                    <Text style={styles.tableCol}>
                      {budget.budgetInitAmount || 0}
                    </Text>
                    <Text style={styles.tableCol}>
                      {budget.budgetTransfer?.amount || 0}
                    </Text>
                    <Text style={styles.tableCol}>
                      {budget.budgetWithdrawal || 0}
                    </Text>
                    <Text style={styles.tableCol}>
                      {budget.budgetRemainAmount || 0}
                    </Text>
                  </View>

                  {/* Expenditure List level */}
                  {budget.expenditureList &&
                    budget.expenditureList.map((expenditure) => (
                      <React.Fragment key={expenditure.expenditureId}>
                        <View style={styles.tableRow}>
                          <Text
                            style={[
                              styles.tableColLeft,
                              styles.nestedRow,
                              { paddingLeft: 30 },
                            ]}
                          >
                            {expenditure.expenditureName}
                          </Text>
                          <Text style={styles.tableCol}>
                            {expenditure.expenditureInitAmount || 0}
                          </Text>
                          <Text style={styles.tableCol}>
                            {expenditure.expenditureTransfer?.amount || 0}
                          </Text>
                          <Text style={styles.tableCol}>
                            {expenditure.expenditureWithdrawal || 0}
                          </Text>
                          <Text style={styles.tableCol}>
                            {expenditure.expenditureRemainAmount || 0}
                          </Text>
                        </View>

                        {/* Project List level */}
                        {expenditure.projectList &&
                          expenditure.projectList.map((project) => (
                            <React.Fragment key={project.projectId}>
                              <View style={styles.tableRow}>
                                <Text
                                  style={[
                                    styles.tableColLeft,
                                    styles.nestedRow,
                                    { paddingLeft: 40 },
                                  ]}
                                >
                                  {project.projectName}
                                </Text>
                                <Text style={styles.tableCol}>
                                  {project.projectInitAmount || 0}
                                </Text>
                                <Text style={styles.tableCol}>
                                  {project.projectTransfer?.amount || 0}
                                </Text>
                                <Text style={styles.tableCol}>
                                  {project.projectWithdrawal || 0}
                                </Text>
                                <Text style={styles.tableCol}>
                                  {project.projectRemainAmount || 0}
                                </Text>
                              </View>

                              {/* Activity List level */}
                              {project.activityList &&
                                project.activityList.map((activity) => (
                                  <View
                                    key={activity.activityId}
                                    style={styles.tableRow}
                                  >
                                    <Text
                                      style={[
                                        styles.tableColLeft,
                                        styles.nestedRow,
                                        { paddingLeft: 50 },
                                      ]}
                                    >
                                      {activity.activityName}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                      {activity.activityInitAmount || 0}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                      {activity.activityTransfer?.amount || 0}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                      {activity.activityWithdrawal || 0}
                                    </Text>
                                    <Text style={styles.tableCol}>
                                      {activity.activityRemainAmount || 0}
                                    </Text>
                                  </View>
                                ))}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </View>
    </Page>
  </Document>
);

export const generatePDF = async (reportData) => {
  const blob = await pdf(<PDFDocument reportData={reportData} />).toBlob();

  const url = URL.createObjectURL(blob);
  window.open(url); // Open the PDF in a new tab
};
