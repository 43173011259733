import React, { Fragment, useEffect, useState } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Alert,
} from "reactstrap";

const AmountRequestIn = ({ formData, handleFormDataChange, formDataOut }) => {
  const [error, setError] = useState("");

  useEffect(() => {
    if (formDataOut.amount_request) {
      handleFormDataChange({ amount_request: formDataOut.amount_request });
    }
  }, [formDataOut]);

  return (
    <Fragment>
      {(formData.transfer_type === "expenditure" && formData.expenditure) ||
      (formData.transfer_type === "activity" && formData.activity) ? (
        <Card className="mb-4">
          <CardBody>
            <CardTitle tag="h3" className="mb-3">
              ข้อมูลจำนวนเงินโอนเข้า
            </CardTitle>
            <FormGroup>
              <Label for="transfer_name">ชื่อรายการโอนเข้า</Label>
              <Input
                type="text"
                name="transfer_name"
                id="transfer_name"
                value={formData.transfer_name || ""}
                onChange={(e) =>
                  handleFormDataChange({ transfer_name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="amount_request">จำนวนเงินโอนเข้า</Label>
              <Input
                type="number"
                name="amount_request"
                id="amount_request"
                value={formDataOut.amount_request || ""}
                disabled
              />
            </FormGroup>

            <FormGroup>
              <Label for="description">
                * เมื่อใส่จำนวนเบิกแล้ว ให้คลิกปุ่ม "ไปหน้าข้อมูลโอนเข้า"
                เพื่อไปยัง Step ถัดไป
              </Label>
            </FormGroup>
          </CardBody>
        </Card>
      ) : null}
    </Fragment>
  );
};

export default AmountRequestIn;
