import React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from "reactstrap";

const TransferModal = ({
  isOpen,
  toggle,
  transfer,
  transferYears,
  transferSessions,
}) => {
  if (!transfer) return null;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = (date.getFullYear() + 543).toString(); // Convert to Buddhist year
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>รายละเอียดการโอน</ModalHeader>
      <ModalBody>
        <Card className="mt-3">
          <CardBody>
            <CardTitle tag="h5">ข้อมูลการโอน</CardTitle>
            <CardText>วันเวลาที่โอน: {formatDate(transfer.createdAt)}</CardText>
            <CardText>ผู้โอน: {transfer.username}</CardText>
            <CardText>รายการโอน: {transfer.transfer_name}</CardText>
            <CardText>ประเภทการโอน: {transfer.transfer_type}</CardText>
            <CardText>จำนวนเงินที่โอน: {transfer.transfer_amount}</CardText>
          </CardBody>
        </Card>
        <br />
        <Card>
          <CardBody className="mt-3">
            <CardTitle tag="h5">รายละเอียดงบประมาณ</CardTitle>
            <CardText>ปีการศึกษา: {transferYears}</CardText>
            <CardText>ปีงบประมาณ: {transferSessions}</CardText>
            <CardText>แหล่งงบประมาณ: {transfer.income_name}</CardText>
            <CardText>ฝ่ายที่จัดงบประมาณ: {transfer.budget_name}</CardText>
            <CardText>รายจ่าย: {transfer.expenditure_name}</CardText>
            {(transfer.transfer_type === "โอนกิจกรรมออก" ||
              transfer.transfer_type === "โอนกิจกรรมเข้า") && (
              <>
                <CardText>โครงการ: {transfer.project_name}</CardText>
                <CardText>กิจกรรม: {transfer.activity_name}</CardText>
              </>
            )}
          </CardBody>
        </Card>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          ปิด
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TransferModal;
