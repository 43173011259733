import React from "react";
import { formatDate, getStatusStyles, getStageStyles } from "./utils";

const TableRow = ({ item, showUsername, showWithdrawalType, openModal }) => {
  return (
    <tr>
      <td>{formatDate(item.createdAt)}</td>
      <td>{item.withdrawal_name}</td>
      {showUsername && <td>{item.withdrawal_username}</td>}
      {showWithdrawalType && <td>{item.withdrawal_type}</td>}
      <td>{item.amount_actual || item.amount_request}</td>
      <td>
        <div
          style={{ ...getStageStyles(item.status.stage), marginBottom: "8px" }}
        >
          {item.status.stage}
        </div>
        <div style={getStatusStyles(item.status.name)}>{item.status.name}</div>
      </td>
      <td>
        <button className="detail-button" onClick={() => openModal(item)}>
          รายละเอียด
        </button>
      </td>
    </tr>
  );
};

export default TableRow;
