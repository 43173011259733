import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Pagination from "./Pagination";
import ErrorHandling from "./ErrorHandling";
import NoData from "./NoData";
import YearField from "./YearField";
import SessionField from "./SessionField";
import Table from "./Table";
import { fetchTransfers, RESET_TRANSFERS } from "../../../actions/listAction";

const UserRoleView = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Fixed value can be declared outside of state
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedSession, setSelectedSession] = useState("");

  const dispatch = useDispatch();
  const { position } = useSelector((state) => state.authState);
  const { data: dataList } = useSelector((state) => state.listState.transfers);

  const hasPermission = [
    "ADMIN",
    "MANAGEMENT",
    "HEADFINANCE",
    "FINANCE",
    "PLANNER",
    "PROCUREMENT",
  ].includes(position);

  useEffect(() => {
    dispatch({ type: RESET_TRANSFERS });
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedYear || !selectedSession) {
        setError("Please select both year and session to fetch data.");
        return;
      }

      if (
        !["ADMIN", "MANAGEMENT", "HEADFINANCE", "PLANNER"].includes(position)
      ) {
        setError("You don't have permission to access this data.");
        return;
      }

      try {
        setLoading(true);
        await dispatch(
          fetchTransfers({
            budget_year: selectedYear,
            session: selectedSession,
          })
        );
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, position, selectedYear, selectedSession]);

  useEffect(() => {
    if (Array.isArray(dataList)) {
      setData(dataList);
    } else {
      console.error("Expected transferList to be an array, but got:", dataList);
      setData([]);
    }
  }, [dataList]);

  const currentItems = data.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  if (!hasPermission) {
    return <p>Not authorized</p>;
  }

  return (
    <Fragment>
      <YearField
        selectedYear={selectedYear}
        setSelectedYear={setSelectedYear}
      />
      <SessionField
        selectedSession={selectedSession}
        setSelectedSession={setSelectedSession}
      />

      {loading && <div>Loading...</div>}

      {!loading &&
        selectedYear &&
        selectedSession &&
        (data.length > 0 ? (
          <Fragment>
            <Table
              data={currentItems}
              transferYears={selectedYear}
              transferSessions={selectedSession}
            />
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={data.length}
              paginate={setCurrentPage}
              currentPage={currentPage}
            />
          </Fragment>
        ) : (
          <NoData />
        ))}

      {error && <ErrorHandling message={error} />}
    </Fragment>
  );
};

export default UserRoleView;
