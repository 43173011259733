import React from "react";
import { Table } from "reactstrap";
import Alert from "../../config/Alert";

const Activities = ({ activities }) => (
  <div>
    <h5>กิจกรรม {activities.length > 0 && `(${activities.length} รายการ)`}</h5>
    {activities.length === 0 ? (
      <Alert color="warning">ไม่พบกิจกรรม</Alert>
    ) : (
      <Table bordered>
        <thead>
          <tr>
            <th>ชื่อกิจกรรม</th>
            <th>ครูที่รับผิดชอบ</th>
            <th>งบตั้งต้น</th>
            <th>งบคงเหลือ</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => (
            <tr key={activity.activity_id}>
              <td>{activity.activity_name}</td>
              <td>{activity.res_teacher}</td>
              <td>{activity.init_amount.toLocaleString()}</td>
              <td>{activity.remain_amount.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}
  </div>
);

export default Activities;
