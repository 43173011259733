import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchWithdrawalYears } from "../../../actions/listAction";
import { FormGroup, Label, Input, Spinner } from "reactstrap";

const YearField = ({ selectedYear, setSelectedYear }) => {
  const dispatch = useDispatch();
  const { years, isLoading, error } = useSelector(
    (state) => state.listState.withdrawals
  );

  useEffect(() => {
    dispatch(fetchWithdrawalYears());
  }, [dispatch]);

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
  };

  return (
    <FormGroup>
      <Label for="year">ปีการศึกษา</Label>
      {isLoading ? (
        <Spinner color="primary" />
      ) : (
        <Fragment>
          {error ? (
            <p className="text-danger">Error loading years: {error.error}</p>
          ) : (
            <Input
              type="select"
              name="year"
              id="year"
              value={selectedYear}
              onChange={handleChange}
            >
              <option value="">เลือกปีการศึกษา</option>
              {years?.years &&
                years.years.map((years, index) => (
                  <option key={index} value={years}>
                    {years}
                  </option>
                ))}
            </Input>
          )}
        </Fragment>
      )}
    </FormGroup>
  );
};

export default YearField;
