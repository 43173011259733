import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessionsByYear } from "../../../actions/budget/fetchAction";
import { FormGroup, Label, Input, Spinner, Alert } from "reactstrap";

const SessionField = ({
  selectedYear,
  selectedSession,
  setSelectedSession,
}) => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector(
    (state) => state.budgetState.sessions
  );
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    if (selectedYear) {
      dispatch(
        fetchSessionsByYear({
          year: selectedYear,
          accessToken: authData.accessToken,
        })
      );
    }
  }, [dispatch, selectedYear, authData]);

  const handleChange = (e) => {
    setSelectedSession(e.target.value);
  };

  return (
    <FormGroup>
      <Label for="selectSession">เลือกปีงบประมาณ</Label>
      {isLoading && <Spinner color="primary" />}
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="selectSession"
        id="selectSession"
        value={selectedSession}
        onChange={handleChange}
        disabled={isLoading}
      >
        <option value="">กรุณาเลือกปีงบประมาณ</option>
        {data.map((session) => (
          <option key={session._id} value={session.session}>
            {session.session}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default SessionField;
