import React, { Fragment } from "react";
import { Form, FormGroup, Label, Input, Row, Col, Button } from "reactstrap";

const Filters = ({
  level,
  setLevel,
  initMin,
  setInitMin,
  initMax,
  setInitMax,
  remainMin,
  setRemainMin,
  remainMax,
  setRemainMax,
}) => {
  const resetFilters = () => {
    setLevel("");
    setInitMin("");
    setInitMax("");
    setRemainMin("");
    setRemainMax("");
  };

  return (
    <Fragment>
      <Form>
        <FormGroup>
          <Label for="level">ระดับการศึกษา</Label>
          <Input
            type="select"
            name="level"
            id="level"
            defaultValue=""
            onChange={(e) => setLevel(e.target.value)}
          >
            <option value="">ทั้งหมด</option>
            <option value="PRIMARY">ประถมศึกษา</option>
            <option value="KINDERGARTEN">อนุบาล</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>งบตั้งต้น</Label>
          <Row>
            <Col>
              <Input
                type="number"
                name="initMin"
                id="initMin"
                placeholder="ขั้นต่ำ"
                defaultValue={initMin !== undefined ? initMin : ""}
                onChange={(e) => setInitMin(e.target.value)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name="initMax"
                id="initMax"
                placeholder="ขั้นสูง"
                defaultValue={initMax !== undefined ? initMax : ""}
                onChange={(e) => setInitMax(e.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>
        <FormGroup>
          <Label>งบคงเหลือ</Label>
          <Row>
            <Col>
              <Input
                type="number"
                name="remainMin"
                id="remainMin"
                placeholder="ขั้นต่ำ"
                defaultValue={remainMin !== undefined ? remainMin : ""}
                onChange={(e) => setRemainMin(e.target.value)}
              />
            </Col>
            <Col>
              <Input
                type="number"
                name="remainMax"
                id="remainMax"
                placeholder="ขั้นสูง"
                defaultValue={remainMax !== undefined ? remainMax : ""}
                onChange={(e) => setRemainMax(e.target.value)}
              />
            </Col>
          </Row>
        </FormGroup>

        <div className="text-center">
          <Button
            size="sm"
            color="secondary"
            onClick={resetFilters}
            className="w-100"
          >
            รีเซต
          </Button>
        </div>
      </Form>
    </Fragment>
  );
};

export default Filters;
