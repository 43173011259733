import React, { useState } from "react";
import TransferModal from "./TransferModal";
import "./Table.css";

const Table = ({ data, transferYears, transferSessions }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = (date.getFullYear() + 543).toString(); // Convert to Buddhist year
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month}/${year}, ${hours}:${minutes}`;
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleDetailClick = (transfer) => {
    setSelectedTransfer(transfer);
    toggleModal();
  };

  return (
    <div className="transfer-table">
      <table>
        <thead>
          <tr>
            <th>วันเวลาที่โอน</th>
            <th>ชื่อการโอน</th>
            <th>ประเภทการโอน</th>
            <th>ยอดโอน (บาท)</th>
            <th>รายละเอียด</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data.length > 0 &&
            data.map((item, index) => (
              <tr key={index}>
                <td>{formatDate(item.createdAt)}</td>
                <td>{item.transfer_name}</td>
                <td>{item.transfer_type}</td>
                <td>{item.transfer_amount}</td>
                <td>
                  <button
                    className="detail-button"
                    onClick={() => handleDetailClick(item)}
                  >
                    รายละเอียด
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {selectedTransfer && (
        <TransferModal
          isOpen={modalOpen}
          toggle={toggleModal}
          transfer={selectedTransfer}
          transferYears={transferYears}
          transferSessions={transferSessions}
        />
      )}
    </div>
  );
};

export default Table;
