import { createAction, combineReducers } from "@reduxjs/toolkit";
import yearsReducer from "./budget/yearsBudget";
import sessionsReducer from "./budget/sessionsBudget";
import incomeTypesReducer from "./budget/incomeTypesBudget";
import budgetItemsReducer from "./budget/budgetItemsBudget";
import expenditureReducer from "./budget/expenditureBudget";
import projectReducer from "./budget/projectBudget";

export const resetBudget = createAction("budget/resetBudget");

const budgetReducer = combineReducers({
  years: yearsReducer,
  sessions: sessionsReducer,
  incomeTypes: incomeTypesReducer,
  budgetItems: budgetItemsReducer,
  expenditure: expenditureReducer,
  project: projectReducer,
});

export default budgetReducer;
