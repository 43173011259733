import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectDetails } from "../../actions/budget/fetchAction";
import {
  addProject,
  addActivity,
  deleteProject,
  deleteActivity,
} from "../../actions/budget/addAction";

import { resetBudget } from "../budgetReducer";

const initialState = {
  success: false,
  data: [],
  isLoading: false,
  error: null,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjectDetails.pending, (state) => {
        state.success = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchProjectDetails.fulfilled, (state, action) => {
        state.success = false;
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchProjectDetails.rejected, (state, action) => {
        state.success = false;
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(addProject.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(addProject.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addActivity.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addActivity.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(addActivity.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteProject.pending, (state) => {
        state.isLoading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteProject.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(deleteActivity.pending, (state) => {
        state.isLoading = true;
        state.success = false;
        state.error = null;
      })
      .addCase(deleteActivity.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteActivity.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      })
      .addCase(resetBudget, () => initialState);
  },
});

export default projectSlice.reducer;
