import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../config/config";
const Finance_API = config.Finance_API;

// Fetch Yearly Report
export const fetchYearReport = createAsyncThunk(
  "report/fetchYearReport",
  async ({ budget_year, session, date }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const response = await axios.post(
        `${Finance_API}/api/report/year`,
        { budget_year, session, date },
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
