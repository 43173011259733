import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import Alert from "../../config/Alert";

Chart.register(ArcElement, Tooltip, Legend);

const ProjectBudgetChart = ({ projectData, totalBudget }) => {
  if (!projectData || !totalBudget) {
    return (
      <Alert color="danger">ข้อมูลโครงการหรือข้อมูลงบประมาณไม่ถูกต้อง</Alert>
    );
  }

  const projectNames = projectData.map((project) => project.project_name);
  const projectBudgets = projectData.map((project) =>
    project.activity_list.reduce(
      (total, activity) => total + activity.init_amount,
      0
    )
  );

  const totalProjectBudget = projectBudgets.reduce((acc, cur) => acc + cur, 0);
  const remainingBudget = totalBudget - totalProjectBudget;

  const data = {
    labels: [...projectNames, "งบที่ยังไม่ได้จัดสรร"],
    datasets: [
      {
        data: [...projectBudgets, remainingBudget],
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
          "#C0C0C0", // Color for the remaining budget
        ],
      },
    ],
  };

  if (projectBudgets.length === 0) {
    return <Alert color="warning">ไม่พบข้อมูลงบประมาณกิจกรรมในโครงการ</Alert>;
  }

  return (
    <div>
      <h5>แผนภูมิวงกลมงบประมาณโครงการ</h5>
      <p>งบตั้งต้นทั้งหมดของรายจ่าย: {totalBudget}</p>
      <div style={{ maxWidth: "400px", margin: "0 auto" }}>
        <Pie data={data} />
      </div>
    </div>
  );
};

export default ProjectBudgetChart;
