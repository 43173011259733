import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Button,
} from "reactstrap";
import BasicInfo from "../BasicInfo";
import WithdrawalType from "./WithdrawalType";
import Year from "./Year";
import Session from "./Session";
import IncomeType from "./IncomeType";
import Budget from "./Budget";
import Expenditure from "./Expenditure";
import Project from "./Project";
import Activity from "./Activity";
import AmountRequest from "./AmountRequest";
import { resetBudget } from "../../../reducers/budgetReducer";

const Step1Form = ({ formData, handleFormDataChange }) => {
  const dispatch = useDispatch();
  const [formState, setFormState] = useState(formData);
  const [resetFields, setResetFields] = useState(false);

  const handleFormStateChange = (newData) => {
    if (
      newData.withdrawal_type &&
      newData.withdrawal_type !== formState.withdrawal_type
    ) {
      // Reset expenditure-related fields when withdrawal_type changes
      newData.expenditure = "";
      newData.expenditure_name = "";
      newData.expenditure_remain = "";
      newData.project = "";
      newData.project_name = "";
      newData.activity = "";
      newData.activity_name = "";
      newData.activity_remain = "";
      newData.withdrawal_name = "";
      newData.amount_request = "";
    }
    setFormState((prevState) => ({
      ...prevState,
      ...newData,
    }));
    handleFormDataChange({ ...formState, ...newData });
  };

  useEffect(() => {
    console.log("formState: ", formState);
  }, [formState]);

  const handleReset = () => {
    const resetData = {
      withdrawal_type: "",
      year: "",
      year_name: "",
      session: "",
      session_name: "",
      incometype: "",
      incometype_name: "",
      budget: "",
      expenditure: "",
      expenditure_name: "",
      expenditure_remain: "",
      project: "",
      project_name: "",
      activity: "",
      activity_name: "",
      activity_remain: "",
      withdrawal_name: "",
      amount_request: "",
    };
    setFormState(resetData);
    handleFormDataChange(resetData);
    dispatch(resetBudget());
    setResetFields(!resetFields);
  };

  const isFormEmpty = !(
    formState.withdrawal_type ||
    formState.year ||
    formState.year_name ||
    formState.session ||
    formState.session_name ||
    formState.incometype ||
    formState.incometype_name ||
    formState.budget ||
    formState.expenditure ||
    formState.expenditure_name ||
    formState.expenditure_remain ||
    formState.project ||
    formState.project_name ||
    formState.activity ||
    formState.activity_name ||
    formState.activity_remain ||
    formState.withdrawal_name ||
    formState.amount_request
  );

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-container mb-4">
            <CardBody>
              <CardTitle tag="h2" className="text-center mb-4">
                ส่วนที่ 1:รายละเอียดการเบิก
              </CardTitle>
              <BasicInfo />
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h3" className="mb-3">
                    ข้อมูลงบประมาณ{" "}
                    <Button
                      color="danger"
                      size="sm"
                      className="float-right"
                      onClick={handleReset}
                      disabled={isFormEmpty}
                    >
                      รีเซ็ต
                    </Button>
                  </CardTitle>
                  <Form>
                    <WithdrawalType
                      formData={formState}
                      handleFormDataChange={handleFormStateChange}
                    />
                    {formState.withdrawal_type && (
                      <>
                        <Year
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                          resetFields={resetFields}
                        />
                        <Session
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        <IncomeType
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        <Budget
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        <Expenditure
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        {formState.withdrawal_type === "activity" && (
                          <>
                            <Project
                              formData={formState}
                              handleFormDataChange={handleFormStateChange}
                            />
                            <Activity
                              formData={formState}
                              handleFormDataChange={handleFormStateChange}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
              <AmountRequest
                formData={formState}
                handleFormDataChange={handleFormStateChange}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Step1Form;
