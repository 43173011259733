import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AddProjectButton from "./AddProjectButton";
import AddActivityButton from "./AddActivityButton";
import DeleteActivityButton from "./DeleteActivityButton";
import DeleteProjectButton from "./DeleteProjectButton";

const LeftSidebar = ({
  details,
  hasProjects,
  expenditureId,
  refreshProjects,
}) => {
  return (
    <div>
      <AddProjectButton
        expenditureId={expenditureId}
        refreshProjects={refreshProjects}
      />
      <DeleteProjectButton
        hasProjects={hasProjects}
        details={details}
        refreshProjects={refreshProjects}
      />
      <AddActivityButton
        hasProjects={hasProjects}
        expenditureId={expenditureId}
        refreshProjects={refreshProjects}
      />
      <DeleteActivityButton
        hasProjects={hasProjects}
        expenditureId={expenditureId}
        refreshProjects={refreshProjects}
      />
    </div>
  );
};

export default LeftSidebar;
