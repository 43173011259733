import { createSlice } from "@reduxjs/toolkit";
import {
  fetchBudgetYears,
  fetchBudgetYearsOnlyOpen,
} from "../../actions/budget/fetchAction";
import {
  addBudgetYear,
  changeStatusBudgetYear,
} from "../../actions/budget/addAction";
import { resetBudget } from "../budgetReducer";

const initialState = {
  data: [],
  changeStatus: false,
  isLoading: false,
  error: null,
};

const yearsSlice = createSlice({
  name: "years",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBudgetYears.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBudgetYears.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchBudgetYears.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchBudgetYearsOnlyOpen.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBudgetYearsOnlyOpen.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchBudgetYearsOnlyOpen.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addBudgetYear.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addBudgetYear.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addBudgetYear.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(changeStatusBudgetYear.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changeStatusBudgetYear.fulfilled, (state) => {
        state.isLoading = false;
        state.changeStatus = true;
      })
      .addCase(changeStatusBudgetYear.rejected, (state, action) => {
        state.isLoading = false;
        state.changeStatus = false;
        state.error = action.payload;
      })
      .addCase(resetBudget, () => initialState); // Reset state
  },
});

export default yearsSlice.reducer;
