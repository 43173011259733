import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBudgetYears } from "../../actions/budget/fetchAction";
import { FormGroup, Label, Input, Form, Alert, Spinner } from "reactstrap";
import SelectSessionField from "./SelectSessionField";

const SelectYearField = () => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector(
    (state) => state.budgetState.years
  );
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    dispatch(fetchBudgetYears({ accessToken: authData.accessToken }));
  }, [dispatch, authData]);

  const [selectedYear, setSelectedYear] = useState("");

  const handleChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const uniqueYears = Array.from(new Set(data.map((item) => item.budget_year)));

  return (
    <>
      <Form>
        <FormGroup>
          <Label for="selectYear">เลือกปีการศึกษา</Label>
          {isLoading && <Spinner color="primary" />}
          {error && <Alert color="danger">{error}</Alert>}
          <Input
            type="select"
            name="selectYear"
            id="selectYear"
            value={selectedYear}
            onChange={handleChange}
            disabled={isLoading}
          >
            <option value="">กรุณาเลือกปีการศึกษา</option>
            {uniqueYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Form>
      {selectedYear && <SelectSessionField year={selectedYear} />}
    </>
  );
};

export default SelectYearField;
