import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Spinner } from "reactstrap";
import { fetchBudgetItemList } from "../../../actions/budget/fetchAction";
import Alert from "../../../config/Alert";

const Budget = ({ formData, handleFormDataChange }) => {
  const dispatch = useDispatch();
  const {
    data: budgetItems,
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.budgetItems);
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    if (formData.year && formData.session_name && formData.incometype) {
      dispatch(
        fetchBudgetItemList({
          group_year_fk: formData.year,
          session_fk: formData.session_name,
          group_income_fk: formData.incometype,
          accessToken: authData.accessToken,
        })
      );
    }
  }, [
    dispatch,
    formData.year,
    formData.session_name,
    formData.incometype,
    authData.accessToken,
  ]);

  const handleChange = (e) => {
    handleFormDataChange({
      budget: e.target.value,
      expenditure: "",
      expenditure_name: "",
      expenditure_remain: "",
      project: "",
      project_name: "",
      activity: "",
      activity_name: "",
      activity_remain: "",
      transfer_name: "",
      amount_request: "",
    });
  };

  return (
    <FormGroup>
      <Label for="budget">
        ฝ่ายที่เบิกงบประมาณ {isLoading && <Spinner size="sm" color="primary" />}
      </Label>
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="budget"
        id="budget"
        value={formData.budget || ""}
        onChange={handleChange}
        disabled={isLoading}
      >
        {formData.budget ? null : (
          <option value="">กรุณาเลือกฝ่ายที่เบิกงบประมาณ</option>
        )}

        {budgetItems.map((item) => (
          <option key={item.budget} value={item.budget}>
            {item.budget}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default Budget;
