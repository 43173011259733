import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { config } from "../config/config";
const EService_Client = config.EService_Client;

const NotAllow = () => {
  return (
    <Fragment>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>
              ไม่อนุญาตให้เข้าใช้งานเนื่องจาก Session หมดอายุ
              โปรดปิดหน้าต่างนี้และ Login ใหม่ที่{" "}
              <a
                href={EService_Client}
                target="_blank"
                rel="noopener noreferrer"
              >
                link
              </a>
            </h1>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default NotAllow;
