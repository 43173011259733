import React, { useState } from "react";
import { Button } from "reactstrap";
import AddProjectApprovalModal from "./AddProjectApprovalModal";

const AddProjectApprovalButton = ({
  yearId,
  sessionValue,
  sessionId,
  incomeTypeId,
  budgetName,
  expenditureItems,
  budgetId,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button
        size="sm"
        color="primary"
        onClick={toggle}
        className="mb-2"
        outline
        block
      >
        อนุมัติจัดตั้งโครงการ
      </Button>
      <AddProjectApprovalModal
        isOpen={modal}
        toggle={toggle}
        budgetName={budgetName}
        yearId={yearId}
        sessionValue={sessionValue}
        sessionId={sessionId}
        incomeTypeId={incomeTypeId}
        expenditureItems={expenditureItems}
        budgetId={budgetId}
      />
    </div>
  );
};

export default AddProjectApprovalButton;
