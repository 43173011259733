import React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

const TypeReportDropdown = ({ selectedType, onChange }) => {
  return (
    <Row className="mb-4">
      <Col md={4}>
        <FormGroup>
          <Label for="reportType">เลือกประเภทของรายงาน</Label>
          <Input
            type="select"
            name="reportType"
            id="reportType"
            value={selectedType}
            onChange={(e) => onChange(e.target.value)}
          >
            <option value="">โปรดเลือกประเภทรายงาน</option>
            <option value="yearly">รายงานปีการศึกษา</option>
            {/* เพิ่มตัวเลือกอื่น ๆ ในอนาคต */}
          </Input>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default TypeReportDropdown;
