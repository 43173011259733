import React from "react";
import Header from "./Header";
import UserRoleView from "./UserRoleView";
import "./WithdrawalList.css";

const WithdrawalList = () => {
  return (
    <div className="withdrawal-list">
      <br />
      <Header />
      <UserRoleView />
    </div>
  );
};

export default WithdrawalList;
