import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetAuth } from "../../reducers/authReducer";

const UserNavbar = () => {
  const authData = useSelector((state) => state.authState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(resetAuth());
    navigate("/");
  };

  return (
    <Nav className="ms-auto" navbar>
      {authData && authData.username ? (
        <>
          <NavItem>
            <NavLink href="/profile">
              <i className="bi bi-person-vcard fw-bold px-2" /> {authData.title}{" "}
              {authData.firstname} {authData.lastname}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="#" onClick={handleLogout}>
              <i className="bi bi-box-arrow-right fw-bold px-2" /> Logout
            </NavLink>
          </NavItem>
        </>
      ) : null}
    </Nav>
  );
};

export default UserNavbar;
