import React from "react";
import { TabPane, Card, CardBody, CardTitle, CardText } from "reactstrap";

const BudgetDetails = ({ withdrawal, year, session }) => (
  <TabPane tabId="2">
    <Card className="mt-3">
      <CardBody>
        <CardTitle tag="h5">ข้อมูลงบประมาณ</CardTitle>
        <CardText>ปีการศึกษา: {year}</CardText>
        <CardText>ปีงบประมาณ: {session}</CardText>
        <CardText>แหล่งงบประมาณ: {withdrawal.income_group}</CardText>
        <CardText>ฝ่ายที่ใช้งบประมาณ: {withdrawal.budget_name}</CardText>
        <CardText>รายจ่าย: {withdrawal.expenditure_name}</CardText>
        <CardText>
          ระดับชั้น:{" "}
          {withdrawal.level === "PRIMARY"
            ? "ประถมศึกษา"
            : withdrawal.level === "KINDERGARTEN"
            ? "อนุบาล"
            : "อื่นๆ"}
        </CardText>
        <CardText>โครงการ: {withdrawal.project_name}</CardText>
        <CardText>กิจกรรม: {withdrawal.activity_name}</CardText>
      </CardBody>
    </Card>
  </TabPane>
);

export default BudgetDetails;
