import React from "react";
import { FormGroup, Label } from "reactstrap";
import { ThaiDatePicker } from "thaidatepicker-react";
import { format } from "date-fns";

const DateField = ({
  selectedDate = null,
  setSelectedDate = () => {},
  label = "วันที่เลือก",
}) => {
  const handleDateChange = (date) => {
    if (!date) {
      setSelectedDate("");
      return;
    }

    // แปลงวันที่เป็นฟอร์แมต YYYY-MM-DD ก่อน
    const formattedDate = format(date, "yyyy-MM-dd");

    // ส่งค่าที่ได้ไปยังฟังก์ชัน setSelectedDate โดยตรง (ไม่ต้องปรับปี)
    setSelectedDate(formattedDate);
  };

  return (
    <FormGroup>
      <Label for="datePicker">{label}</Label>
      <ThaiDatePicker
        id="datePicker"
        value={selectedDate ? new Date(selectedDate) : ""}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        className="form-control"
        showYearDropdown
        scrollableYearDropdown
      />
    </FormGroup>
  );
};

export default DateField;
