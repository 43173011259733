const dev = {
  Version: "dev v.1.0",
  EService_API: "http://119.59.99.186:4110",
  Finance_API: "http://119.59.99.186:4111",
  EService_Client: "http://119.59.99.186:4010",
};

const production = {
  Version: "production v.1.0",
  EService_API: "https://service.watplong.ac.th",
  Finance_API: "https://finance.watplong.ac.th",
  EService_Client: "https://service.watplong.ac.th",
};

export const config = process.env.NODE_ENV === "development" ? dev : production;
