import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const TransferType = ({ formData, handleFormDataChange }) => {
  const handleChange = (e) => {
    handleFormDataChange({ transfer_type: e.target.value });
  };

  return (
    <FormGroup>
      <Label for="transferType">รูปแบบการโอน</Label>
      <Input
        type="select"
        name="transferType"
        id="transferType"
        value={formData.transfer_type || ""}
        onChange={handleChange}
      >
        {formData.transfer_type ? null : (
          <option value="">กรุณาเลือกรูปแบบการโอน</option>
        )}
        {/* <option value="expenditure">โอนรายจ่าย</option> */}
        <option value="activity">โอนกิจกรรม</option>
      </Input>
    </FormGroup>
  );
};

export default TransferType;
