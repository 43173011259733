import React from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
} from "reactstrap";
import classnames from "classnames";
import WithdrawalDetails from "./WithdrawalDetails";
import BudgetDetails from "./BudgetDetails";
import LogDetails from "./LogDetails";

const ModalBody = ({ activeTab, toggleTab, withdrawal, year, session }) => (
  <div>
    <br />
    <Container>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggleTab("1")}
          >
            รายการเบิก
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggleTab("2")}
          >
            ข้อมูลงบประมาณ
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => toggleTab("3")}
          >
            ประวัติการเปลี่ยนสถานะ
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <WithdrawalDetails
          withdrawal={withdrawal}
          year={year}
          session={session}
        />
        <BudgetDetails withdrawal={withdrawal} year={year} session={session} />
        <LogDetails
          withdrawal={withdrawal}
          year={year}
          session={session}
          activeTab={activeTab}
        />
      </TabContent>
    </Container>
  </div>
);

export default ModalBody;
