import React, { useState } from "react";
import { Collapse, Navbar, NavbarToggler, NavbarBrand } from "reactstrap";
import { useSelector } from "react-redux";
import MainNavbar from "./MainNavbar";
import UserNavbar from "./UserNavbar";
import "./Navbar.css";

// logo
import Logo from "../../config/image/logo512.png";

const CustomNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const authData = useSelector((state) => state.authState);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar className="navbar-custom" expand="md">
        <NavbarBrand href="/">
          <img src={Logo} alt="logo" />
          ระบบจัดการงบประมาณ
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          {authData && authData.username ? <MainNavbar /> : null}
          {authData && authData.username ? <UserNavbar /> : null}
        </Collapse>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
