import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchWithdrawalLog } from "../../../../actions/listAction";
import { TabPane, Card, CardBody, CardTitle, Table } from "reactstrap";

const LogDetails = ({ withdrawal, year, session, activeTab }) => {
  const dispatch = useDispatch();

  const logs = useSelector(
    (state) => state.listState.withdrawals.logs?.data || []
  );
  const loading = useSelector((state) => state.listState.withdrawals.loading);
  const error = useSelector((state) => state.listState.withdrawals.error);

  useEffect(() => {
    if (activeTab === "3" && withdrawal) {
      dispatch(
        fetchWithdrawalLog({
          budget_year: year,
          session,
          withdrawal_id: withdrawal._id,
        })
      );
    }
  }, [dispatch, activeTab, withdrawal, year, session]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  // ฟังก์ชันสำหรับแปลงวันที่เป็นรูปแบบวัน/เดือน/ปี และแยกเวลาออกเป็นบรรทัดใหม่
  const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const datePart = date.toLocaleDateString("th-TH"); // รูปแบบวัน/เดือน/ปี
    const timePart = date.toLocaleTimeString(); // เวลา
    return (
      <>
        {datePart} <br /> {timePart}
      </>
    );
  };

  return (
    <TabPane tabId="3">
      <Card className="mt-3">
        <CardBody>
          <CardTitle tag="h5">ประวัติการเปลี่ยนสถานะ</CardTitle>
          {logs.length > 0 ? (
            <Table bordered striped>
              <thead>
                <tr>
                  <th>#</th>
                  <th>สถานะ</th>
                  <th>ขั้นตอน</th>
                  <th>ผู้ดำเนินการ</th>
                  <th>วันที่เริ่ม</th>
                  <th>วันที่สิ้นสุด</th>
                  <th>ความคิดเห็น</th>
                </tr>
              </thead>
              <tbody>
                {logs.map((log, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{log.status}</td>
                    <td>{log.stage}</td>
                    <td>
                      {log.res_username} ({log.res_user_position})
                    </td>
                    <td>{formatDateTime(log.status_time_start)}</td>
                    <td>
                      {log.status_time_end
                        ? formatDateTime(log.status_time_end)
                        : "-"}
                    </td>
                    <td>{log.comment || "-"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <div>ไม่มีข้อมูลการเปลี่ยนสถานะ</div>
          )}
        </CardBody>
      </Card>
    </TabPane>
  );
};

export default LogDetails;
