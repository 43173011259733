import React from "react";

const StepIndicator = ({ currentStep }) => {
  return (
    <div>
      <p>ขั้นตอนที่ {currentStep} of 3</p>
    </div>
  );
};

export default StepIndicator;
