import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "./Table";
import ErrorHandling from "./ErrorHandling";
import NoData from "./NoData";
import YearField from "./YearField";
import SessionField from "./SessionField";
import {
  fetchWithdrawals,
  fetchUserWithdrawals,
  fetchActivityWithdrawals,
  RESET_WITHDRAWALS,
} from "../../../actions/listAction";

const UserRoleView = () => {
  // State variables
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState([]);
  const [showUsername, setShowUsername] = useState(false);
  const [showWithdrawalType, setShowWithdrawalType] = useState(false);
  const [year, setYear] = useState(null);
  const [session, setSession] = useState(null);

  const dispatch = useDispatch();
  const { position, accessToken } = useSelector((state) => state.authState);

  // Fetch withdrawal data based on user role
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        dispatch({ type: RESET_WITHDRAWALS });
        const response = await fetchRoleBasedWithdrawals(
          position,
          accessToken,
          dispatch
        );
        if (response) {
          setData(response.data);
          setShowUsername(response.showUsername);
          setShowWithdrawalType(response.showWithdrawalType);
          setYear(response.year);
          setSession(response.session);
        } else {
          setError("Access token not found");
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, position, accessToken]);

  // Handle empty data scenario
  useEffect(() => {
    if (data && data.length === 0 && !loading) {
      dispatch(fetchWithdrawals());
    }
  }, [data, loading, dispatch]);

  if (loading) return <div>Loading...</div>;
  if (error) return <ErrorHandling message={error} />;
  if (!data || data.length === 0) return <NoData />;

  return (
    <div>
      <YearField selectedYear={year} setSelectedYear={setYear} />
      <SessionField selectedSession={session} setSelectedSession={setSession} />
      <Table
        data={data}
        showUsername={showUsername}
        showWithdrawalType={showWithdrawalType}
        year={year}
        session={session}
      />
    </div>
  );
};

// Fetch withdrawals based on user role
const fetchRoleBasedWithdrawals = async (position, accessToken, dispatch) => {
  if (!accessToken) return null;

  let response = null;
  if (
    [
      "ADMIN",
      "MANAGEMENT",
      "HEADFINANCE",
      "FINANCE",
      "PLANNER",
      "PROCUREMENT",
    ].includes(position)
  ) {
    response = await dispatch(fetchWithdrawals()).unwrap();
    return {
      data: flattenData(response),
      showUsername: true,
      showWithdrawalType: true,
      year: response.year,
      session: response.session,
    };
  } else if (position === "OFFICER") {
    response = await dispatch(fetchUserWithdrawals()).unwrap();
    return {
      data: response.data,
      showUsername: false,
      showWithdrawalType: true,
      year: response.year,
      session: response.session,
    };
  } else if (position === "TEACHER") {
    response = await dispatch(fetchActivityWithdrawals()).unwrap();
    return {
      data: response.data,
      showUsername: false,
      showWithdrawalType: false,
      year: response.year,
      session: response.session,
    };
  }
};

// Helper function to flatten data
const flattenData = (response) => {
  return response.data.reduce((acc, user) => acc.concat(user.data), []);
};

export default UserRoleView;
