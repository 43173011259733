// Utility function for formatting dates
export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const yearBuddhist = date.getFullYear() + 543;
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${yearBuddhist}, ${hours}:${minutes}`;
};

// Utility function to get styles based on status name
export const getStatusStyles = (statusName) => {
  const styles = {
    ยกเลิกการเบิก: {
      color: "white", // White text
      backgroundColor: "#ff4d4d", // Red background
      borderRadius: "10px", // Rounded corners
      padding: "6px 12px", // Padding for more visual space
      fontSize: "14px",
      border: "2px solid #ff0000", // Red border
      fontWeight: "600", // Bold text
      boxShadow: "0px 4px 8px rgba(255, 0, 0, 0.2)", // Subtle red shadow
    },
    อนุมัติการเบิก: {
      color: "white", // White text
      backgroundColor: "#00b300", // Green background
      borderRadius: "10px", // Rounded corners
      padding: "6px 12px",
      fontSize: "14px",
      border: "2px solid #131313", // Green border
      fontWeight: "600",
      boxShadow: "0px 4px 8px rgba(61, 61, 61, 0.2)", // Subtle green shadow
    },
    แก้ไขข้อมูลในเอกสาร: {
      color: "black", // Black text
      backgroundColor: "#ffcc66", // Orange background
      borderRadius: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      border: "2px solid #ff9900", // Orange border
      fontWeight: "600",
      boxShadow: "0px 4px 8px rgba(255, 153, 0, 0.2)", // Subtle orange shadow
    },
    แก้ไขข้อมูลในแบบฟอร์ม: {
      color: "black", // Black text
      backgroundColor: "#ffcc66", // Orange background (same as above)
      borderRadius: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      border: "2px solid #ff9900", // Orange border
      fontWeight: "600",
      boxShadow: "0px 4px 8px rgba(255, 153, 0, 0.2)", // Subtle orange shadow
    },
    default: {
      color: "black", // Black text
      backgroundColor: "#ffffcc", // Yellow background
      borderRadius: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      border: "2px solid #ffff66", // Yellow border
      fontWeight: "600",
      boxShadow: "0px 4px 8px rgba(255, 255, 102, 0.2)", // Subtle yellow shadow
    },
  };

  return styles[statusName] || styles.default;
};

// Utility function to get styles based on stage
export const getStageStyles = (stageName) => {
  const styles = {
    ขั้นตอนรับเงินเบิก: {
      color: "#28a745", // Green text
      backgroundColor: "#e6ffe6", // Light green background
      borderRadius: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      border: "2px solid #28a745", // Green border
      fontWeight: "600",
      boxShadow: "0px 4px 8px rgba(40, 167, 69, 0.2)", // Subtle shadow
    },
    default: {
      color: "#0066cc", // Blue text
      backgroundColor: "#e6f2ff", // Light blue background
      borderRadius: "10px",
      padding: "6px 12px",
      fontSize: "14px",
      border: "2px solid #0066cc", // Blue border
      fontWeight: "600",
      boxShadow: "0px 4px 8px rgba(0, 102, 204, 0.2)", // Subtle shadow
    },
  };

  return styles[stageName] || styles.default;
};
