import React, { useState } from "react";
import { Table, Button } from "reactstrap";
import "./Table.css";

const BudgetReportTable = ({ reportData }) => {
  const [expandedIncome, setExpandedIncome] = useState({});
  const [expandedBudget, setExpandedBudget] = useState({});
  const [expandedExpenditure, setExpandedExpenditure] = useState({});
  const [expandedProject, setExpandedProject] = useState({});

  const toggleExpand = (id, setExpanded, expandedState) => {
    setExpanded({ ...expandedState, [id]: !expandedState[id] });
  };

  const getTransferAmount = (transfer) => {
    if (!transfer) return 0;
    return transfer.negative ? -transfer.amount : transfer.amount;
  };

  const formatNumber = (num) => {
    if (typeof num === "number") {
      return num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
    return parseFloat(num)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  return (
    <Table bordered>
      <thead>
        <tr>
          <th>รายการ</th>
          <th>งบตั้งต้น</th>
          <th>การโอน</th>
          <th>การเบิก</th>
          <th>งบคงเหลือ</th>
        </tr>
      </thead>
      <tbody>
        {/* ระดับ ปีการศึกษา (ชั้นบนสุด) */}
        <tr className="budget-year-row">
          <td>{`ปีการศึกษา ${reportData.budgetYear}`}</td>
          <td>{formatNumber(reportData.yearInitAmount || 0)}</td>
          <td>
            {formatNumber(getTransferAmount(reportData.yearTransfer) || 0)}
          </td>
          <td>{formatNumber(reportData.yearWithdrawal || 0)}</td>
          <td>{formatNumber(reportData.yearRemainAmount || 0)}</td>
        </tr>

        {/* ระดับ ประเภทงบประมาณ */}
        {reportData.incomeList.map((income) => (
          <React.Fragment key={income.incomeId}>
            <tr className="income-row">
              <td>
                <Button
                  color="link"
                  onClick={() =>
                    toggleExpand(
                      income.incomeId,
                      setExpandedIncome,
                      expandedIncome
                    )
                  }
                >
                  {expandedIncome[income.incomeId] ? "-" : "+"}
                </Button>
                {income.incomeName}
              </td>
              <td>{formatNumber(income.incomeInitAmount || 0)}</td>
              <td>
                {formatNumber(getTransferAmount(income.incomeTransfer) || 0)}
              </td>
              <td>{formatNumber(income.incomeWithdrawal || 0)}</td>
              <td>{formatNumber(income.incomeRemainAmount || 0)}</td>
            </tr>

            {/* ระดับ ฝ่ายที่รับงบประมาณ */}
            {expandedIncome[income.incomeId] &&
              income.budgetList.map((budget) => (
                <React.Fragment key={budget.budgetId}>
                  <tr className="budget-row">
                    <td style={{ paddingLeft: "20px" }}>
                      <Button
                        color="link"
                        onClick={() =>
                          toggleExpand(
                            budget.budgetId,
                            setExpandedBudget,
                            expandedBudget
                          )
                        }
                      >
                        {expandedBudget[budget.budgetId] ? "-" : "+"}
                      </Button>
                      {budget.budgetName}
                    </td>
                    <td>{formatNumber(budget.budgetInitAmount || 0)}</td>
                    <td>
                      {formatNumber(
                        getTransferAmount(budget.budgetTransfer) || 0
                      )}
                    </td>
                    <td>{formatNumber(budget.budgetWithdrawal || 0)}</td>
                    <td>{formatNumber(budget.budgetRemainAmount || 0)}</td>
                  </tr>

                  {/* ระดับ รายจ่าย */}
                  {expandedBudget[budget.budgetId] &&
                    budget.expenditureList.map((expenditure) => (
                      <React.Fragment key={expenditure.expenditureId}>
                        <tr className="expenditure-row">
                          <td style={{ paddingLeft: "40px" }}>
                            <Button
                              color="link"
                              onClick={() =>
                                toggleExpand(
                                  expenditure.expenditureId,
                                  setExpandedExpenditure,
                                  expandedExpenditure
                                )
                              }
                            >
                              {expandedExpenditure[expenditure.expenditureId]
                                ? "-"
                                : "+"}
                            </Button>
                            {expenditure.expenditureName} ({expenditure.level})
                          </td>
                          <td>
                            {formatNumber(
                              expenditure.expenditureInitAmount || 0
                            )}
                          </td>
                          <td>
                            {formatNumber(
                              getTransferAmount(
                                expenditure.expenditureTransfer
                              ) || 0
                            )}
                          </td>
                          <td>
                            {formatNumber(
                              expenditure.expenditureWithdrawal || 0
                            )}
                          </td>
                          <td>
                            {formatNumber(
                              expenditure.expenditureRemainAmount || 0
                            )}
                          </td>
                        </tr>

                        {/* ระดับ โครงการ */}
                        {expandedExpenditure[expenditure.expenditureId] &&
                          expenditure.projectList &&
                          expenditure.projectList.map((project) => (
                            <React.Fragment key={project.projectId}>
                              <tr className="project-row">
                                <td style={{ paddingLeft: "60px" }}>
                                  <Button
                                    color="link"
                                    onClick={() =>
                                      toggleExpand(
                                        project.projectId,
                                        setExpandedProject,
                                        expandedProject
                                      )
                                    }
                                  >
                                    {expandedProject[project.projectId]
                                      ? "-"
                                      : "+"}
                                  </Button>
                                  {project.projectName}
                                </td>
                                <td>
                                  {formatNumber(project.projectInitAmount || 0)}
                                </td>
                                <td>
                                  {formatNumber(
                                    getTransferAmount(
                                      project.projectTransfer
                                    ) || 0
                                  )}
                                </td>
                                <td>
                                  {formatNumber(project.projectWithdrawal || 0)}
                                </td>
                                <td>
                                  {formatNumber(
                                    project.projectRemainAmount || 0
                                  )}
                                </td>
                              </tr>

                              {/* ระดับ กิจกรรม */}
                              {expandedProject[project.projectId] &&
                                project.activityList.map((activity) => (
                                  <tr
                                    key={activity.activityId}
                                    className="activity-row"
                                  >
                                    <td style={{ paddingLeft: "80px" }}>
                                      {activity.activityName}
                                    </td>
                                    <td>
                                      {formatNumber(
                                        activity.activityInitAmount || 0
                                      )}
                                    </td>
                                    <td>
                                      {formatNumber(
                                        getTransferAmount(
                                          activity.activityTransfer
                                        ) || 0
                                      )}
                                    </td>
                                    <td>
                                      {formatNumber(
                                        activity.activityWithdrawal || 0
                                      )}
                                    </td>
                                    <td>
                                      {formatNumber(
                                        activity.activityRemainAmount || 0
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                </React.Fragment>
              ))}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
};

export default BudgetReportTable;
