import React, { Fragment } from "react";
import Layout from "../components/Layout/Layout";

const Profile = () => {
  return (
    <Fragment>
      <h1>หน้าผู้ใช้งาน</h1>
    </Fragment>
  );
};

export default Profile;
