import React from "react";
import { Row, Col } from "reactstrap";
import "./Header.css";

const Header = () => {
  return (
    <Row className="header">
      <Col>
        <h3>รายการจัดการงบประมาณ</h3>
      </Col>
    </Row>
  );
};

export default Header;
