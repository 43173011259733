import React from "react";
import { useDispatch } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { resetBudget } from "../../../reducers/budgetReducer";

const FormActions = ({
  currentStep,
  setCurrentStep,
  setError,
  handleSubmit,
  formDataOut,
  formDataIn,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNext = () => {
    console.log("currentStep: " + currentStep);
    if (currentStep === 1 && Object.keys(formDataIn).length === 0) {
      dispatch(resetBudget());
    }
    setError(null);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setError(null);
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleFinish = () => {
    handleSubmit();
    navigate("/");
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col className="text-center">
          {currentStep === 1 && (
            <Button color="primary" onClick={handleNext}>
              ถัดไป
            </Button>
          )}
          {currentStep === 2 && (
            <>
              <Button color="secondary" onClick={handleBack} className="mr-2">
                ย้อนกลับ
              </Button>{" "}
              <Button color="primary" onClick={handleNext}>
                ถัดไป
              </Button>
            </>
          )}
          {currentStep === 3 && (
            <>
              <Button color="secondary" onClick={handleBack} className="mr-2">
                ย้อนกลับ
              </Button>{" "}
              <Button color="primary" onClick={handleNext}>
                ถัดไป
              </Button>
            </>
          )}
          {currentStep === 4 && (
            <Button color="primary" onClick={handleFinish}>
              กลับหน้าหลัก
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FormActions;
