import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Button, Spinner, Alert } from "reactstrap";
import { fetchBudgetYears } from "../../actions/budget/fetchAction";
import { changeStatusBudgetYear } from "../../actions/budget/addAction";

const ChangeStatusBudget = () => {
  const dispatch = useDispatch();
  const { data, isLoading, changeStatus, error } = useSelector(
    (state) => state.budgetState.years
  );
  const authData = useSelector((state) => state.authState);

  const [selectedYear, setSelectedYear] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  useEffect(() => {
    dispatch(fetchBudgetYears({ accessToken: authData.accessToken }));
  }, [dispatch, authData.accessToken]);

  useEffect(() => {
    if (changeStatus) {
      setIsSuccess(true);
    }
  }, [changeStatus]);

  const handleChange = (e) => {
    const year = parseInt(e.target.value);
    setSelectedYear(year);
    setErrorMessage("");

    const selectedYearData = data.find((item) => item.budget_year === year);
    setSelectedStatus(selectedYearData?.status || "");
  };

  const handleStatusChange = () => {
    const selectedYearData = data.find(
      (item) => item.budget_year === selectedYear
    );
    if (!selectedYearData) {
      setErrorMessage("ไม่พบปีการศึกษาที่เลือก");
      return;
    }

    const newStatus = selectedYearData.status === "OPEN" ? "CLOSED" : "OPEN";
    dispatch(
      changeStatusBudgetYear({
        budget_year_id: selectedYearData.id,
        status: newStatus,
        accessToken: authData.accessToken,
      })
    );
  };

  const handleReload = () => window.location.reload(); // Reload หน้าเมื่อผู้ใช้กดปุ่ม "ตกลง"

  const displayStatus = selectedStatus === "OPEN" ? "เปิดอยู่" : "ปิดอยู่";

  return (
    <div>
      <FormGroup>
        <Label for="selectYear">เลือกปีการศึกษา</Label>
        {isLoading && <Spinner color="primary" />}
        {error && <Alert color="danger">{error}</Alert>}
        {!data?.length && <Alert color="warning">ไม่พบข้อมูลปีการศึกษา</Alert>}
        <Input
          type="select"
          id="selectYear"
          value={selectedYear}
          onChange={handleChange}
          disabled={isLoading || !data?.length}
        >
          <option value="">กรุณาเลือกปีการศึกษา</option>
          {data.map((year) => (
            <option key={year.budget_year} value={year.budget_year}>
              {year.budget_year}
            </option>
          ))}
        </Input>
      </FormGroup>

      {selectedYear && (
        <>
          <p>
            สถานะปัจจุบัน: {selectedStatus ? displayStatus : "ไม่พบข้อมูลสถานะ"}
          </p>
          {!isSuccess ? (
            <Button
              onClick={handleStatusChange}
              disabled={isLoading}
              color="primary"
            >
              {isLoading ? (
                <Spinner size="sm" />
              ) : (
                `เปลี่ยนสถานะเป็น ${selectedStatus === "OPEN" ? "ปิด" : "เปิด"}`
              )}
            </Button>
          ) : (
            <Button onClick={handleReload} color="success" outline>
              ตกลง
            </Button>
          )}
        </>
      )}

      {errorMessage && (
        <>
          <p> </p>
          <Alert color="danger">{errorMessage}</Alert>
        </>
      )}
      {isSuccess && (
        <>
          <p> </p>
          <Alert color="success">เปลี่ยนสถานะสำเร็จ!</Alert>
        </>
      )}
    </div>
  );
};

export default ChangeStatusBudget;
