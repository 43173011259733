import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Button, Spinner, Row, Col } from "reactstrap";
import { updateLinkDoc } from "../../../../actions/listAction";

const LinkDocument = ({ withdrawal, year, session }) => {
  const dispatch = useDispatch();
  const { position, username, accessToken } = useSelector(
    (state) => state.authState
  );
  const { success, isLoading } = useSelector(
    (state) => state.listState.withdrawals
  );

  const [link, setLink] = useState(withdrawal.link_doc || "");
  const [error, setError] = useState("");

  const canEdit =
    username === withdrawal.withdrawal_username ||
    username === withdrawal.res_planner_username ||
    username === withdrawal.res_procurement_username ||
    username === withdrawal.res_finance_username;

  useEffect(() => {
    if (success) {
      window.location.reload();
    }
  }, [success]);

  const handleLinkChange = (e) => {
    setLink(e.target.value);
  };

  const validateLink = (link) => {
    const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
    if (!regex.test(link)) {
      return `http://${link}`;
    }
    return link;
  };

  const handleSubmit = () => {
    if (!link) {
      setError("กรุณากรอกลิงก์เอกสาร");
      return;
    }

    const validLink = validateLink(link);
    dispatch(
      updateLinkDoc({
        year,
        session,
        id: withdrawal._id,
        link: validLink,
        username,
      })
    );
  };

  if (!canEdit) return null;

  return (
    <FormGroup>
      <Row className="align-items-center">
        <Col md={2}>
          <Label for="linkDoc" className="mb-0">
            เพิ่มหรือแก้ไขเอกสาร
          </Label>
        </Col>
        <Col md={7}>
          <Input
            type="text"
            id="linkDoc"
            value={link}
            onChange={handleLinkChange}
            placeholder="กรุณากรอกลิงก์เอกสาร"
          />
        </Col>
        <Col md={3}>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={isLoading}
            block
          >
            {isLoading ? <Spinner size="sm" /> : "อัปเดตลิงก์"}
          </Button>
        </Col>
      </Row>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </FormGroup>
  );
};

export default LinkDocument;
