import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
  Table,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { addExpenditureItems } from "../../actions/budget/addAction";
import { fetchExpenditureDetails } from "../../actions/budget/fetchAction";

const AddExpenseModal = ({
  isOpen,
  toggle,
  budgetName,
  yearId,
  sessionValue,
  sessionId,
  incomeTypeId,
}) => {
  const dispatch = useDispatch();
  const [expenditures, setExpenditures] = useState([]);
  const [expenditureName, setExpenditureName] = useState("");
  const [level, setLevel] = useState("PRIMARY");
  const [initAmount] = useState(0); // กำหนด initAmount เป็น 0 คงที่
  const [isSubmitting, setIsSubmitting] = useState(false);

  const yearsData = useSelector((state) => state.budgetState.years.data);
  const sessionsData = useSelector((state) => state.budgetState.sessions.data);
  const incomeTypesData = useSelector(
    (state) => state.budgetState.incomeTypes.data
  );
  const authData = useSelector((state) => state.authState);

  const year = yearsData.find((y) => y.id === yearId)?.budget_year || "";
  const session =
    sessionsData.find((s) => s.session === sessionId)?.session || "";
  const incomeType =
    incomeTypesData.find((i) => i._id === incomeTypeId)?.name || "";

  const addExpenditure = () => {
    setExpenditures([
      ...expenditures,
      { expenditureName, level, initAmount: 0 },
    ]);
    setExpenditureName("");
    setLevel("PRIMARY");
  };

  const handleRemoveExpenditure = (index) => {
    const updatedExpenditures = [...expenditures];
    updatedExpenditures.splice(index, 1);
    setExpenditures(updatedExpenditures);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data = {
      group_year_fk: yearId,
      session_fk: sessionValue,
      group_income_fk: incomeTypeId,
      budget_name: budgetName,
      items: expenditures.map((exp) => ({
        expenditure_name: exp.expenditureName,
        level: exp.level,
        init_amount: exp.initAmount,
      })),
      accessToken: authData.accessToken,
    };

    try {
      await dispatch(addExpenditureItems(data));
      toggle();
      dispatch(
        fetchExpenditureDetails({
          group_year_fk: yearId,
          session_fk: sessionValue,
          group_income_fk: incomeTypeId,
          budget_name: budgetName,
          accessToken: authData.accessToken,
        })
      );
    } catch (error) {
      console.error("Error adding expenditures:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>เพิ่มรายจ่าย</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="year">ปีการศึกษา</Label>
                <Input
                  type="text"
                  name="year"
                  id="year"
                  value={year}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="session">ปีงบประมาณ</Label>
                <Input
                  type="text"
                  name="session"
                  id="session"
                  value={session}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="incomeType">แหล่งงบประมาณ</Label>
            <Input
              type="text"
              name="incomeType"
              id="incomeType"
              value={incomeType}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="budgetName">งบประมาณ</Label>
            <Input
              type="text"
              name="budgetName"
              id="budgetName"
              value={budgetName}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="expenditureName">ชื่อรายการรายจ่าย</Label>
            <Input
              type="text"
              name="expenditureName"
              id="expenditureName"
              value={expenditureName}
              onChange={(e) => setExpenditureName(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label for="level">ระดับ</Label>
            <Input
              type="select"
              name="level"
              id="level"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <option value="PRIMARY">ประถมศึกษา</option>
              <option value="KINDERGARTEN">อนุบาล</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="initAmount">งบตั้งต้น</Label>
            <Input
              type="number"
              name="initAmount"
              id="initAmount"
              value={initAmount}
              readOnly
            />
          </FormGroup>
          <Button
            color="secondary"
            onClick={addExpenditure}
            disabled={!expenditureName}
          >
            เพิ่มรายการ
          </Button>
          {expenditures.length > 0 && (
            <>
              <h5 className="mt-3">สรุปรายการที่เพิ่ม</h5>
              <Table bordered>
                <thead>
                  <tr>
                    <th>ชื่อรายการรายจ่าย</th>
                    <th>ระดับ</th>
                    <th>งบตั้งต้น</th>
                    <th>ลบ</th>
                  </tr>
                </thead>
                <tbody>
                  {expenditures.map((exp, index) => (
                    <tr key={index}>
                      <td>{exp.expenditureName}</td>
                      <td>
                        {exp.level === "PRIMARY" ? "ประถมศึกษา" : "อนุบาล"}
                      </td>
                      <td>{exp.initAmount}</td>
                      <td>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => handleRemoveExpenditure(index)}
                        >
                          ลบ
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting || expenditures.length === 0}
            >
              {isSubmitting ? "กำลังเพิ่ม..." : "ตกลง"}
            </Button>
            <Button color="secondary" onClick={toggle}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddExpenseModal;
