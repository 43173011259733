import React from "react";

const Header = () => {
  return (
    <div className="withdrawal-header">
      <h4>รายการเบิกจ่าย</h4>
    </div>
  );
};

export default Header;
