import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Spinner } from "reactstrap";
import { fetchSessionsByYear } from "../../../actions/budget/fetchAction";
import Alert from "../../../config/Alert";

const Session = ({ formData, handleFormDataChange }) => {
  const dispatch = useDispatch();
  const {
    data: sessions,
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.sessions);
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    if (formData.year_name) {
      dispatch(
        fetchSessionsByYear({
          year: formData.year_name,
          accessToken: authData.accessToken,
        })
      );
    }
  }, [dispatch, formData, handleFormDataChange, authData.accessToken]);

  const handleChange = (e) => {
    handleFormDataChange({
      session: e.target.value,
      session_name: e.target.options[e.target.selectedIndex].text,
      incometype: "",
      incometype_name: "",
      budget: "",
      expenditure: "",
      expenditure_name: "",
      expenditure_remain: "",
      project: "",
      project_name: "",
      activity: "",
      activity_name: "",
      activity_remain: "",
      withdrawal_name: "",
      amount_request: "",
    });
  };

  return (
    <FormGroup>
      <Label for="session">
        ปีงบประมาณ {isLoading && <Spinner size="sm" color="primary" />}
      </Label>
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="session"
        id="session"
        value={formData.session || ""}
        onChange={handleChange}
        disabled={isLoading}
      >
        {formData.session ? (
          <></>
        ) : (
          <>
            <option value="">กรุณาเลือกปีงบประมาณ</option>
          </>
        )}

        {sessions.map((session) => (
          <option key={session._id} value={session._id}>
            {session.session}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default Session;
