import React from "react";
import Header from "./Header";
import UserRoleView from "./UserRoleView";
import "./TransferList.css";

const TransferList = () => {
  return (
    <div className="transfer-list">
      <br />
      <Header />
      <UserRoleView />
    </div>
  );
};

export default TransferList;
