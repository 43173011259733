// components/Footer/Footer.js
import React from "react";
import { Container } from "reactstrap";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer-custom">
      <Container className="py-4">
        <p className="mb-0">
          &copy; {new Date().getFullYear()} โรงเรียนวัดพลงช้างเผือก. All Rights
          Reserved.
        </p>
      </Container>
    </footer>
  );
};

export default Footer;
