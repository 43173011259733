import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

const ProjectBreadcrumb = ({ projectName }) => (
  <Breadcrumb>
    <BreadcrumbItem>
      <Link to="/">หน้าหลัก</Link>
    </BreadcrumbItem>

    <BreadcrumbItem active>โครงการ</BreadcrumbItem>
  </Breadcrumb>
);

export default ProjectBreadcrumb;
