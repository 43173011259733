import React from "react";
import {
  Modal,
  ModalHeader as RSModalHeader,
  ModalFooter as RSModalFooter,
  Button,
} from "reactstrap";

// ModalContainer: คอมโพเนนต์หลักที่จัดการการแสดงผลของโมดัล
export const ModalContainer = ({ isOpen, toggle, children }) => (
  <Modal isOpen={isOpen} toggle={toggle} size="lg">
    {children}
  </Modal>
);

// ModalHeader: คอมโพเนนต์ที่ใช้แสดงหัวข้อของโมดัล
export const ModalHeader = ({ toggle, title }) => (
  <RSModalHeader toggle={toggle}>{title}</RSModalHeader>
);

// ModalFooter: คอมโพเนนต์ที่ใช้แสดงส่วนท้ายของโมดัล
export const ModalFooter = ({ toggle }) => (
  <RSModalFooter>
    <Button color="secondary" onClick={toggle}>
      ปิด
    </Button>
  </RSModalFooter>
);
