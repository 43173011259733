import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Spinner } from "reactstrap";
import { fetchBudgetYears } from "../../../actions/budget/fetchAction";
import Alert from "../../../config/Alert";

const Year = ({ formData, handleFormDataChange, resetFields }) => {
  const dispatch = useDispatch();
  const {
    data: years,
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.years);
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    dispatch(fetchBudgetYears({ accessToken: authData.accessToken }));
  }, [dispatch, authData.accessToken, resetFields]);

  const handleChange = (e) => {
    handleFormDataChange({
      year: e.target.value,
      year_name: e.target.options[e.target.selectedIndex].text,
      session: "",
      session_name: "",
      incometype: "",
      incometype_name: "",
      budget: "",
      expenditure: "",
      expenditure_name: "",
      expenditure_remain: "",
      project: "",
      project_name: "",
      activity: "",
      activity_name: "",
      activity_remain: "",
      transfer_name: "",
      amount_request: "",
    });
  };

  return (
    <FormGroup>
      <Label for="year">
        ปีการศึกษา {isLoading && <Spinner size="sm" color="primary" />}
      </Label>
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="year"
        id="year"
        value={formData.year || ""}
        onChange={handleChange}
        disabled={isLoading}
      >
        {formData.year ? (
          <></>
        ) : (
          <>
            <option value="">กรุณาเลือกปีการศึกษา</option>
          </>
        )}
        {years.map((year) => (
          <option key={year.id} value={year.id}>
            {year.budget_year}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default Year;
