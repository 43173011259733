// App.js
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, Route, useNavigate } from "react-router-dom";
import Layout from "./components/Layout/Layout";
import List from "./pages/List/List";
import WithdrawalForm from "./pages/WithdrawalForm";
import TransferForm from "./pages/TransferForm";
import Budgeting from "./pages/Budgeting";
import Profile from "./pages/Profile";
import Expenditure from "./pages/Expenditure";
import Project from "./pages/Project";
import Report from "./pages/Report";
import NotAllow from "./pages/NotAllow";
import { useTokenHandler } from "./components/handlingToken";

import { Spinner } from "reactstrap";

import "./App.css";

// reducers
import { resetAuth } from "./reducers/authReducer";
// actions
import { authToken, getDataUser } from "./actions/authAction";

function App() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authState);
  const [userName, setUserName] = useState("");
  const [userFK, setUserFK] = useState("");
  const token = useTokenHandler(authData, dispatch, authToken, resetAuth);

  // Check if user is rejected
  useEffect(() => {
    if (authData.isReject) {
      navigate("/notallow");
    }
  }, [authData.isReject, navigate]);

  // set username
  useEffect(() => {
    if (authData && authData.username) {
      if (authData.username && authData.id) {
        setUserName(authData.username);
        setUserFK(authData.id);
      }
    }
  }, [authData]);

  // send username, id and token to get withdrawal & transfer
  useEffect(() => {
    if (userName && userFK && token) {
      const data = {
        username: userName,
        userfk: userFK,
        refreshToken: token,
      };
      dispatch(getDataUser(data));
    }
  }, [userName, userFK, token]);

  return (
    <Fragment>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<List />} />
          <Route path="*" element={<NotAllow />} />
          <Route path="list" element={<List />} />
          <Route path="withdrawalform" element={<WithdrawalForm />} />
          <Route path="transferform" element={<TransferForm />} />
          <Route path="budgeting" element={<Budgeting />} />
          <Route path="profile" element={<Profile />} />
          <Route path="expenditure/:budgetName" element={<Expenditure />} />
          <Route path="project/:projectName" element={<Project />} />
          <Route path="report" element={<Report />} />
        </Route>
      </Routes>
    </Fragment>
  );
}

export default App;
