import React, { useState } from "react";
import { Button, FormGroup, Label, Input, Col, Row } from "reactstrap";
import "./Download.css";

const Download = ({ onDownload }) => {
  const [fileType, setFileType] = useState("pdf");

  const handleDownload = () => {
    onDownload(fileType);
  };

  return (
    <div className="download-section p-3">
      <Row className="align-items-center">
        <Col md={8} xs={12}>
          <FormGroup>
            <Label for="fileType" className="mb-2">
              เลือกประเภทไฟล์
            </Label>
            <Input
              type="select"
              name="fileType"
              id="fileType"
              value={fileType}
              onChange={(e) => setFileType(e.target.value)}
              className="form-control"
            >
              <option value="pdf">PDF</option>
              <option value="csv">CSV</option>
              <option value="excel">Excel</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={4} xs={12} className="text-md-left text-center">
          <FormGroup>
            <br className="mb-2" />
            <Button
              color="primary"
              onClick={handleDownload}
              className="w-100 align-self-center"
            >
              Download
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
};

export default Download;
