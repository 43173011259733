import React from "react";
import { FormGroup, Label, Input } from "reactstrap";

const WithdrawalType = ({ formData, handleFormDataChange }) => {
  const handleChange = (e) => {
    handleFormDataChange({ withdrawal_type: e.target.value });
  };

  return (
    <FormGroup>
      <Label for="withdrawalType">รูปแบบการเบิก</Label>
      <Input
        type="select"
        name="withdrawalType"
        id="withdrawalType"
        value={formData.withdrawal_type || ""}
        onChange={handleChange}
      >
        {formData.withdrawal_type ? null : (
          <option value="">กรุณาเลือกรูปแบบการเบิก</option>
        )}
        {/* <option value="expenditure">เบิกรายจ่าย</option> */}
        <option value="activity">เบิกกิจกรรม</option>
      </Input>
    </FormGroup>
  );
};

export default WithdrawalType;
