import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { fetchExpenditureDetails } from "../actions/budget/fetchAction";
import {
  Spinner,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Card,
  CardBody,
  Table,
  Row,
  Col,
} from "reactstrap";
import Alert from "../config/Alert";
import ExpenditureTable from "../components/Expenditure/ExpenditureTable";
import SearchBar from "../components/Expenditure/SearchBar";
import PaginationComponent from "../components/Expenditure/PaginationComponent";
import AddProjectApprovalButton from "../components/Expenditure/AddProjectApprovalButton";
import AddExpenseButton from "../components/Expenditure/AddExpenseButton";
import DeleteExpenseButton from "../components/Expenditure/DeleteExpenseButton";
import DeleteExpenseModal from "../components/Expenditure/DeleteExpenseModal";
import Filters from "../components/Expenditure/Filters";

import "./Expenditure.css";

const Expenditure = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data = { items: [] },
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.expenditure);
  const yearsData = useSelector((state) => state.budgetState.years.data);
  const sessionsData = useSelector((state) => state.budgetState.sessions.data);
  const incomeTypesData = useSelector(
    (state) => state.budgetState.incomeTypes.data
  );
  const authData = useSelector((state) => state.authState);

  const { group_year_fk, session_fk, group_income_fk, budget_name } =
    location.state;

  const [retryCount, setRetryCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [level, setLevel] = useState("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [initMin, setInitMin] = useState("");
  const [initMax, setInitMax] = useState("");
  const [remainMin, setRemainMin] = useState("");
  const [remainMax, setRemainMax] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const toggleDeleteModal = () => setIsDeleteModalOpen(!isDeleteModalOpen);

  useEffect(() => {
    const fetchData = () => {
      dispatch(
        fetchExpenditureDetails({
          group_year_fk,
          session_fk,
          group_income_fk,
          budget_name,
          accessToken: authData.accessToken,
        })
      ).catch((error) => {
        console.error("Error fetching expenditure details:", error);
        if (retryCount < 3) {
          setTimeout(fetchData, 1000);
          setRetryCount(retryCount + 1);
        }
      });
    };

    fetchData();
  }, [
    dispatch,
    group_year_fk,
    session_fk,
    group_income_fk,
    budget_name,
    authData,
    retryCount,
  ]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDetailsClick = (budget) => {
    navigate(`/expenditure/${budget.budget}`, {
      state: {
        group_year_fk,
        session_fk,
        group_income_fk,
        budget_name: budget.budget,
      },
    });
  };

  const handleProjectManageClick = (expenditureName, expenditureId) => {
    navigate(`/project/${expenditureName}`, {
      state: {
        year: yearsData.find((y) => y.id === group_year_fk)?.budget_year || "",
        yearId: group_year_fk,
        session:
          sessionsData.find((s) => s.session === session_fk)?.session || "",
        sessionId: session_fk,
        incomeType:
          incomeTypesData.find((i) => i._id === group_income_fk)?.name || "",
        incomeTypeId: group_income_fk,
        budgetName: budget_name,
        expenditureId: expenditureId,
        expenditureName: expenditureName,
      },
    });
  };

  const filteredItems = data.items
    .filter((item) =>
      item.expenditure_name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .filter((item) => (level ? item.level === level : true))
    .filter((item) => (initMin ? item.init_amount >= initMin : true))
    .filter((item) => (initMax ? item.init_amount <= initMax : true))
    .filter((item) => (remainMin ? item.remain_amount >= remainMin : true))
    .filter((item) => (remainMax ? item.remain_amount <= remainMax : true));

  const paginatedItems = filteredItems.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);

  const year = yearsData.find((y) => y.id === group_year_fk)?.budget_year || "";
  const session =
    sessionsData.find((s) => s.session === session_fk)?.session || "";
  const incomeType =
    incomeTypesData.find((i) => i._id === group_income_fk)?.name || "";

  return (
    <Container className="expenditure-container">
      <h4 className="budget-name">งบประมาณ: {budget_name}</h4>
      <Breadcrumb>
        <BreadcrumbItem>
          <a href="/">หน้าหลัก</a>
        </BreadcrumbItem>
        <BreadcrumbItem active>หน้ารายละเอียดงบประมาณ</BreadcrumbItem>
      </Breadcrumb>
      <Row>
        <Col md="12">
          <p>
            ปีงบประมาณ: {year} | ปีการศึกษา: {session} | แหล่งงบประมาณ:{" "}
            {incomeType}
          </p>
        </Col>
      </Row>
      <Row>
        <Col md="3">
          <SearchBar searchTerm={searchTerm} handleSearch={handleSearch} />
          <Filters
            level={level}
            setLevel={setLevel}
            initMin={initMin}
            setInitMin={setInitMin}
            initMax={setInitMax}
            setInitMax={setInitMax}
            remainMin={remainMin}
            setRemainMin={setRemainMin}
            remainMax={remainMax}
            setRemainMax={setRemainMax}
          />
          <br />
          <Table bordered>
            <tbody>
              <tr>
                <td>งบตั้งต้น:</td>
                <td
                  className={`budget-amount ${
                    data.total_budget_init_amount >= 0 ? "positive" : "negative"
                  }`}
                >
                  {data.total_budget_init_amount || 0}
                </td>
              </tr>
              <tr>
                <td>งบคงเหลือ:</td>
                <td
                  className={`budget-amount ${
                    data.total_budget_remain_amount >= 0
                      ? "positive"
                      : "negative"
                  }`}
                >
                  {data.total_budget_remain_amount || 0}
                </td>
              </tr>
            </tbody>
          </Table>
          <Row>
            <Col>
              <AddProjectApprovalButton
                yearId={group_year_fk}
                sessionValue={session_fk}
                sessionId={session_fk}
                incomeTypeId={group_income_fk}
                budgetName={budget_name}
                expenditureItems={data.items}
                budgetId={data.id}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <AddExpenseButton
                yearId={group_year_fk}
                sessionId={session_fk}
                sessionValue={session}
                incomeTypeId={group_income_fk}
                budgetName={budget_name}
              />
            </Col>
            <Col>
              <DeleteExpenseButton toggle={toggleDeleteModal} />
              <DeleteExpenseModal
                isOpen={isDeleteModalOpen}
                toggle={toggleDeleteModal}
                yearId={group_year_fk}
                sessionValue={session_fk}
                sessionId={session_fk}
                incomeTypeId={group_income_fk}
                budgetName={budget_name}
              />
            </Col>
          </Row>
        </Col>
        <Col md="9">
          {isLoading && <Spinner color="primary" />}
          {error && (
            <Alert color="danger">
              {error.message || "เกิดข้อผิดพลาด"}
              {retryCount < 3 && (
                <Button color="link" onClick={() => setRetryCount(0)}>
                  ลองอีกครั้ง
                </Button>
              )}
            </Alert>
          )}
          {!isLoading && paginatedItems.length === 0 && (
            <Alert color="warning">
              {error
                ? "เกิดข้อผิดพลาดในการโหลดข้อมูลรายจ่าย"
                : "ไม่พบข้อมูลรายจ่าย"}
            </Alert>
          )}
          {paginatedItems.length > 0 && (
            <Fragment>
              รายการรายจ่ายที่พบ: {filteredItems.length}
              <Card className="table-card">
                <CardBody>
                  <ExpenditureTable
                    data={paginatedItems}
                    handleDetailsClick={handleDetailsClick}
                    handleProjectManageClick={handleProjectManageClick}
                  />
                  <div className="pagination-container">
                    <PaginationComponent
                      currentPage={currentPage}
                      totalPages={totalPages}
                      handlePageChange={handlePageChange}
                    />
                  </div>
                </CardBody>
              </Card>
            </Fragment>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Expenditure;
