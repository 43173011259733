import React, { useState } from "react";
import { Button } from "reactstrap";
import DeleteProjectForm from "./DeleteProjectForm";

const DeleteProjectButton = ({ hasProjects, details, refreshProjects }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const { expenditureId } = details;

  return (
    <>
      <Button
        color="danger"
        disabled={!hasProjects}
        style={{ width: "100%", marginBottom: "10px" }}
        outline
        onClick={toggle}
      >
        ลบโครงการ
      </Button>
      <DeleteProjectForm
        isOpen={isOpen}
        toggle={toggle}
        expenditureId={expenditureId}
        refreshProjects={refreshProjects}
      />
    </>
  );
};

export default DeleteProjectButton;
