import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import { fetchExpenditureDetails } from "../../../actions/budget/fetchAction";

const Expenditure = ({ formData, handleFormDataChange }) => {
  const dispatch = useDispatch();
  const {
    data: expenditureItems,
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.expenditure);
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    if (
      formData.year &&
      formData.session_name &&
      formData.incometype &&
      formData.budget
    ) {
      dispatch(
        fetchExpenditureDetails({
          group_year_fk: formData.year,
          session_fk: formData.session_name,
          group_income_fk: formData.incometype,
          budget_name: formData.budget,
          accessToken: authData.accessToken,
        })
      );
    }
  }, [
    dispatch,
    formData.year,
    formData.session_name,
    formData.incometype,
    formData.budget,
    authData.accessToken,
  ]);

  const handleChange = (e) => {
    const selectedExpenditure = expenditureItems?.items?.find(
      (item) => item._id === e.target.value
    );
    handleFormDataChange({
      expenditure: e.target.value,
      expenditure_name: e.target.options[e.target.selectedIndex].text,
      expenditure_remain: selectedExpenditure.remain_amount,
      project: "",
      project_name: "",
      activity: "",
      activity_name: "",
      activity_remain: "",
      withdrawal_name: "",
      amount_request: "",
    });
  };

  const renderOptions = () => {
    if (expenditureItems?.items && expenditureItems.items.length > 0) {
      return expenditureItems.items.map((item) =>
        (formData.withdrawal_type === "activity" && item.project_allow) ||
        (formData.withdrawal_type === "expenditure" && !item.project_allow) ? (
          <option key={item._id} value={item._id}>
            {item.expenditure_name}, ระดับการศึกษา:{" "}
            {item.level === "PRIMARY"
              ? "ประถมศึกษา"
              : item.level === "KINDERGARTEN"
              ? "อนุบาล"
              : "อื่นๆ"}
            , งบคงเหลือ: {item.remain_amount.toLocaleString()}
          </option>
        ) : null
      );
    } else {
      return null;
    }
  };

  return (
    <FormGroup>
      <Label for="expenditure">
        รายจ่าย {isLoading && <Spinner size="sm" color="primary" />}
      </Label>
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="expenditure"
        id="expenditure"
        value={formData.expenditure || ""}
        onChange={handleChange}
        disabled={isLoading}
      >
        {formData.expenditure !== "" ? null : (
          <option value="">กรุณาเลือกรายจ่าย</option>
        )}
        {renderOptions()}
      </Input>
    </FormGroup>
  );
};

export default Expenditure;
