// components/SelectBudgetForm.js
import React, { useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import "./SelectBudgetForm.css";
const SelectBudgetForm = ({ onSelect }) => {
  const [selection, setSelection] = useState("");

  const handleChange = (e) => {
    setSelection(e.target.value);
    onSelect(e.target.value);
  };

  return (
    <Form>
      <FormGroup>
        <Label for="budgetOption">เลือกวิธีการจัดการงบประมาณ</Label>
        <Input
          type="select"
          name="budgetOption"
          id="budgetOption"
          defaultValue={""}
          onChange={handleChange}
        >
          <option value="">กรุณาเลือก</option>
          <option value="select">เลือกปีการศึกษา</option>
          <option value="create" className="add-income-type-form">
            {" "}
            + สร้างปีการศึกษา
          </option>
          <option value="change-status" className="change-status-budget-form">
            เปลี่ยนการเปิดปิดปีการศึกษา
          </option>
        </Input>
      </FormGroup>
    </Form>
  );
};

export default SelectBudgetForm;
