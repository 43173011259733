import React from "react";

const NoData = () => {
  return (
    <div className="withdrawal-no-data">
      <p>No data available</p>
    </div>
  );
};

export default NoData;
