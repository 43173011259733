import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteProject } from "../../actions/budget/addAction";

const DeleteProjectForm = ({
  isOpen,
  toggle,
  expenditureId,
  refreshProjects,
}) => {
  const dispatch = useDispatch();
  const { data: projectData } = useSelector(
    (state) => state.budgetState.project
  );
  const [selectedProject, setSelectedProject] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedProject) {
      setError("กรุณาเลือกโครงการที่ต้องการลบ.");
      return;
    }

    const payload = {
      budget_list_id: expenditureId,
      project_id: selectedProject,
    };

    dispatch(deleteProject(payload))
      .then(() => {
        setSuccess(true);
        refreshProjects();
        setSelectedProject("");
      })
      .catch((err) => {
        setError("เกิดข้อผิดพลาดในการลบโครงการ.");
      });
  };

  const handleClose = () => {
    setError(null);
    setSuccess(false);
    toggle();
    refreshProjects();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleClose}>ลบโครงการ</ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          {success && <Alert color="success">ลบโครงการสำเร็จ!</Alert>}
          {!success && (
            <>
              <FormGroup>
                <Label for="projectSelect">เลือกโครงการ</Label>
                <Input
                  type="select"
                  id="projectSelect"
                  value={selectedProject}
                  onChange={(e) => setSelectedProject(e.target.value)}
                >
                  <option value="">-- เลือกโครงการ --</option>
                  {projectData &&
                    projectData.data &&
                    projectData.data.map((project) => (
                      <option
                        key={project._id}
                        value={project._id}
                        disabled={project.activity_list.length > 0} // Disable if project has activities
                      >
                        {project.project_name}{" "}
                        {project.activity_list.length > 0 && "(ไม่สามารถลบได้)"}
                      </option>
                    ))}
                </Input>
              </FormGroup>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {success ? (
            <Button color="primary" onClick={handleClose}>
              ปิดหน้าต่าง
            </Button>
          ) : (
            <>
              <Button type="submit" color="danger" disabled={!selectedProject}>
                ลบโครงการ
              </Button>{" "}
              <Button color="secondary" onClick={handleClose}>
                ยกเลิก
              </Button>
            </>
          )}
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default DeleteProjectForm;
