// expenditureBudget.js
import { createSlice } from "@reduxjs/toolkit";
import { fetchExpenditureDetails } from "../../actions/budget/fetchAction";
import { deleteExpenditure } from "../../actions/budget/addAction";
import { resetBudget } from "../budgetReducer";

const initialState = {
  success: false,
  data: { items: [] },
  isLoading: false,
  error: null,
};

const expenditureSlice = createSlice({
  name: "expenditure",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpenditureDetails.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchExpenditureDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchExpenditureDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteExpenditure.pending, (state) => {
        state.success = false;
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteExpenditure.fulfilled, (state) => {
        state.success = true;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(deleteExpenditure.rejected, (state, action) => {
        state.success = false;
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(resetBudget, () => initialState); // Reset state
  },
});

export default expenditureSlice.reducer;
