import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import Alert from "../../config/Alert";
import { addProject } from "../../actions/budget/addAction";

const AddProjectForm = ({ isOpen, toggle, expenditureId, refreshProjects }) => {
  const dispatch = useDispatch();
  const { data: expenditureData } = useSelector(
    (state) => state.budgetState.expenditure
  );
  const [projectNames, setProjectNames] = useState([""]);
  const [error, setError] = useState(null);
  const [showSummary, setShowSummary] = useState(false);
  const [budgetFk, setBudgetFk] = useState("");
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const [submissionError, setSubmissionError] = useState(null);

  useEffect(() => {
    if (expenditureData) {
      const item = expenditureData.items.find(
        (item) => item._id === expenditureId
      );
      if (item) {
        setBudgetFk(expenditureData.id);
      }
    }
  }, [expenditureData, expenditureId]);

  const handleChange = (index, value) => {
    const updatedProjects = [...projectNames];
    updatedProjects[index] = value;
    setProjectNames(updatedProjects);
  };

  const handleAddProjectField = () => {
    setProjectNames([...projectNames, ""]);
  };

  const handleRemoveProjectField = (index) => {
    setProjectNames(projectNames.filter((_, i) => i !== index));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (projectNames.some((name) => !name.trim())) {
      setError("All project names must be filled.");
      return;
    }
    setError(null);
    setShowSummary(true);
  };

  const confirmSubmit = () => {
    const payload = {
      budget_fk: budgetFk,
      budget_list_id: expenditureId,
      project_names: projectNames.filter((name) => name.trim()),
    };

    dispatch(addProject(payload))
      .then(() => {
        setSubmissionSuccess(true);
        setShowSummary(false);
      })
      .catch((err) => {
        setSubmissionError(err.message);
      });
  };

  const handleClose = () => {
    setSubmissionSuccess(false);
    setSubmissionError(null);
    toggle();
    refreshProjects();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggle}>เพิ่มโครงการ</ModalHeader>
        <ModalBody>
          {error && <Alert color="danger">{error}</Alert>}
          {showSummary ? (
            <div>
              <h5>สรุปข้อมูลที่จะส่งไป</h5>
              <Table bordered>
                <thead>
                  <tr>
                    <th>รหัสงบประมาณ: </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{budgetFk}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th>รหัสรายจ่าย: </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{expenditureId}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th>ชื่อโครงการ:</th>
                  </tr>
                </thead>
                <tbody>
                  {projectNames
                    .filter((name) => name.trim())
                    .map((name, index) => (
                      <tr key={index}>
                        <td>{name}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              <Button
                color="primary"
                onClick={confirmSubmit}
                className="mt-2 w-100"
              >
                ยืนยันและส่งข้อมูล
              </Button>
              <Button
                color="secondary"
                onClick={() => setShowSummary(false)}
                className="mt-2 w-100"
              >
                กลับไปแก้ไข
              </Button>
            </div>
          ) : submissionSuccess ? (
            <div>
              <Alert color="success">ส่งข้อมูลสำเร็จ</Alert>
              <Button
                color="primary"
                onClick={handleClose}
                className="mt-2 w-100"
              >
                ตกลง
              </Button>
            </div>
          ) : submissionError ? (
            <div>
              <Alert color="danger">{submissionError}</Alert>
              <Button
                color="primary"
                onClick={handleClose}
                className="mt-2 w-100"
              >
                ตกลง
              </Button>
            </div>
          ) : (
            projectNames.map((name, index) => (
              <FormGroup key={index}>
                <Row>
                  <Col xs="auto">
                    <Label for={`project_name_${index}`}>โครงการ:</Label>
                  </Col>
                  <Col>
                    <Input
                      type="text"
                      name={`project_name_${index}`}
                      id={`project_name_${index}`}
                      value={name}
                      onChange={(e) => handleChange(index, e.target.value)}
                    />
                  </Col>
                  {projectNames.length > 1 && (
                    <Col xs="auto">
                      <Button
                        color="danger"
                        onClick={() => handleRemoveProjectField(index)}
                        style={{ height: "fit-content" }}
                        outline
                      >
                        X
                      </Button>
                    </Col>
                  )}
                </Row>
              </FormGroup>
            ))
          )}
          {!showSummary && !submissionSuccess && !submissionError && (
            <Button
              color="primary"
              onClick={handleAddProjectField}
              className="mt-2 w-100"
              outline
            >
              เพิ่มโครงการ
            </Button>
          )}
        </ModalBody>
        {!showSummary && !submissionSuccess && !submissionError && (
          <ModalFooter>
            <Button type="submit" color="primary">
              บันทึก
            </Button>{" "}
            <Button color="secondary" onClick={toggle}>
              ยกเลิก
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

export default AddProjectForm;
