import React, { useEffect } from "react";
import { Container, Row, Col, Card, CardBody, CardTitle } from "reactstrap";

const Step3Summary = ({ basicInfo, formDataOut, formDataIn, error }) => {
  useEffect(() => {
    console.log("formDataIn: ", formDataIn);
    console.log("formDataOut: ", formDataOut);
  }, [formDataIn, formDataOut]);

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-container mb-4">
            <CardBody>
              <CardTitle tag="h2" className="text-center mb-4">
                Step 3: สรุป งบประมาณที่โอนออก และ โอนเข้า
              </CardTitle>
              {error && <p className="text-danger">{error}</p>}
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h3" className="mb-3">
                    ข้อมูลผู้ใช้
                  </CardTitle>
                  <p>
                    <strong>ชื่อผู้ใช้:</strong> {basicInfo.username}
                  </p>
                  <p>
                    <strong>ตำแหน่ง:</strong> {basicInfo.position}
                  </p>
                  {/* Add other relevant basic info fields here */}
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h3" className="mb-3">
                    ข้อมูลงบประมาณที่จะโอนออก
                  </CardTitle>
                  <p>
                    <strong>ประเภทการโอน:</strong> {formDataOut.transfer_type}
                  </p>
                  <p>
                    <strong>ปี:</strong> {formDataOut.year_name}
                  </p>
                  <p>
                    <strong>ภาคเรียน:</strong> {formDataOut.session_name}
                  </p>
                  <p>
                    <strong>แหล่งงบประมาณ:</strong>{" "}
                    {formDataOut.incometype_name}
                  </p>
                  <p>
                    <strong>งบประมาณ:</strong> {formDataOut.budget}
                  </p>
                  <p>
                    <strong>รายจ่าย:</strong> {formDataOut.expenditure_name}
                  </p>
                  {formDataOut.transfer_type === "activity" && (
                    <>
                      <p>
                        <strong>โครงการ:</strong> {formDataOut.project_name}
                      </p>
                      <p>
                        <strong>กิจกรรม:</strong> {formDataOut.activity_name}
                      </p>
                    </>
                  )}
                  <p>
                    <strong>จำนวนเงินที่ต้องการโอนออก:</strong>{" "}
                    {formDataOut.amount_request.toLocaleString()}
                  </p>
                  <p>
                    <strong>ชื่อรายการโอนออก:</strong>{" "}
                    {formDataOut.transfer_name}
                  </p>
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h3" className="mb-3">
                    ข้อมูลงบประมาณที่จะโอนเข้า
                  </CardTitle>
                  <p>
                    <strong>ประเภทการโอน:</strong> {formDataIn.transfer_type}
                  </p>
                  <p>
                    <strong>ปี:</strong> {formDataIn.year_name}
                  </p>
                  <p>
                    <strong>ภาคเรียน:</strong> {formDataIn.session_name}
                  </p>
                  <p>
                    <strong>แหล่งงบประมาณ:</strong> {formDataIn.incometype_name}
                  </p>
                  <p>
                    <strong>งบประมาณ:</strong> {formDataIn.budget}
                  </p>
                  <p>
                    <strong>รายจ่าย:</strong> {formDataIn.expenditure_name}
                  </p>
                  {formDataIn.transfer_type === "activity" && (
                    <>
                      <p>
                        <strong>โครงการ:</strong> {formDataIn.project_name}
                      </p>
                      <p>
                        <strong>กิจกรรม:</strong> {formDataIn.activity_name}
                      </p>
                    </>
                  )}
                  <p>
                    <strong>จำนวนเงินโอนเข้า:</strong>{" "}
                    {formDataIn.amount_request}
                  </p>
                  <p>
                    <strong>ชื่อรายการโอนเข้า:</strong>{" "}
                    {formDataIn.transfer_name}
                  </p>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Step3Summary;
