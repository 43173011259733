import { createSlice } from "@reduxjs/toolkit";
import { fetchIncomeTypes } from "../../actions/budget/fetchAction";
import { addIncomeType } from "../../actions/budget/addAction";
import { resetBudget } from "../budgetReducer";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const incomeTypesSlice = createSlice({
  name: "incomeTypes",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchIncomeTypes.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchIncomeTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchIncomeTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(addIncomeType.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addIncomeType.fulfilled, (state) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(addIncomeType.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(resetBudget, () => initialState); // Reset state
  },
});

export default incomeTypesSlice.reducer;
