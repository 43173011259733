import React, { Fragment, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Card,
  CardBody,
  CardText,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { changeWithdrawalStatus } from "../../../actions/listAction";
import { getStatusOptions } from "../../../config/components/List/getStatusOptions";

const ChangeStatus = ({ withdrawal }) => {
  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [amountActual, setAmountActual] = useState("");
  const [showComment, setShowComment] = useState(false);
  const [showAmountActual, setShowAmountActual] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const { position, username, accessToken } = useSelector(
    (state) => state.authState
  );

  const handleChangeStatus = async () => {
    setLoading(true);
    setError(null);
    try {
      const body = {
        username,
        withdrawal_id: withdrawal._id,
        new_status: status.split(",")[1],
        new_stage: status.split(",")[2],
        user_position: position,
        amount_actual: amountActual || undefined,
        comment: comment || undefined,
        accessToken,
      };
      await dispatch(changeWithdrawalStatus(body));
      setSuccess(true);
    } catch (err) {
      setError("Error changing status");
    }
    setLoading(false);
  };

  const handleStatusChange = (e) => {
    const selectedOption = e.target.value;
    setStatus(selectedOption);
    const optionParts = selectedOption.split(",");
    setShowComment(optionParts.includes("comment"));
    setShowAmountActual(optionParts.includes("amount_actual"));
    setConfirm(false);
  };

  const resetForm = () => {
    setStatus("");
    setComment("");
    setAmountActual("");
    setShowComment(false);
    setShowAmountActual(false);
    setConfirm(false);
  };

  if (success) {
    return (
      <Fragment>
        <h5>เปลี่ยนแปลงสถานะ</h5>
        <Label>สถานะเปลี่ยนสำเร็จ</Label>
        <br />
        <Button color="primary" onClick={() => window.location.reload()}>
          ยืนยัน
        </Button>
      </Fragment>
    );
  }

  const statusOptions = getStatusOptions(withdrawal, position, username); // เรียกใช้ฟังก์ชัน getStatusOptions

  // ตรวจสอบว่ามีข้อมูลใน statusOptions หรือไม่
  if (statusOptions.length === 0) {
    return null; // ซ่อนฟอร์มถ้าไม่มีข้อมูล
  }

  return (
    <Form>
      <h5>เปลี่ยนแปลงสถานะ</h5>
      <FormGroup>
        <Label for="statusSelect">สถานะ</Label>
        <Input
          type="select"
          name="status"
          id="statusSelect"
          value={status}
          onChange={handleStatusChange}
        >
          <option value="">เลือกสถานะ</option>
          {statusOptions.map((option, index) => (
            <option key={index} value={option}>
              {option.split(",")[0]}
            </option>
          ))}
        </Input>
      </FormGroup>

      {showComment && (
        <FormGroup>
          <Label for="comment">อธิบาย</Label>
          <Input
            type="textarea"
            name="comment"
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </FormGroup>
      )}
      {showAmountActual && (
        <FormGroup>
          <Label for="amountActual">จำนวนเงินที่เบิกจริง</Label>
          <Input
            type="number"
            name="amountActual"
            id="amountActual"
            value={amountActual}
            onChange={(e) => setAmountActual(e.target.value)}
            min="0"
            max={withdrawal.amount_request}
          />
        </FormGroup>
      )}
      {confirm ? (
        <>
          <CardText>ยืนยันการส่ง: {status.split(",")[0]}</CardText>
          {showComment && <CardText>อธิบาย: {comment}</CardText>}
          {showAmountActual && (
            <CardText>จำนวนเงินที่เบิกจริง: {amountActual}</CardText>
          )}
          {error && <CardText color="danger">{error}</CardText>}
          <Button
            color="primary"
            onClick={handleChangeStatus}
            disabled={loading}
          >
            {loading ? "กำลังโหลด..." : "ยืนยัน"}
          </Button>{" "}
          <Button
            color="secondary"
            onClick={() => setConfirm(false)}
            disabled={loading}
          >
            ยกเลิก
          </Button>
        </>
      ) : (
        <Button
          color="primary"
          onClick={() => setConfirm(true)}
          disabled={
            !status ||
            (showComment && !comment) ||
            (showAmountActual && amountActual > withdrawal.amount_request)
          }
        >
          เปลี่ยนสถานะ
        </Button>
      )}
    </Form>
  );
};

export default ChangeStatus;
