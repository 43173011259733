import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";
import handleError from "../handleError";
const Finance_API = config.Finance_API;

// Add Income Type
export const addIncomeType = createAsyncThunk(
  "budget/addIncomeType",
  async (
    { budget_year, session, income_name, accessToken },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        budget_year,
        session,
        income_name,
      };
      const response = await axios.post(
        `${Finance_API}/api/budget/addincometype`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Budget Year
export const addBudgetYear = createAsyncThunk(
  "budget/addBudgetYear",
  async (
    { budget_year, session, incometypes, accessToken },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        budget_year,
        session,
        incometypes,
      };
      const response = await axios.post(`${Finance_API}/api/budget/add`, body, {
        headers,
      });
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Change Status Budget Year
export const changeStatusBudgetYear = createAsyncThunk(
  "budget/changeStatusBudgetYear",
  async (
    { budget_year_id, status, accessToken },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        budget_year_id,
        status,
      };
      const response = await axios.post(
        `${Finance_API}/api/budget/changestatus`,
        body,
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Budget
export const addBudget = createAsyncThunk(
  "budget/addBudget",
  async (
    { group_year_fk, session_fk, group_income_fk, budget_name, accessToken },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        group_year_fk,
        session_fk,
        group_income_fk,
        budget_name,
      };
      const response = await axios.post(
        `${Finance_API}/api/budget/addbudget`,
        body,
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Budgets
export const addBudgets = createAsyncThunk(
  "budget/addBudgets",
  async (
    { group_year_fk, session_fk, group_income_fk, budgets, accessToken },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        group_year_fk,
        session_fk,
        group_income_fk,
        budgets,
      };
      const response = await axios.post(
        `${Finance_API}/api/budget/addbudgets`,
        body,
        {
          headers,
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Expenditure Items
export const addExpenditureItems = createAsyncThunk(
  "budget/addExpenditureItems",
  async (
    {
      group_year_fk,
      session_fk,
      group_income_fk,
      budget_name,
      items,
      accessToken,
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        group_year_fk,
        session_fk,
        group_income_fk,
        budget_name,
        items,
      };
      console.log("Body: ", body);
      const response = await axios.post(
        `${Finance_API}/api/budget/addbudget/items`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Allow Project
export const allowProject = createAsyncThunk(
  "budget/allowProject",
  async ({ budget_fk, budget_list_id }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        budget_fk,
        budget_list_id,
      };
      const response = await axios.post(
        `${Finance_API}/api/budget/projectallow`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Project
export const addProject = createAsyncThunk(
  "project/addProject",
  async (payload, { getState, rejectWithValue }) => {
    const { budget_fk, budget_list_id, project_names } = payload;
    const { authState } = getState();
    const accessToken = authState.accessToken;
    const headers = { "x-auth-token": accessToken };
    const body = {
      budget_fk,
      budget_list_id,
      project_names,
    };
    try {
      const response = await axios.post(
        `${Finance_API}/api/project/addprojects`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Activity
export const addActivity = createAsyncThunk(
  "project/addActivity",
  async (payload, { getState, rejectWithValue }) => {
    const { project_name, budget_list_id, activity } = payload;
    const { authState } = getState();
    const accessToken = authState.accessToken;
    const headers = { "x-auth-token": accessToken };
    const body = {
      project_name,
      budget_list_id,
      activity,
    };

    try {
      const response = await axios.post(
        `${Finance_API}/api/project/addactivity`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Delete Expenditure
export const deleteExpenditure = createAsyncThunk(
  "budget/deleteExpenditure",
  async ({ expenditure_id }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };

      console.log("expenditure_id: ", expenditure_id);

      const response = await axios.delete(
        `${Finance_API}/api/budget/deleteexpenditure`,
        {
          headers,
          data: { expenditure_id },
        }
      );

      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Delete Project
export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async ({ budget_list_id, project_id }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };

      const response = await axios.delete(
        `${Finance_API}/api/project/deleteproject`,
        {
          headers,
          data: { budget_list_id, project_id },
        }
      );
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Delete Activity
export const deleteActivity = createAsyncThunk(
  "project/deleteActivity",
  async ({ budget_list_id, activity_id }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };

      const response = await axios.delete(
        `${Finance_API}/api/project/deleteactivity`,
        {
          headers,
          data: { budget_list_id, activity_id },
        }
      );

      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Withdrawal
export const addWithdrawal = createAsyncThunk(
  "withdrawal/addWithdrawal",
  async ({ formData, authData }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      let body = {
        username: authData.username,
        withdrawal_name: formData.withdrawal_name,
        withdrawal_type:
          formData.withdrawal_type === "activity"
            ? "เบิกกิจกรรม"
            : "เบิกรายจ่าย",
        expenditure_id: formData.expenditure,
        amount_request: formData.amount_request,
        user_position: authData.position,
      };

      let apiEndpoint = `${Finance_API}/api/withdrawal/`;
      if (formData.withdrawal_type === "activity") {
        body.activity_id = formData.activity;
        apiEndpoint += "activity";
      } else {
        apiEndpoint += "expenditure";
      }

      const response = await axios.post(apiEndpoint, body, { headers });
      return response.data;
    } catch (error) {
      handleError(error, rejectWithValue);
    }
  }
);

// Add Transfer
export const addTransfer = createAsyncThunk(
  "budget/addTransfer",
  async (payload, { getState, rejectWithValue }) => {
    const {
      username,
      transfer_name_out,
      transfer_name_in,
      type,
      budget_list_id_out,
      budget_list_id_in,
      activity_id_out,
      activity_id_in,
      amount_request,
      accessToken,
    } = payload;

    try {
      const { authState } = getState();
      const accessToken = authState.accessToken;
      const headers = { "x-auth-token": accessToken };
      const body = {
        username,
        transfer_name_out,
        transfer_name_in,
        type,
        budget_list_id_out,
        budget_list_id_in,
        activity_id_out,
        activity_id_in,
        amount_request,
      };

      let apiEndpoint = `${Finance_API}/api/transfer/`;
      if (type === "โอนกิจกรรม") {
        apiEndpoint += "activity";
        body.expenditure_id_out = activity_id_out;
        body.expenditure_id_in = activity_id_in;
      } else {
        apiEndpoint += "expenditure";
        body.expenditure_id_out = budget_list_id_out;
        body.expenditure_id_in = budget_list_id_in;
      }

      const response = await axios.post(apiEndpoint, body, { headers });
      return response.data;
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);
