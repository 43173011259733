import React from "react";
import { Card, CardBody, CardTitle, Alert } from "reactstrap";

const Step3Result = ({ formData, error }) => {
  return (
    <div>
      <Card className="mb-4">
        <CardBody>
          <CardTitle tag="h3" className="mb-3">
            ผลการดำเนินการ
          </CardTitle>
          {error ? (
            <Alert color="danger">{error}</Alert>
          ) : (
            <Alert color="success">การส่งเรื่องสำเร็จ</Alert>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default Step3Result;
