import React, { Fragment } from "react";
import Alert from "../../config/Alert";

const ProjectErrorHandling = ({ error = null }) => {
  return (
    <Fragment>
      {error && <Alert color="danger">{error.message || error}</Alert>}
    </Fragment>
  );
};

export default ProjectErrorHandling;
