import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import "./PaginationComponent.css";

const PaginationComponent = ({ currentPage, totalPages, handlePageChange }) => {
  return (
    <div className="pagination-container">
      <Pagination>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink first onClick={() => handlePageChange(1)} />
        </PaginationItem>
        <PaginationItem disabled={currentPage === 1}>
          <PaginationLink
            previous
            onClick={() => handlePageChange(currentPage - 1)}
          />
        </PaginationItem>
        {[...Array(totalPages).keys()].map((page) => (
          <PaginationItem active={currentPage === page + 1} key={page}>
            <PaginationLink onClick={() => handlePageChange(page + 1)}>
              {page + 1}
            </PaginationLink>
          </PaginationItem>
        ))}
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink
            next
            onClick={() => handlePageChange(currentPage + 1)}
          />
        </PaginationItem>
        <PaginationItem disabled={currentPage === totalPages}>
          <PaginationLink last onClick={() => handlePageChange(totalPages)} />
        </PaginationItem>
      </Pagination>
    </div>
  );
};

export default PaginationComponent;
