import { createSlice } from "@reduxjs/toolkit";
import { authToken, getDataUser } from "../actions/authAction";

const initialState = {
  refreshToken: null,
  accessToken: null,
  id: null,
  username: null,
  title: null,
  firstname: null,
  lastname: null,
  email: null,
  active: null,
  position: null,
  phone: null,
  academicStanding: null,
  withdrawal: null,
  transfer: null,
  msg: null,
  isLoading: false,
  isReject: false,
};

const rejectState = {
  ...initialState,
  isReject: true,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: () => initialState,
    setError: (state, action) => {
      return {
        ...state,
        msg: action.payload,
        isLoading: false,
        isReject: true,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(authToken.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(authToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.refreshToken = action.payload.token;
        state.id = action.payload.id;
        state.username = action.payload.username;
        state.title = action.payload.title;
        state.firstname = action.payload.firstname;
        state.lastname = action.payload.lastname;
        state.email = action.payload.email;
        state.active = action.payload.finance_active;
        state.position = action.payload.finance_position;
        state.phone = action.payload.phone;
        state.academicStanding = action.payload.academic_standing;
        state.msg = null;
        state.isReject = false;
      })
      .addCase(authToken.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      })

      .addCase(getDataUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDataUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.accessToken = action.payload.accessToken;
        state.username = action.payload.username;
        state.id = action.payload.id;
        state.withdrawal = action.payload.withdrawal;
        state.transfer = action.payload.transfer;
        state.msg = null;
        state.isReject = false;
      })
      .addCase(getDataUser.rejected, (state, action) => {
        Object.assign(state, rejectState);
        state.msg = action.payload ? action.payload : null;
      });
  },
});

export const { resetAuth, setError } = authSlice.actions;
export default authSlice.reducer;
