import React from "react";
import { Breadcrumb as ReactstrapBreadcrumb, BreadcrumbItem } from "reactstrap";
import "./Breadcrumb.css";

const Breadcrumb = () => {
  return (
    <ReactstrapBreadcrumb>
      <BreadcrumbItem active>Home</BreadcrumbItem>
      <BreadcrumbItem active>List</BreadcrumbItem>
    </ReactstrapBreadcrumb>
  );
};

export default Breadcrumb;
