import React from "react";
import { Input } from "reactstrap";

const SearchBar = ({ searchTerm, handleSearch }) => {
  return (
    <Input
      type="text"
      placeholder="ค้นหารายการรายจ่าย"
      value={searchTerm}
      onChange={handleSearch}
      className="mb-3"
    />
  );
};

export default SearchBar;
