import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../config/config";
const Finance_API = config.Finance_API;

// Fetch Withdrawals
export const fetchWithdrawals = createAsyncThunk(
  "withdrawals/fetchWithdrawals",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const response = await axios.post(
        `${Finance_API}/api/withdrawal/withdrawallist/all`,
        {},
        { headers }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch User-specific Withdrawals
export const fetchUserWithdrawals = createAsyncThunk(
  "withdrawals/fetchUserWithdrawals",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const response = await axios.post(
        `${Finance_API}/api/withdrawal/withdrawallist/list`,
        { username: authState.username },
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Activity-specific Withdrawals
export const fetchActivityWithdrawals = createAsyncThunk(
  "withdrawals/fetchActivityWithdrawals",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const response = await axios.post(
        `${Finance_API}/api/withdrawal/withdrawallist/activitylist`,
        { username: authState.username },
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Change Withdrawal Status
export const changeWithdrawalStatus = createAsyncThunk(
  "withdrawals/changeWithdrawalStatus",
  async (
    {
      username,
      withdrawal_id,
      new_status,
      new_stage,
      user_position,
      amount_actual,
      comment,
      accessToken,
    },
    { rejectWithValue }
  ) => {
    try {
      const headers = { "x-auth-token": accessToken };
      const body = {
        username,
        withdrawal_id,
        new_status,
        new_stage,
        user_position,
        amount_actual,
        comment,
      };
      const response = await axios.post(
        `${Finance_API}/api/withdrawal/changestatus`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Transfers
export const fetchTransfers = createAsyncThunk(
  "transfers/fetchTransfers",
  async ({ budget_year, session }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const validBudgetYear = Number(budget_year);
      const validSession = String(session);
      const response = await axios.post(
        `${Finance_API}/api/transfer/list`,
        { budget_year: validBudgetYear, session: validSession },
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Withdrawl Year List
export const fetchWithdrawalYears = createAsyncThunk(
  "withdrawals/fetchWithdrawYears",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const response = await axios.get(
        `${Finance_API}/api/withdrawal/yearlist`,
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Withdrawl Session List
export const fetchWithdrawSessions = createAsyncThunk(
  "withdrawals/fetchWithdrawSessions",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const response = await axios.get(
        `${Finance_API}/api/withdrawal/sessionlist`,
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Withdrawal Log List
export const fetchWithdrawalLog = createAsyncThunk(
  "withdrawals/fetchWithdrawalLog",
  async (
    { budget_year, session, withdrawal_id },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const validBudgetYear = Number(budget_year);
      const validSession = String(session);
      const response = await axios.post(
        `${Finance_API}/api/withdrawal/log`,
        { budget_year: validBudgetYear, session: validSession, withdrawal_id },
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Transfer Year List
export const fetchTransferYears = createAsyncThunk(
  "transfers/fetchTransferYears",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const response = await axios.get(`${Finance_API}/api/transfer/yearlist`, {
        headers: { "x-auth-token": authState.accessToken },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Fetch Transfer Session List
export const fetchTransferSessions = createAsyncThunk(
  "transfers/fetchTransferSessions",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const response = await axios.get(
        `${Finance_API}/api/transfer/sessionlist`,
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update Link Docment in Withdrawal Modal
export const updateLinkDoc = createAsyncThunk(
  "withdrawals/updateLinkDoc",
  async (
    { year, session, id, link, username },
    { getState, rejectWithValue }
  ) => {
    try {
      const body = { year, session, id, link, username };
      const { authState } = getState();
      const response = await axios.post(
        `${Finance_API}/api/withdrawal/linkdoc`,
        body,
        {
          headers: { "x-auth-token": authState.accessToken },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Reset Actions
export const RESET_WITHDRAWALS = "withdrawals/resetWithdrawals";
export const RESET_TRANSFERS = "transfers/resetTransfers";
