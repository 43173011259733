import React from "react";
import { useSelector } from "react-redux";
import { Card, CardBody, CardTitle, Table, Alert } from "reactstrap";

const Step2Summary = ({ formData, error }) => {
  const authData = useSelector((state) => state.authState);

  return (
    <div>
      <Card className="mb-4">
        <CardBody>
          <CardTitle tag="h3" className="mb-3">
            สรุปรายละเอียดการเบิกงบประมาณ
          </CardTitle>
          {error && <Alert color="danger">{error}</Alert>}
          <Table striped>
            <tbody>
              <tr>
                <th scope="row">Username</th>
                <td>{authData.username}</td>
              </tr>
              <tr>
                <th scope="row">ผู้เบิก</th>
                <td>{`${authData.title} ${authData.firstname} ${authData.lastname}`}</td>
              </tr>
              <tr>
                <th scope="row">ปีการศึกษา</th>
                <td>{formData.year_name}</td>
              </tr>
              <tr>
                <th scope="row">ปีงบประมาณ</th>
                <td>{formData.session_name}</td>
              </tr>
              <tr>
                <th scope="row">แหล่งงบประมาณ</th>
                <td>{formData.incometype_name}</td>
              </tr>
              <tr>
                <th scope="row">ฝ่ายที่เบิกงบประมาณ</th>
                <td>{formData.budget}</td>
              </tr>
              <tr>
                <th scope="row">รายจ่าย</th>
                <td>{formData.expenditure_name}</td>
              </tr>
              {formData.withdrawal_type === "activity" && (
                <>
                  <tr>
                    <th scope="row">โครงการ</th>
                    <td>{formData.project_name}</td>
                  </tr>
                  <tr>
                    <th scope="row">กิจกรรม</th>
                    <td>{formData.activity_name}</td>
                  </tr>
                </>
              )}
              <tr>
                <th scope="row">ชื่อรายการเบิก</th>
                <td>{formData.withdrawal_name}</td>
              </tr>
              <tr>
                <th scope="row">จำนวนเงินที่เบิก</th>
                <td>{formData.amount_request}</td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default Step2Summary;
