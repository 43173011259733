import React from "react";
import { Card, CardBody } from "reactstrap";
import ChangeStatus from "../ChangeStatus";

const ChangeStatusSection = ({ withdrawal }) => (
  <Card className="mt-3">
    <CardBody>
      <ChangeStatus withdrawal={withdrawal} />
    </CardBody>
  </Card>
);

export default ChangeStatusSection;
