import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormGroup, Label, Input, Spinner, Alert } from "reactstrap";

const Activity = ({ formData, handleFormDataChange }) => {
  const {
    data: projectItems,
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.project);

  const authData = useSelector((state) => state.authState);

  const [activities, setActivities] = useState([]);

  useEffect(() => {
    if (formData.project && projectItems && projectItems.data) {
      const selectedProject = projectItems.data.find(
        (item) => item._id === formData.project
      );
      setActivities(selectedProject ? selectedProject.activity_list : []);
    }
  }, [formData.project, projectItems]);

  const handleChange = (e) => {
    const selectedActivity = activities.find(
      (activity) => activity.activity_id === e.target.value
    );
    handleFormDataChange({
      activity: e.target.value,
      activity_name: e.target.options[e.target.selectedIndex].text,
      activity_remain: selectedActivity ? selectedActivity.remain_amount : "",
      withdrawal_name: "",
      amount_request: "",
    });
  };

  const renderOptions = () => {
    if (!projectItems || !projectItems.data) {
      return <option value="">ไม่มีรายการ</option>;
    }

    const selectedProject = projectItems.data.find(
      (item) => item._id === formData.project
    );
    const filteredActivities = selectedProject
      ? selectedProject.activity_list.filter(
          (activity) =>
            authData.position === "ADMIN" ||
            activity.res_teacher === authData.username
        )
      : [];

    if (filteredActivities.length > 0) {
      return filteredActivities.map((activity) => (
        <option key={activity.activity_id} value={activity.activity_id}>
          {activity.activity_name} (งบคงเหลือ{" "}
          {activity.remain_amount.toLocaleString()})
        </option>
      ));
    } else {
      return <option value={""}>ไม่มีรายการ</option>;
    }
  };

  return (
    <FormGroup>
      <Label for="activity">
        กิจกรรม {isLoading && <Spinner size="sm" color="primary" />}
      </Label>
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="activity"
        id="activity"
        value={formData.activity || ""}
        onChange={handleChange}
        disabled={isLoading || !formData.project}
      >
        {formData.activity !== "" ? null : (
          <option value="">กรุณาเลือกกิจกรรม</option>
        )}
        {renderOptions()}
      </Input>
    </FormGroup>
  );
};

export default Activity;
