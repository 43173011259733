import React from "react";

const ErrorHandling = () => {
  return (
    <div className="withdrawal-error-handling">
      {/* Placeholder for error handling */}
    </div>
  );
};

export default ErrorHandling;
