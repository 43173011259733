// handleError.js
const handleError = (error, rejectWithValue) => {
  if (error.response) {
    console.log("error.response: ", error.response);
    return rejectWithValue(error.response.data.error);
  } else if (error.request) {
    console.log("error.request: ", error.request);
    return rejectWithValue("No response received from the server.");
  } else {
    console.log("error.message: ", error.message);
    return rejectWithValue("Error setting up the request.");
  }
};

export default handleError;
