import React, { Fragment, useEffect } from "react";
import { Table } from "reactstrap";

const ExpenditureData = ({ details, expenditureData, getLevelName }) => {
  const {
    year,
    yearId,
    session,
    sessionId,
    incomeType,
    incomeTypeId,
    budgetName,
    expenditureName,
    expenditureId,
  } = details;

  return (
    <Fragment>
      <Table bordered>
        <tbody>
          <tr>
            <td>ปีการศึกษา:</td>
            <td>{year}</td>
            <td>รหัสปีการศึกษา:</td>
            <td>{yearId}</td>
          </tr>
          <tr>
            <td>ปีงบประมาณ:</td>
            <td>{session}</td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>แหล่งงบประมาณ:</td>
            <td>{incomeType}</td>
            <td>รหัสแหล่งงบประมาณ:</td>
            <td>{incomeTypeId}</td>
          </tr>
          <tr>
            <td>งบประมาณ:</td>
            <td>{budgetName}</td>
            <td>ระดับการศึกษา:</td>
            <td>
              {expenditureData &&
                getLevelName(
                  expenditureData.find((i) => i._id === expenditureId)?.level ||
                    ""
                )}
            </td>
          </tr>
          <tr>
            <td>รายจ่าย ID:</td>
            <td>{expenditureId}</td>
            <td>รายจ่าย:</td>
            <td>{expenditureName}</td>
          </tr>
          <tr>
            <td>งบตั้งต้นของรายจ่าย:</td>
            <td>
              {(expenditureData &&
                expenditureData
                  .find((i) => i._id === expenditureId)
                  ?.init_amount.toLocaleString()) ||
                ""}
            </td>
            <td>งบคงเหลือของรายจ่าย:</td>
            <td>
              {(expenditureData &&
                expenditureData
                  .find((i) => i._id === expenditureId)
                  ?.remain_amount.toLocaleString()) ||
                ""}
            </td>
          </tr>
        </tbody>
      </Table>{" "}
    </Fragment>
  );
};

export default ExpenditureData;
