import { createSlice } from "@reduxjs/toolkit";
import {
  fetchTransfers,
  fetchTransferYears,
  fetchTransferSessions,
  RESET_TRANSFERS,
} from "../../actions/listAction";

const transferSlice = createSlice({
  name: "transfers",
  initialState: {
    data: [],
    years: [],
    sessions: [],
    isLoading: false,
    error: null,
  },
  reducers: {
    resetTransfers: (state) => {
      state.data = [];
      state.years = [];
      state.sessions = [];
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransfers.pending, (state) => {
        state.isLoading = true;
        state.data = [];
        state.error = null;
      })
      .addCase(fetchTransfers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload.transferList || [];
        state.error = null;
      })
      .addCase(fetchTransfers.rejected, (state, action) => {
        state.isLoading = false;
        state.data = [];
        state.error = action.payload;
      })
      .addCase(fetchTransferYears.pending, (state) => {
        state.isLoading = true;
        state.years = [];
        state.error = null;
      })
      .addCase(fetchTransferYears.fulfilled, (state, action) => {
        state.isLoading = false;
        state.years = action.payload;
        state.error = null;
      })
      .addCase(fetchTransferYears.rejected, (state, action) => {
        state.isLoading = false;
        state.years = [];
        state.error = action.payload;
      })
      .addCase(fetchTransferSessions.pending, (state) => {
        state.isLoading = true;
        state.sessions = [];
        state.error = null;
      })
      .addCase(fetchTransferSessions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessions = action.payload;
        state.error = null;
      })
      .addCase(fetchTransferSessions.rejected, (state, action) => {
        state.isLoading = false;
        state.sessions = [];
        state.error = action.payload;
      })
      .addCase(RESET_TRANSFERS, (state) => {
        state.data = [];
        state.years = [];
        state.sessions = [];
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { resetTransfers } = transferSlice.actions;
export default transferSlice.reducer;
