import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTransferSessions } from "../../../actions/listAction";
import { FormGroup, Label, Input, Spinner } from "reactstrap";

const SessionField = ({ selectedSession, setSelectedSession }) => {
  const dispatch = useDispatch();
  const { sessions, isLoading, error } = useSelector(
    (state) => state.listState.transfers
  );

  useEffect(() => {
    dispatch(fetchTransferSessions());
  }, [dispatch]);

  const handleChange = (e) => {
    setSelectedSession(e.target.value);
  };

  return (
    <FormGroup>
      <Label for="session">ปีงบประมาณ</Label>
      {isLoading ? (
        <Spinner color="primary" />
      ) : (
        <Fragment>
          {error ? (
            <p className="text-danger">Error loading sessions: {error.error}</p>
          ) : (
            <Input
              type="select"
              name="session"
              id="session"
              value={selectedSession}
              onChange={handleChange}
            >
              <option value="">เลือกปีงบประมาณ</option>
              {sessions?.sessions &&
                sessions.sessions.map((session, index) => (
                  <option key={index} value={session}>
                    {session}
                  </option>
                ))}
            </Input>
          )}
        </Fragment>
      )}
    </FormGroup>
  );
};

export default SessionField;
