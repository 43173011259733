import React, { useState } from "react";
import { Button } from "reactstrap";
import AddActivityForm from "./AddActivityForm";

const AddActivityButton = ({ hasProjects, expenditureId, refreshProjects }) => {
  const [isActivityModalOpen, setActivityModalOpen] = useState(false);

  const toggleActivityModal = () => setActivityModalOpen(!isActivityModalOpen);

  return (
    <>
      <Button
        color="primary"
        onClick={toggleActivityModal}
        disabled={!hasProjects}
        style={{ width: "100%", marginBottom: "10px" }}
        outline
      >
        สร้างกิจกรรม
      </Button>
      <AddActivityForm
        color="primary"
        isOpen={isActivityModalOpen}
        toggle={toggleActivityModal}
        expenditureId={expenditureId}
        refreshProjects={refreshProjects}
      />
    </>
  );
};

export default AddActivityButton;
