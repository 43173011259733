export const getStatusOptions = (withdrawal, position, username) => {
  const options = new Set();
  if (
    withdrawal.status.name === "รอตรวจสอบ" &&
    withdrawal.status.stage === "ขั้นตอนตรวจสอบแบบฟอร์ม"
  ) {
    if (["ADMIN", "PLANNER"].includes(position)) {
      if (
        username === withdrawal.withdrawal_username ||
        !withdrawal.res_planner_username ||
        withdrawal.res_planner_username === username
      ) {
        options.add("รับเรื่อง,ฝ่ายแผนกำลังตรวจสอบ,ขั้นตอนตรวจสอบแบบฟอร์ม");
        options.add(
          "ยกเลิกการเบิก,ยกเลิกการเบิก,ขั้นตอนตรวจสอบแบบฟอร์ม,comment"
        );
      }
    }
    if (username === withdrawal.withdrawal_username) {
      options.add("ยกเลิกการเบิก,ยกเลิกการเบิก,ขั้นตอนตรวจสอบแบบฟอร์ม,comment");
    }
  } else if (
    withdrawal.status.name === "ฝ่ายแผนกำลังตรวจสอบ" &&
    withdrawal.status.stage === "ขั้นตอนตรวจสอบแบบฟอร์ม"
  ) {
    if (["ADMIN", "PLANNER"].includes(position)) {
      if (withdrawal.res_planner_username === username) {
        options.add(
          "ยกเลิกการเบิก,ยกเลิกการเบิก,ขั้นตอนตรวจสอบแบบฟอร์ม,comment"
        );
      }
    }
    if (["ADMIN", "PROCUREMENT", "FINANCE", "HEADFINANCE"].includes(position)) {
      if (
        username === withdrawal.withdrawal_username ||
        !withdrawal.res_procurement_username ||
        withdrawal.res_procurement_username === username
      ) {
        options.add("รับเรื่อง,ฝ่ายพัสดุกำลังตรวจสอบ,ขั้นตอนตรวจสอบเอกสาร");
      }
    }
  } else if (
    withdrawal.status.name === "ฝ่ายพัสดุกำลังตรวจสอบ" &&
    withdrawal.status.stage === "ขั้นตอนตรวจสอบเอกสาร"
  ) {
    if (["ADMIN", "PROCUREMENT", "FINANCE", "HEADFINANCE"].includes(position)) {
      if (withdrawal.res_procurement_username === username) {
        options.add(
          "เอกสารมีปัญหา แจ้งกลับไปยังผู้ส่ง,แก้ไขข้อมูลในเอกสาร,ขั้นตอนตรวจสอบเอกสาร,comment"
        );
        options.add("ยกเลิกการเบิก,ยกเลิกการเบิก,ขั้นตอนตรวจสอบเอกสาร,comment");
      }
    }
    if (["ADMIN", "FINANCE", "HEADFINANCE"].includes(position)) {
      if (
        username === withdrawal.withdrawal_username ||
        !withdrawal.res_finance_username ||
        withdrawal.res_finance_username === username
      ) {
        options.add("รับเรื่อง,การเงินกำลังตรวจสอบ,ขั้นตอนรับเงินเบิก");
      }
    }
  } else if (
    withdrawal.status.name === "แก้ไขข้อมูลในเอกสาร" &&
    withdrawal.status.stage === "ขั้นตอนตรวจสอบเอกสาร"
  ) {
    if (withdrawal.res_procurement_username === username) {
      options.add("รับเรื่อง,ฝ่ายพัสดุกำลังตรวจสอบ,ขั้นตอนตรวจสอบเอกสาร");
      options.add("ยกเลิกการเบิก,ยกเลิกการเบิก,ขั้นตอนตรวจสอบเอกสาร,comment");
    }
  } else if (
    withdrawal.status.name === "การเงินกำลังตรวจสอบ" &&
    withdrawal.status.stage === "ขั้นตอนรับเงินเบิก"
  ) {
    if (["ADMIN", "FINANCE", "HEADFINANCE"].includes(position)) {
      if (withdrawal.res_finance_username === username) {
        options.add(
          "รอจ่ายเงินเบิก,รอจ่ายเงินเบิก,ขั้นตอนรับเงินเบิก,amount_actual"
        );
        options.add(
          "อนุมัติการเบิก,อนุมัติการเบิก,ขั้นตอนรับเงินเบิก,amount_actual"
        );
        options.add("ยกเลิกการเบิก,ยกเลิกการเบิก,ขั้นตอนรับเงินเบิก,comment");
      }
    }
  } else if (
    withdrawal.status.name === "รอจ่ายเงินเบิก" &&
    withdrawal.status.stage === "ขั้นตอนรับเงินเบิก"
  ) {
    if (["ADMIN", "FINANCE", "HEADFINANCE"].includes(position)) {
      if (withdrawal.res_finance_username === username) {
        options.add(
          "อนุมัติการเบิก,อนุมัติการเบิก,ขั้นตอนรับเงินเบิก,amount_actual"
        );
        options.add("ยกเลิกการเบิก,ยกเลิกการเบิก,ขั้นตอนรับเงินเบิก,comment");
      }
    }
  } else if (
    withdrawal.status.name === "อนุมัติการเบิก" &&
    withdrawal.status.stage === "ขั้นตอนรับเงินเบิก"
  ) {
    if (["ADMIN", "FINANCE", "HEADFINANCE"].includes(position)) {
      if (withdrawal.res_finance_username === username) {
        options.add("เบิกเงินสำเร็จ,เบิกเงินสำเร็จ,ขั้นตอนรับเงินเบิก");
      }
    }
  }
  return Array.from(options);
};
