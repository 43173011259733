import React, { useState } from "react";
import Header from "../components/TransferForm/Header";
import BasicInfo from "../components/TransferForm/BasicInfo";
import FormActions from "../components/TransferForm/FormActions/FormActions";
import StepIndicator from "../components/TransferForm/StepIndicator";
import Step1TransferOut from "../components/TransferForm/Step1TransferOut/Step1TransferOut";
import Step2TransferIn from "../components/TransferForm/Step2TransferIn/Step2TransferIn";
import Step3Summary from "../components/TransferForm/Step3Summary/Step3Summary";
import Step4Result from "../components/TransferForm/Step4Result/Step4Result";
import ErrorBoundary from "../components/TransferForm/ErrorHandling/ErrorBoundary";
import { useDispatch, useSelector } from "react-redux";
// import { addTransfer } from "../actions/budget/addAction";

const TransferForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formDataOut, setFormDataOut] = useState({});
  const [formDataIn, setFormDataIn] = useState({});
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authState);

  if (authData.position !== "ADMIN" && authData.position !== "PLANNER") {
    return <div>คุณไม่มีสิทธิ์ในการเข้าถึงหน้านี้</div>;
  }

  const handleFormDataChangeOut = (newData) => {
    setFormDataOut({ ...formDataOut, ...newData });
  };

  const handleFormDataChangeIn = (newData) => {
    setFormDataIn({ ...formDataIn, ...newData });
  };

  const validateTransferData = () => {
    if (formDataOut.transfer_type === "activity") {
      return (
        formDataOut.year !== formDataIn.year ||
        formDataOut.year_name !== formDataIn.year_name ||
        formDataOut.session !== formDataIn.session ||
        formDataOut.session_name !== formDataIn.session_name ||
        formDataOut.incometype !== formDataIn.incometype ||
        formDataOut.incometype_name !== formDataIn.incometype_name ||
        formDataOut.budget !== formDataIn.budget ||
        formDataOut.expenditure !== formDataIn.expenditure ||
        formDataOut.expenditure_name !== formDataIn.expenditure_name ||
        formDataOut.expenditure_remain !== formDataIn.expenditure_remain ||
        formDataOut.project !== formDataIn.project ||
        formDataOut.project_name !== formDataIn.project_name ||
        formDataOut.activity !== formDataIn.activity ||
        formDataOut.activity_name !== formDataIn.activity_name ||
        formDataOut.activity_remain !== formDataIn.activity_remain
      );
    } else if (formDataOut.transfer_type === "expenditure") {
      return (
        formDataOut.year !== formDataIn.year ||
        formDataOut.year_name !== formDataIn.year_name ||
        formDataOut.session !== formDataIn.session ||
        formDataOut.session_name !== formDataIn.session_name ||
        formDataOut.incometype !== formDataIn.incometype ||
        formDataOut.incometype_name !== formDataIn.incometype_name ||
        formDataOut.budget !== formDataIn.budget ||
        formDataOut.expenditure !== formDataIn.expenditure ||
        formDataOut.expenditure_name !== formDataIn.expenditure_name ||
        formDataOut.expenditure_remain !== formDataIn.expenditure_remain
      );
    }
    return false;
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      if (!validateTransferData()) {
        setError("ค่าที่ใช้โอนต้องมีความแตกต่างกันอย่างน้อย 1 ฟิลด์");
        return;
      }
      const allData = { ...authData, ...formDataOut, ...formDataIn };
      // await dispatch(addTransfer(allData)).unwrap();
      setCurrentStep(4);
    } catch (error) {
      setError(error.message);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1TransferOut
            formData={formDataOut}
            handleFormDataChange={handleFormDataChangeOut}
          />
        );
      case 2:
        return (
          <Step2TransferIn
            formData={formDataIn}
            handleFormDataChange={handleFormDataChangeIn}
            basicInfo={authData}
            formDataOut={formDataOut}
          />
        );
      case 3:
        return (
          <Step3Summary
            basicInfo={authData}
            formDataOut={formDataOut}
            formDataIn={formDataIn}
            error={error}
          />
        );
      case 4:
        return (
          <Step4Result
            basicInfo={authData}
            formDataOut={formDataOut}
            formDataIn={formDataIn}
            error={error}
          />
        );
      default:
        return (
          <Step1TransferOut
            formData={formDataOut}
            handleFormDataChange={handleFormDataChangeOut}
          />
        );
    }
  };

  return (
    <div>
      <Header title="Transfer Form" />
      <StepIndicator currentStep={currentStep} />
      <ErrorBoundary error={error} />
      {renderStepContent()}
      <FormActions
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setError={setError}
        handleSubmit={handleSubmit}
        formDataOut={formDataOut}
        formDataIn={formDataIn}
      />
      <br />
    </div>
  );
};

export default TransferForm;
