import React from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";

const BasicInfo = () => {
  const authState = useSelector((state) => state.authState);

  return (
    <Card className="mb-4">
      <CardBody>
        <CardTitle tag="h3" className="mb-3">
          ข้อมูลผู้ใช้
        </CardTitle>
        <Form>
          <FormGroup>
            <Label for="username">Username</Label>
            <Input
              type="text"
              name="username"
              id="username"
              value={authState.username}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label for="fullname">ผู้โอนงบประมาณ</Label>
            <Input
              type="text"
              name="fullname"
              id="fullname"
              value={`${authState.title} ${authState.firstname} ${authState.lastname}`}
              disabled
            />
          </FormGroup>
          <FormGroup>
            <Label for="academicStanding">วิทยฐานะ</Label>
            <Input
              type="text"
              name="academicStanding"
              id="academicStanding"
              value={authState.academicStanding}
              disabled
            />
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default BasicInfo;
