import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import BasicInfo from "../BasicInfo";
import Year from "../Common/Year";
import Session from "../Common/Session";
import IncomeType from "../Common/IncomeType";
import Budget from "../Common/Budget";
import Expenditure from "../Common/Expenditure";
import Project from "../Common/Project";
import Activity from "../Common/Activity";
import AmountRequestIn from "../Common/AmountRequestIn";
import { useDispatch } from "react-redux";
import { resetBudget } from "../../../reducers/budgetReducer";

const Step2TransferIn = ({
  formData,
  handleFormDataChange,
  basicInfo,
  formDataOut,
}) => {
  const dispatch = useDispatch();

  const [formState, setFormState] = useState({
    ...formData,
    transfer_type: formDataOut.transfer_type || "",
    amount_request: formDataOut.amount_request || "",
  });
  const [resetFields, setResetFields] = useState(false);

  const handleFormStateChange = (newData) => {
    if (
      newData.transfer_type &&
      newData.transfer_type !== formState.transfer_type
    ) {
      // Reset expenditure-related fields when transfer_type changes
      newData.expenditure = "";
      newData.expenditure_name = "";
      newData.expenditure_remain = "";
      newData.project = "";
      newData.project_name = "";
      newData.activity = "";
      newData.activity_name = "";
      newData.activity_remain = "";
      newData.transfer_name = "";
      newData.amount_request = "";
    }
    setFormState((prevState) => ({
      ...prevState,
      ...newData,
    }));
    handleFormDataChange({ ...formState, ...newData });
  };

  useEffect(() => {
    console.log("formStateIn: ", formState);
  }, [formState]);

  const handleReset = () => {
    const resetData = {
      transfer_type: formDataOut.transfer_type || "",
      year: "",
      year_name: "",
      session: "",
      session_name: "",
      incometype: "",
      incometype_name: "",
      budget: "",
      expenditure: "",
      expenditure_name: "",
      expenditure_remain: "",
      project: "",
      project_name: "",
      activity: "",
      activity_name: "",
      activity_remain: "",
      transfer_name: "",
      amount_request: formDataOut.amount_request || "",
    };
    setFormState(resetData);
    handleFormDataChange(resetData);
    dispatch(resetBudget());
    setResetFields(!resetFields);
  };

  const isFormEmpty = !(
    formState.transfer_type ||
    formState.year ||
    formState.year_name ||
    formState.session ||
    formState.session_name ||
    formState.incometype ||
    formState.incometype_name ||
    formState.budget ||
    formState.expenditure ||
    formState.expenditure_name ||
    formState.expenditure_remain ||
    formState.project ||
    formState.project_name ||
    formState.activity ||
    formState.activity_name ||
    formState.activity_remain ||
    formState.transfer_name ||
    formState.amount_request
  );

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md="8">
          <Card className="form-container mb-4">
            <CardBody>
              <CardTitle tag="h2" className="text-center mb-4">
                ส่วนที่ 2: รายละเอียดการโอนเข้า
              </CardTitle>
              <BasicInfo data={basicInfo} />
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h3" className="mb-3">
                    ข้อมูลงบประมาณที่จะโอนออก
                  </CardTitle>
                  <Form>
                    {/* แสดงข้อมูลจาก formDataOut เฉพาะ transfer_name และ amount_request */}
                    <FormGroup>
                      <Label for="transfer_name">ชื่อรายการโอนออก</Label>
                      <Input
                        type="text"
                        name="transfer_name"
                        id="transfer_name"
                        value={formDataOut.transfer_name || ""}
                        disabled
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="amount_request">
                        จำนวนเงินที่ต้องการโอนออก
                      </Label>
                      <Input
                        type="number"
                        name="amount_request"
                        id="amount_request"
                        value={formDataOut.amount_request || ""}
                        disabled
                      />
                    </FormGroup>
                  </Form>
                </CardBody>
              </Card>
              <Card className="mb-4">
                <CardBody>
                  <CardTitle tag="h3" className="mb-3">
                    ข้อมูลงบประมาณที่จะโอนเข้า{" "}
                    <Button
                      color="danger"
                      size="sm"
                      className="float-right"
                      onClick={handleReset}
                      disabled={isFormEmpty}
                    >
                      รีเซ็ต
                    </Button>
                  </CardTitle>
                  <Form>
                    {formDataOut.transfer_type && (
                      <>
                        <Year
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                          resetFields={resetFields}
                        />
                        <Session
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        <IncomeType
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        <Budget
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        <Expenditure
                          formData={formState}
                          handleFormDataChange={handleFormStateChange}
                        />
                        {formState.transfer_type === "activity" && (
                          <>
                            <Project
                              formData={formState}
                              handleFormDataChange={handleFormStateChange}
                            />
                            <Activity
                              formData={formState}
                              handleFormDataChange={handleFormStateChange}
                            />
                          </>
                        )}
                      </>
                    )}
                  </Form>
                </CardBody>
              </Card>
              <AmountRequestIn
                formData={formState}
                handleFormDataChange={handleFormStateChange}
                formDataOut={formDataOut}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Step2TransferIn;
