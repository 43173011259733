import React, { Fragment, useState } from "react";
import {
  Row,
  Col,
  Button,
  Spinner,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchYearReport } from "../../actions/reportAction";
import BudgetYearField from "./YearReport/BudgetYearField";
import SessionField from "./YearReport/SessionField";
import DateField from "./YearReport/DateField";
import BudgetReportTable from "./YearReport/Table";
import Download from "./YearReport/Download";
import { generatePDF } from "./YearReport/generatePDF";
import { generateCSV } from "./YearReport/generateCSV";
import { generateExcel } from "./YearReport/generateExcel";

const YearReportSection = () => {
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedSession, setSelectedSession] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const dispatch = useDispatch();
  const { isLoading, reportData } = useSelector((state) => state.reportState); // ใช้ข้อมูล reportData จาก state

  const handleFetchReport = () => {
    if (selectedYear && selectedSession && selectedDate) {
      // Adjust year to be in BE (Buddhist Era)
      const [year, month, day] = selectedDate.split("-");
      const buddhistYear = (parseInt(year) + 543).toString();
      const adjustedDate = `${buddhistYear}-${month}-${day}`;

      dispatch(
        fetchYearReport({
          budget_year: selectedYear,
          session: selectedSession,
          date: adjustedDate,
        })
      );
    }
  };

  const handleDownload = (fileType) => {
    // Logic to generate and download the file (PDF/CSV)
    if (fileType === "pdf") {
      if (reportData && reportData.data) {
        generatePDF(reportData.data);
      } else {
        console.log("No data available to generate the PDF.");
      }
    } else if (fileType === "csv") {
      if (reportData && reportData.data) {
        generateCSV(reportData.data);
      } else {
        console.log("No data available to generate the CSV.");
      }
    } else if (fileType === "excel") {
      if (reportData && reportData.data) {
        generateExcel(reportData.data);
      } else {
        console.log("No data available to generate the Excel.");
      }
    }
  };

  return (
    <Card className="mt-4">
      <CardBody>
        <CardTitle tag="h5" className="text-center mb-4">
          ฟอร์มรายงานปีการศึกษา
        </CardTitle>
        <Row>
          <Col md={6}>
            <FormGroup>
              <BudgetYearField
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <SessionField
                selectedYear={selectedYear}
                selectedSession={selectedSession}
                setSelectedSession={setSelectedSession}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}>
            <FormGroup>
              <DateField
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                label="วันที่เลือก"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <Button
              color="primary"
              onClick={handleFetchReport}
              disabled={
                !selectedYear || !selectedSession || !selectedDate || isLoading
              }
            >
              {isLoading ? (
                <>
                  กำลังโหลด... <Spinner size="sm" />
                </>
              ) : (
                "ดึงข้อมูลรายงาน"
              )}
            </Button>
          </Col>
        </Row>

        {/* แสดงตารางเมื่อมีข้อมูล */}
        {reportData && reportData.data && (
          <Fragment>
            <Row className="mt-5">
              <Col>
                <BudgetReportTable reportData={reportData.data} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Download onDownload={handleDownload} />
              </Col>
            </Row>
          </Fragment>
        )}
      </CardBody>
    </Card>
  );
};

export default YearReportSection;
