import { createSlice } from "@reduxjs/toolkit";
import {
  fetchWithdrawals,
  fetchWithdrawalLog,
  fetchWithdrawSessions,
  fetchWithdrawalYears,
  RESET_WITHDRAWALS,
  updateLinkDoc,
} from "../../actions/listAction";

import { addWithdrawal } from "../../actions/budget/addAction";

const withdrawalSlice = createSlice({
  name: "withdrawals",
  initialState: {
    data: [],
    logs: [],
    years: [],
    sessions: [],
    success: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    resetWithdrawals: (state) => {
      state.data = [];
      state.logs = [];
      state.years = [];
      state.sessions = [];
      state.success = false;
      state.isLoading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Withdrawals
      .addCase(fetchWithdrawals.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWithdrawals.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
      })
      .addCase(fetchWithdrawals.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Withdrawal Years
      .addCase(fetchWithdrawalYears.pending, (state) => {
        state.isLoading = true;
        state.years = [];
        state.error = null;
      })
      .addCase(fetchWithdrawalYears.fulfilled, (state, action) => {
        state.isLoading = false;
        state.years = action.payload;
      })
      .addCase(fetchWithdrawalYears.rejected, (state, action) => {
        state.isLoading = false;
        state.years = [];
        state.error = action.payload;
      })

      // Withdrawal Sessions
      .addCase(fetchWithdrawSessions.pending, (state) => {
        state.isLoading = true;
        state.sessions = [];
        state.error = null;
      })
      .addCase(fetchWithdrawSessions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sessions = action.payload;
      })
      .addCase(fetchWithdrawSessions.rejected, (state, action) => {
        state.isLoading = false;
        state.sessions = [];
        state.error = action.payload;
      })

      // Withdrawal Logs
      .addCase(fetchWithdrawalLog.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchWithdrawalLog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.logs = action.payload;
      })
      .addCase(fetchWithdrawalLog.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // addWithdrawal
      .addCase(addWithdrawal.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addWithdrawal.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(addWithdrawal.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Link Doc
      .addCase(updateLinkDoc.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(updateLinkDoc.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(updateLinkDoc.rejected, (state, action) => {
        state.isLoading = false;
        state.success = false;
        state.error = action.payload;
      })

      // Reset Withdrawals
      .addCase(RESET_WITHDRAWALS, (state) => {
        state.data = [];
        state.years = [];
        state.sessions = [];
        state.logs = [];
        state.isLoading = false;
        state.error = null;
      });
  },
});

export const { resetWithdrawals } = withdrawalSlice.actions;
export default withdrawalSlice.reducer;
