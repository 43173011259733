import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchIncomeTypes } from "../../actions/budget/fetchAction";
import { addIncomeType } from "../../actions/budget/addAction";
import { FormGroup, Label, Input, Form, Spinner, Button } from "reactstrap";
import BudgetItemList from "./BudgetItemList";
import "./SelectIncomeTypeField.css";
import Alert from "../../config/Alert";

const SelectIncomeTypeField = ({ year, session, sessionId }) => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector(
    (state) => state.budgetState.incomeTypes
  );
  const authData = useSelector((state) => state.authState);

  const [selectedIncomeType, setSelectedIncomeType] = useState("");
  const [newIncomeType, setNewIncomeType] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);

  useEffect(() => {
    if (year && session) {
      dispatch(
        fetchIncomeTypes({ year, session, accessToken: authData.accessToken })
      );
    }
  }, [dispatch, year, session, authData.accessToken]);

  const handleChange = (e) => {
    setSelectedIncomeType(e.target.value);
    if (e.target.value === "add") {
      setShowAddForm(true);
    } else {
      setShowAddForm(false);
    }
  };

  const handleAddIncomeType = async () => {
    if (newIncomeType) {
      await dispatch(
        addIncomeType({
          budget_year: year,
          session,
          income_name: newIncomeType,
          accessToken: authData.accessToken,
        })
      );
      setNewIncomeType("");
      setShowAddForm(false);
      setSelectedIncomeType("");
      dispatch(
        fetchIncomeTypes({ year, session, accessToken: authData.accessToken })
      );
    }
  };

  const selectedIncomeTypeId = useMemo(() => {
    return data.find((type) => type.name === selectedIncomeType)?._id;
  }, [data, selectedIncomeType]);

  return (
    <>
      <Form>
        <FormGroup>
          <Label for="selectIncomeType">เลือกแหล่งงบประมาณ</Label>
          {isLoading && <Spinner color="primary" />}
          {error && <Alert color="danger">{error}</Alert>}
          <Input
            type="select"
            name="selectIncomeType"
            id="selectIncomeType"
            value={selectedIncomeType}
            onChange={handleChange}
            disabled={isLoading}
          >
            <option value="">กรุณาเลือกแหล่งงบประมาณ</option>
            {data.map((type) => (
              <option key={type._id} value={type.name}>
                {type.name}
              </option>
            ))}
            <option value="add" className="add-income-type-form">
              + เพิ่มแหล่งงบประมาณ
            </option>
          </Input>
        </FormGroup>
        {showAddForm && (
          <FormGroup>
            <Label for="newIncomeType">ชื่อแหล่งงบประมาณใหม่</Label>
            <Input
              type="text"
              name="newIncomeType"
              id="newIncomeType"
              value={newIncomeType}
              onChange={(e) => setNewIncomeType(e.target.value)}
            />
            <br />
            <Button color="primary" onClick={handleAddIncomeType}>
              บันทึก
            </Button>
          </FormGroup>
        )}
      </Form>
      {selectedIncomeTypeId && (
        <BudgetItemList
          yearValue={year}
          yearId={sessionId}
          session={session}
          incomeTypeId={selectedIncomeTypeId}
        />
      )}
    </>
  );
};

export default SelectIncomeTypeField;
