import { createSlice } from "@reduxjs/toolkit";
import { fetchBudgetItemList } from "../../actions/budget/fetchAction";
import { resetBudget } from "../budgetReducer";

const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

const budgetItemsSlice = createSlice({
  name: "budgetItems",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBudgetItemList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchBudgetItemList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchBudgetItemList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(resetBudget, () => initialState); // Reset state
  },
});

export default budgetItemsSlice.reducer;
