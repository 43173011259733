import React, { useState, useEffect } from "react";
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import Activities from "./Activities";

const ProjectDetails = ({ projectData }) => {
  const [open, setOpen] = useState("");
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // console.log("projectData", projectData);
    if (projectData && projectData.data && Array.isArray(projectData.data)) {
      setProjects(projectData.data);
    } else {
      setProjects([]);
    }
  }, [projectData]);

  const toggle = (id) => {
    setOpen(open === id ? "" : id);
  };

  const calculateTotalAmounts = (activities) => {
    const totalInitAmount = activities.reduce(
      (total, activity) => total + activity.init_amount,
      0
    );
    const totalRemainAmount = activities.reduce(
      (total, activity) => total + activity.remain_amount,
      0
    );
    return { totalInitAmount, totalRemainAmount };
  };

  return (
    <div>
      <UncontrolledAccordion open={open} toggle={toggle} stayOpen>
        {projects.length > 0 ? (
          projects.map((project) => {
            const { totalInitAmount, totalRemainAmount } =
              calculateTotalAmounts(project.activity_list);
            return (
              <AccordionItem key={project._id}>
                <AccordionHeader targetId={project._id}>
                  {project.project_name}
                </AccordionHeader>
                <AccordionBody accordionId={project._id}>
                  <p>
                    งบตั้งต้นทั้งหมดของกิจกรรม:{" "}
                    {totalInitAmount.toLocaleString()}
                  </p>
                  <p>
                    งบคงเหลือทั้งหมดของกิจกรรม:{" "}
                    {totalRemainAmount.toLocaleString()}
                  </p>
                  <Activities activities={project.activity_list} />
                </AccordionBody>
              </AccordionItem>
            );
          })
        ) : (
          <div>ไม่พบโครงการ</div>
        )}
      </UncontrolledAccordion>
    </div>
  );
};

export default ProjectDetails;
