import React from "react";
import { Table, Button } from "reactstrap";

const ExpenditureTable = ({
  data,
  handleDetailsClick,
  handleProjectManageClick,
}) => {
  const getRemainAmountStyle = (remain, init) => {
    const percentage = (remain / init) * 100;
    if (percentage <= 30) {
      return { color: "red" };
    } else if (percentage <= 60) {
      return { color: "yellow" };
    } else {
      return { color: "green" };
    }
  };

  return (
    <Table striped className="expenditure-table">
      <thead>
        <tr>
          <th>รายการรายจ่าย</th>
          <th>ระดับ</th>
          <th>งบตั้งต้น</th>
          <th>งบคงเหลือ</th>
          <th>โครงการ</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item._id}>
            <td>{item.expenditure_name}</td>
            <td>{item.level === "PRIMARY" ? "ประถมศึกษา" : "อนุบาล"}</td>
            <td>{item.init_amount.toLocaleString()}</td>
            <td
              className={
                item.remain_amount >= 0 ? "text-success" : "text-danger"
              }
            >
              {item.remain_amount.toLocaleString()}
            </td>
            <td>
              {item.project_allow ? (
                <Button
                  color="primary"
                  onClick={() =>
                    handleProjectManageClick(item.expenditure_name, item._id)
                  }
                >
                  จัดการโครงการ
                </Button>
              ) : (
                <Button color="secondary" disabled>
                  ไม่มีจัดโครงการ
                </Button>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ExpenditureTable;
