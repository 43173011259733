import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addTransfer } from "../../../actions/budget/addAction";

const Step4Result = ({ formDataOut, formDataIn, basicInfo }) => {
  const dispatch = useDispatch();
  const [submissionError, setSubmissionError] = useState(null);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const authData = basicInfo;

  useEffect(() => {
    const submitData = async () => {
      try {
        const payload = {
          username: authData.username,
          transfer_name_out: formDataOut.transfer_name,
          transfer_name_in: formDataIn.transfer_name,
          type:
            formDataOut.transfer_type === "activity"
              ? "โอนกิจกรรม"
              : "โอนรายจ่าย",
          budget_list_id_out: formDataOut.expenditure,
          budget_list_id_in: formDataIn.expenditure,
          activity_id_out:
            formDataOut.transfer_type === "activity"
              ? formDataOut.activity
              : undefined,
          activity_id_in:
            formDataIn.transfer_type === "activity"
              ? formDataIn.activity
              : undefined,
          amount_request: formDataOut.amount_request,
          accessToken: authData.accessToken,
        };

        await dispatch(addTransfer(payload));
        setSubmissionSuccess(true);
      } catch (error) {
        console.error(error);
        setSubmissionError(error.message || "เกิดข้อผิดพลาดในการส่งข้อมูล");
      }
    };

    submitData();
  }, [dispatch, formDataOut, formDataIn, authData]);

  return (
    <div>
      <h2>Step 4: ผลลัพธ์</h2>
      {submissionError ? (
        <p className="text-danger">เกิดข้อผิดพลาด: {submissionError}</p>
      ) : (
        <p className="text-success">ส่งข้อมูลสำเร็จ กรุณากลับหน้าหลัก</p>
      )}
    </div>
  );
};

export default Step4Result;
