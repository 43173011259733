import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip, Legend } from "chart.js";
import { useSelector } from "react-redux";
import Alert from "../../config/Alert";
import { FormGroup, Label, Input, Col, Row } from "reactstrap";

Chart.register(ArcElement, Tooltip, Legend);

const ActivityBudgetChart = ({ projectData }) => {
  const [selectedProject, setSelectedProject] = useState("");
  const [activities, setActivities] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (selectedProject) {
      const project = projectData.find(
        (project) => project.project_name === selectedProject
      );
      if (project) {
        setActivities(project.activity_list);
      } else {
        setActivities([]);
      }
    }
  }, [selectedProject, projectData]);

  const handleChange = (e) => {
    setSelectedProject(e.target.value);
  };

  if (!projectData || projectData.length === 0) {
    return (
      <Alert color="danger">
        ข้อมูลโครงการไม่ถูกต้องหรือไม่มีโครงการในรายการ
      </Alert>
    );
  }

  const data = {
    labels: activities.map((activity) => activity.activity_name),
    datasets: [
      {
        data: activities.map((activity) => activity.init_amount),
        backgroundColor: [
          "#FF6384",
          "#36A2EB",
          "#FFCE56",
          "#4BC0C0",
          "#9966FF",
          "#FF9F40",
        ],
      },
    ],
  };

  if (activities.length === 0) {
    return (
      <div>
        <FormGroup>
          <Label for="projectSelect">เลือกโครงการ</Label>
          <Input
            type="select"
            name="project"
            id="projectSelect"
            value={selectedProject}
            onChange={handleChange}
          >
            <option value="">-- เลือกโครงการ --</option>
            {projectData.map((project, index) => (
              <option key={index} value={project.project_name}>
                {project.project_name}
              </option>
            ))}
          </Input>
        </FormGroup>
        <Alert color="warning">ไม่มีกิจกรรมในโครงการนี้</Alert>
      </div>
    );
  }

  return (
    <div>
      <FormGroup>
        <Label for="projectSelect">เลือกโครงการ</Label>
        <Input
          type="select"
          name="project"
          id="projectSelect"
          value={selectedProject}
          onChange={handleChange}
        >
          <option value="">-- เลือกโครงการ --</option>
          {projectData.map((project, index) => (
            <option key={index} value={project.project_name}>
              {project.project_name}
            </option>
          ))}
        </Input>
      </FormGroup>
      <h5>แผนภูมิวงกลมงบประมาณกิจกรรม</h5>
      <div style={{ maxWidth: "400px", margin: "0 auto" }}>
        <Pie data={data} />
      </div>
    </div>
  );
};

export default ActivityBudgetChart;
