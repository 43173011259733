// Utility function to apply filters to the data
export const applyFilters = (data, filters) => {
  let filtered = [...data];

  if (filters.withdrawalName) {
    filtered = filtered.filter((item) =>
      item.withdrawal_name.includes(filters.withdrawalName)
    );
  }
  if (filters.withdrawalUsername) {
    filtered = filtered.filter((item) =>
      item.withdrawal_username.includes(filters.withdrawalUsername)
    );
  }
  if (filters.withdrawalType) {
    filtered = filtered.filter(
      (item) => item.withdrawal_type === filters.withdrawalType
    );
  }
  if (filters.amountMin) {
    filtered = filtered.filter(
      (item) => (item.amount_actual || item.amount_request) >= filters.amountMin
    );
  }
  if (filters.amountMax) {
    filtered = filtered.filter(
      (item) => (item.amount_actual || item.amount_request) <= filters.amountMax
    );
  }
  if (filters.statusStage) {
    filtered = filtered.filter((item) =>
      item.status.stage.includes(filters.statusStage)
    );
  }
  if (filters.statusName) {
    filtered = filtered.filter((item) =>
      item.status.name.includes(filters.statusName)
    );
  }

  return filtered;
};
