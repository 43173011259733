import { combineReducers } from "@reduxjs/toolkit";
import withdrawalReducer from "./list/withdrawalReducer";
import transferReducer from "./list/transferReducer";

const listReducer = combineReducers({
  withdrawals: withdrawalReducer,
  transfers: transferReducer,
});

export default listReducer;
