import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchBudgetItemList } from "../../actions/budget/fetchAction";
import { Table, Spinner, Button } from "reactstrap";
import AddBudgetForm from "./AddBudgetForm";
import { useNavigate } from "react-router-dom";

import Alert from "../../config/Alert";

const BudgetItemList = ({ yearValue, yearId, session, incomeTypeId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, error } = useSelector(
    (state) => state.budgetState.budgetItems
  );
  const authData = useSelector((state) => state.authState);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    if (yearId && session && incomeTypeId) {
      dispatch(
        fetchBudgetItemList({
          group_year_fk: yearId,
          session_fk: session,
          group_income_fk: incomeTypeId,
          accessToken: authData.accessToken,
        })
      );
    }
  }, [dispatch, yearId, session, incomeTypeId, authData]);

  const getRemainAmountStyle = (remain, init) => {
    const percentage = (remain / init) * 100;
    if (percentage <= 30) {
      return { color: "red" };
    } else if (percentage <= 60) {
      return { color: "yellow" };
    } else {
      return { color: "green" };
    }
  };

  const selectedIncomeType = useSelector((state) =>
    state.budgetState.incomeTypes.data.find((type) => type._id === incomeTypeId)
  );

  const handleDetailsClick = (budget) => {
    navigate(`/expenditure/${budget.budget}`, {
      state: {
        group_year_fk: yearId,
        session_fk: session,
        group_income_fk: incomeTypeId,
        budget_name: budget.budget,
      },
    });
  };

  return (
    <>
      {isLoading && <Spinner color="primary" />}
      <p>
        <Button onClick={toggleModal}>เพิ่มฝ่ายที่ใช้งบประมาณ</Button>
      </p>

      {error && <Alert color="danger">{error}</Alert>}
      {!isLoading && data.length === 0 && (
        <Alert color="warning">ไม่พบข้อมูลรายการงบประมาณ</Alert>
      )}
      {data.length > 0 && (
        <Table striped>
          <thead>
            <tr>
              <th>ฝ่ายที่ใช้งบประมาณ</th>
              <th>งบตั้งต้น</th>
              <th>งบคงเหลือ</th>
              <th>รายละเอียด</th>
            </tr>
          </thead>
          <tbody>
            {data.map((budget) => (
              <tr key={budget.budget}>
                <td>{budget.budget}</td>
                <td>{budget.total_budget_init_amount.toLocaleString()}</td>
                <td
                  style={getRemainAmountStyle(
                    budget.total_budget_remain_amount,
                    budget.total_budget_init_amount
                  )}
                >
                  {budget.total_budget_remain_amount.toLocaleString()}
                </td>
                <td>
                  <button onClick={() => handleDetailsClick(budget)}>
                    รายละเอียด
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {isModalOpen && (
        <AddBudgetForm
          isOpen={isModalOpen}
          toggle={toggleModal}
          year={yearValue}
          groupYearId={yearId}
          session={session}
          sessionId={session}
          incomeTypeId={incomeTypeId}
          incomeTypeName={selectedIncomeType?.name || ""}
        />
      )}
    </>
  );
};

export default BudgetItemList;
