import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { allowProject } from "../../actions/budget/addAction";
import { fetchExpenditureDetails } from "../../actions/budget/fetchAction";

const AddProjectApprovalModal = ({
  isOpen,
  toggle,
  budgetName,
  yearId,
  sessionValue,
  sessionId,
  incomeTypeId,
  expenditureItems,
  budgetId,
}) => {
  const dispatch = useDispatch();
  const [selectedExpenditure, setSelectedExpenditure] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const yearsData = useSelector((state) => state.budgetState.years.data);
  const sessionsData = useSelector((state) => state.budgetState.sessions.data);
  const incomeTypesData = useSelector(
    (state) => state.budgetState.incomeTypes.data
  );
  const authData = useSelector((state) => state.authState);

  const year = yearsData.find((y) => y.id === yearId)?.budget_year || "";
  const session = sessionsData.find((s) => s._id === sessionId)?.session || "";
  const incomeType =
    incomeTypesData.find((i) => i._id === incomeTypeId)?.name || "";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const data = {
      budget_fk: budgetId,
      budget_list_id: selectedExpenditure,
      accessToken: authData.accessToken,
    };

    try {
      await dispatch(allowProject(data));
      toggle();
      dispatch(
        fetchExpenditureDetails({
          group_year_fk: yearId,
          session_fk: sessionValue,
          group_income_fk: incomeTypeId,
          budget_name: budgetName,
          accessToken: authData.accessToken,
        })
      );
    } catch (error) {
      console.error("Error allowing project:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const nonProjectItems = expenditureItems.filter(
    (item) => !item.project_allow
  );

  const translateLevel = (level) => {
    switch (level) {
      case "PRIMARY":
        return "ประถมศึกษา";
      case "KINDERGARTEN":
        return "อนุบาล";
      default:
        return level;
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>อนุมัติจัดตั้งโครงการ</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <FormGroup>
                <Label for="year">ปีการศึกษา</Label>
                <Input
                  type="text"
                  name="year"
                  id="year"
                  value={year}
                  readOnly
                />
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <Label for="session">ปีงบประมาณ</Label>
                <Input
                  type="text"
                  name="session"
                  id="session"
                  value={sessionValue}
                  readOnly
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="incomeType">แหล่งงบประมาณ</Label>
            <Input
              type="text"
              name="incomeType"
              id="incomeType"
              value={incomeType}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="budgetName">งบประมาณ</Label>
            <Input
              type="text"
              name="budgetName"
              id="budgetName"
              value={budgetName}
              readOnly
            />
          </FormGroup>
          <FormGroup>
            <Label for="expenditure">เลือกรายการรายจ่าย</Label>
            <Input
              type="select"
              name="expenditure"
              id="expenditure"
              value={selectedExpenditure}
              onChange={(e) => setSelectedExpenditure(e.target.value)}
              required
            >
              <option value="">เลือกรายการรายจ่าย</option>
              {nonProjectItems.map((item) => (
                <option key={item._id} value={item._id}>
                  {`${item.expenditure_name} (${translateLevel(item.level)})`}
                </option>
              ))}
            </Input>
          </FormGroup>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              disabled={isSubmitting || !selectedExpenditure}
            >
              {isSubmitting ? "กำลังเพิ่ม..." : "ตกลง"}
            </Button>
            <Button color="secondary" onClick={toggle}>
              ยกเลิก
            </Button>
          </ModalFooter>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AddProjectApprovalModal;
