// pages/Budgeting.js
import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectBudgetForm from "../components/Budgeting/SelectBudgetForm";
import AddBudgetYearForm from "../components/Budgeting/AddBudgetYearForm";
import ChangeStatusBudget from "../components/Budgeting/ChangeStatusBudget";
import SelectYearField from "../components/Budgeting/SelectYearField";
import { resetBudget } from "../reducers/budgetReducer";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import "./Budgeting.css";

const Budgeting = () => {
  const { position } = useSelector((state) => state.authState);
  const [selection, setSelection] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetBudget());
  }, [dispatch]);

  const handleSelect = (value) => {
    setSelection(value);
  };

  if (position === "ADMIN" || position === "PLANNER") {
    return (
      <>
        <Container>
          <h3 className="my-4">หน้าจัดการงบประมาณ</h3>
          <Breadcrumb>
            <BreadcrumbItem>
              <a href="/">หน้าหลัก</a>
            </BreadcrumbItem>
            <BreadcrumbItem active>หน้าจัดการงบประมาณ</BreadcrumbItem>
          </Breadcrumb>
          <Row className="row-border">
            <Col>
              <SelectBudgetForm onSelect={handleSelect} />
              {selection === "create" && <AddBudgetYearForm />}
              {selection === "select" && <SelectYearField />}
              {selection === "change-status" && <ChangeStatusBudget />}
            </Col>
          </Row>
        </Container>
      </>
    );
  } else {
    return (
      <Fragment>
        <h3>ไม่มีสิทธิ์ในการเข้าถึงหน้านี้</h3>
      </Fragment>
    );
  }
};

export default Budgeting;
