import React, { Fragment } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
} from "reactstrap";
import { useSelector } from "react-redux";

const MainNavbar = () => {
  const authData = useSelector((state) => state.authState);

  return (
    <Nav className="me-auto" navbar>
      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <i className="bi bi-cash-coin fw-bold px-2" /> การจัดการ
        </DropdownToggle>
        <DropdownMenu end>
          <DropdownItem href="/list">รายการเบิกจ่าย</DropdownItem>
          <DropdownItem href="/withdrawalform">
            แบบฟอร์มการเบิกจ่าย
          </DropdownItem>
          {(authData.position === "ADMIN" ||
            authData.position === "PLANNER") && (
            <Fragment>
              <DropdownItem href="/transferform">
                แบบฟอร์มการโอนงบประมาณ
              </DropdownItem>
              <DropdownItem href="/budgeting">การจัดงบประมาณ</DropdownItem>
            </Fragment>
          )}
          {(authData.position === "ADMIN" ||
            authData.position === "PLANNER" ||
            authData.position === "MANAGEMENT") && (
            <DropdownItem href="/report">สรุปงบประมาณ</DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default MainNavbar;
