import React from "react";
import { Button, Container, Row, Col, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const FormActions = ({
  currentStep,
  setCurrentStep,
  setError,
  handleSubmit,
  formData,
}) => {
  const navigate = useNavigate();
  const { isLoading } = useSelector((state) => state.listState.withdrawals);

  const handleNext = () => {
    setError(null);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setError(null);
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const handleFinish = () => {
    navigate("/");
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col className="text-center">
          {currentStep === 1 && (
            <Button color="primary" onClick={handleNext}>
              ส่งเรื่องเบิกงบประมาณ
            </Button>
          )}
          {currentStep === 2 && (
            <>
              <Button color="secondary" onClick={handleBack} className="mr-2">
                ย้อนกลับ
              </Button>{" "}
              <Button
                color="primary"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <Spinner size="sm" /> กำลังส่งข้อมูล...
                  </>
                ) : (
                  "ยืนยันการส่ง"
                )}
              </Button>
            </>
          )}
          {currentStep === 3 && (
            <Button color="primary" onClick={handleFinish}>
              กลับหน้าหลัก
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default FormActions;
