import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Spinner, Alert } from "reactstrap";
import { fetchProjectDetails } from "../../../actions/budget/fetchAction";

const Project = ({ formData, handleFormDataChange }) => {
  const dispatch = useDispatch();
  const {
    data: projectItems,
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.project);
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    if (formData.expenditure) {
      dispatch(
        fetchProjectDetails({
          budget_list_id: formData.expenditure,
          accessToken: authData.accessToken,
        })
      );
    }
  }, [dispatch, formData.expenditure, authData.accessToken]);

  const handleChange = (e) => {
    handleFormDataChange({
      project: e.target.value,
      project_name: e.target.options[e.target.selectedIndex].text,
      activity: "",
      activity_name: "",
      activity_remain: "",
      transfer_name: "",
      amount_request: "",
    });
  };

  useEffect(() => {}, [projectItems]);

  const renderOptions = () => {
    if (projectItems && projectItems.data && projectItems.data.length > 0) {
      return projectItems.data.map((item) => (
        <option key={item._id} value={item._id}>
          {item.project_name}
        </option>
      ));
    } else {
      return <option value={""}>ไม่มีรายการ</option>;
    }
  };

  return (
    <FormGroup>
      <Label for="project">
        โครงการ {isLoading && <Spinner size="sm" color="primary" />}
      </Label>
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="project"
        id="project"
        value={formData.project || ""}
        onChange={handleChange}
        disabled={isLoading}
      >
        {formData.project !== "" ? null : (
          <option value="">กรุณาเลือกโครงการ</option>
        )}
        {renderOptions()}
      </Input>
    </FormGroup>
  );
};

export default Project;
