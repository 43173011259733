import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addBudgetYear } from "../../actions/budget/addAction";
import { FormGroup, Label, Input, Button, Form, Spinner } from "reactstrap";

import Alert from "../../config/Alert";

const AddBudgetYearForm = () => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.budgetState.years);
  const authData = useSelector((state) => state.authState);

  const [budgetYear, setBudgetYear] = useState("");
  const [session, setSession] = useState("");
  const [incomeTypes, setIncomeTypes] = useState([{ name: "" }]);
  const [apiError, setApiError] = useState(null);

  const handleAddIncomeType = () => {
    setIncomeTypes([...incomeTypes, { name: "" }]);
  };

  const handleIncomeTypeChange = (index, event) => {
    const newIncomeTypes = [...incomeTypes];
    newIncomeTypes[index].name = event.target.value;
    setIncomeTypes(newIncomeTypes);
  };

  const handleRemoveIncomeType = (index) => {
    const newIncomeTypes = incomeTypes.filter((_, i) => i !== index);
    setIncomeTypes(newIncomeTypes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setApiError(null);
    try {
      await dispatch(
        addBudgetYear({
          budget_year: budgetYear,
          session,
          incometypes: incomeTypes,
          accessToken: authData.accessToken,
        })
      ).unwrap();
      setBudgetYear("");
      setSession("");
      setIncomeTypes([{ name: "" }]);
    } catch (err) {
      setApiError(err || "An unexpected error occurred.");
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup>
        <Label for="budgetYear">ปีการศึกษา</Label>
        <Input
          type="number"
          name="budgetYear"
          id="budgetYear"
          value={budgetYear}
          onChange={(e) => setBudgetYear(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label for="session">ปีงบประมาณ</Label>
        <Input
          type="text"
          name="session"
          id="session"
          value={session}
          onChange={(e) => setSession(e.target.value)}
          required
        />
      </FormGroup>
      <FormGroup>
        <Label>แหล่งงบประมาณ</Label>
        {incomeTypes.map((incomeType, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Input
              type="text"
              value={incomeType.name}
              onChange={(e) => handleIncomeTypeChange(index, e)}
              required
              style={{ marginRight: "10px" }}
            />
            {incomeTypes.length > 1 && (
              <Button
                color="danger"
                onClick={() => handleRemoveIncomeType(index)}
                style={{ marginRight: "10px" }}
              >
                -
              </Button>
            )}
            {index === incomeTypes.length - 1 && (
              <Button color="primary" onClick={handleAddIncomeType}>
                +
              </Button>
            )}
          </div>
        ))}
      </FormGroup>
      {apiError && (
        <Alert color="danger">
          {typeof apiError === "string" ? apiError : JSON.stringify(apiError)}
        </Alert>
      )}
      <Button color="primary" type="submit" disabled={isLoading}>
        {isLoading ? <Spinner size="sm" /> : "บันทึก"}
      </Button>
    </Form>
  );
};

export default AddBudgetYearForm;
