import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import AddExpenseModal from "./AddExpenseModal";

const AddExpenseButton = ({
  yearId,
  sessionValue,
  sessionId,
  incomeTypeId,
  budgetName,
}) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button size="sm" color="primary" onClick={toggle} outline block>
        เพิ่มรายการรายจ่าย
      </Button>
      <AddExpenseModal
        isOpen={modal}
        toggle={toggle}
        budgetName={budgetName}
        yearId={yearId}
        sessionValue={sessionValue}
        sessionId={sessionId}
        incomeTypeId={incomeTypeId}
      />
    </div>
  );
};

export default AddExpenseButton;
