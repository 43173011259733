import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const useTokenHandler = (authData, dispatch, authToken, resetAuth) => {
  const [token, setToken] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  // ดึง token จาก URL หนึ่งครั้ง
  const urlToken = new URLSearchParams(window.location.search).get("token");

  useEffect(() => {
    const handleTokenInitialization = () => {
      if (urlToken && !initialized) {
        resetAndSetTokenFromUrl();
      } else if (!urlToken && authData?.refreshToken && !initialized) {
        setToken(authData.refreshToken);
        setInitialized(true);
      } else if (!urlToken && !authData) {
        navigate("/notallow");
      }
    };

    const resetAndSetTokenFromUrl = () => {
      dispatch(resetAuth());
      setToken(urlToken);
      removeTokenFromUrl();
      setInitialized(true);
    };

    const removeTokenFromUrl = () => {
      const url = new URL(window.location);
      url.searchParams.delete("token");
      window.history.replaceState({}, document.title, url);
    };

    handleTokenInitialization();
  }, [urlToken, authData, dispatch, navigate, initialized]);

  useEffect(() => {
    if (token) {
      dispatch(authToken({ refreshToken: token }));
    }
  }, [token, dispatch, authToken]);

  return token;
};
