import React, { useState } from "react";
import { Button } from "reactstrap";
import AddProjectForm from "./AddProjectForm";

const AddProjectButton = ({ expenditureId, refreshProjects }) => {
  const [isProjectModalOpen, setProjectModalOpen] = useState(false);

  const toggleProjectModal = () => setProjectModalOpen(!isProjectModalOpen);

  return (
    <>
      <Button
        color="primary"
        onClick={toggleProjectModal}
        style={{ marginBottom: "10px", width: "100%" }}
        outline
      >
        สร้างโครงการ
      </Button>
      <AddProjectForm
        isOpen={isProjectModalOpen}
        toggle={toggleProjectModal}
        expenditureId={expenditureId}
        refreshProjects={refreshProjects}
      />
    </>
  );
};

export default AddProjectButton;
