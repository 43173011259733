import React from "react";
import { Button } from "reactstrap";

const DeleteExpenseButton = ({ toggle }) => {
  return (
    <Button color="danger" onClick={toggle} size="sm" outline block>
      ลบรายจ่าย
    </Button>
  );
};

export default DeleteExpenseButton;
