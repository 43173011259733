import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input, Spinner } from "reactstrap";
import { fetchIncomeTypes } from "../../../actions/budget/fetchAction";
import Alert from "../../../config/Alert";

const IncomeType = ({ formData, handleFormDataChange }) => {
  const dispatch = useDispatch();
  const {
    data: incomeTypes,
    isLoading,
    error,
  } = useSelector((state) => state.budgetState.incomeTypes);
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    if (formData.year_name && formData.session_name) {
      dispatch(
        fetchIncomeTypes({
          year: Number(formData.year_name),
          session: formData.session_name,
          accessToken: authData.accessToken,
        })
      );
    }
  }, [
    dispatch,
    formData.year_name,
    formData.session_name,
    authData.accessToken,
  ]);

  const handleChange = (e) => {
    handleFormDataChange({
      incometype: e.target.value,
      incometype_name: e.target.options[e.target.selectedIndex].text,
      budget: "",
      expenditure: "",
      expenditure_name: "",
      expenditure_remain: "",
      project: "",
      project_name: "",
      activity: "",
      activity_name: "",
      activity_remain: "",
      withdrawal_name: "",
      amount_request: "",
    });
  };

  return (
    <FormGroup>
      <Label for="incometype">
        แหล่งงบประมาณ {isLoading && <Spinner size="sm" color="primary" />}
      </Label>
      {error && <Alert color="danger">{error}</Alert>}
      <Input
        type="select"
        name="incometype"
        id="incometype"
        value={formData.incometype || ""}
        onChange={handleChange}
        disabled={isLoading}
      >
        {formData.incometype ? null : (
          <option value="">กรุณาเลือกแหล่งงบประมาณ</option>
        )}

        {incomeTypes.map((type) => (
          <option key={type._id} value={type._id}>
            {type.name}
          </option>
        ))}
      </Input>
    </FormGroup>
  );
};

export default IncomeType;
