import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
} from "reactstrap";
import { addBudgets } from "../../actions/budget/addAction";

const AddBudgetForm = ({
  isOpen,
  toggle,
  year,
  groupYearId,
  session,
  sessionId,
  incomeTypeId,
  incomeTypeName,
}) => {
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authState);
  const { isLoading, error } = useSelector((state) => state.budgetState);
  const budgetItemsData = useSelector(
    (state) => state.budgetState.budgetItems.data
  );

  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [formError, setFormError] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const departments = [
    "ฝ่ายบริหารวิชาการ",
    "ฝ่ายบริหารทั่วไป",
    "ฝ่ายบริหารงานบุคคล",
    "ฝ่ายบริหารงบประมาณ",
    "งานกิจการนักเรียน",
    "รายได้แผ่นดิน",
  ];

  const [availableDepartments, setAvailableDepartments] = useState(departments);

  useEffect(() => {
    if (budgetItemsData) {
      const existingDepartments = budgetItemsData.map((item) => item.budget);
      setAvailableDepartments(
        departments.filter((dept) => !existingDepartments.includes(dept))
      );
      console.log(
        "Existing departments in the selected budget source:",
        existingDepartments
      );
    }
  }, [budgetItemsData]);

  const handleCheckboxChange = (department) => {
    setSelectedDepartments((prev) =>
      prev.includes(department)
        ? prev.filter((dep) => dep !== department)
        : [...prev, department]
    );
  };

  const handleSubmit = async () => {
    if (selectedDepartments.length === 0) {
      setFormError("กรุณาเลือกฝ่ายอย่างน้อยหนึ่งฝ่าย");
      return;
    }
    setFormError(null);
    await dispatch(
      addBudgets({
        group_year_fk: groupYearId,
        session_fk: sessionId,
        group_income_fk: incomeTypeId,
        budgets: selectedDepartments,
        accessToken: authData.accessToken,
      })
    );
    setIsSubmitted(true);
  };

  const handleConfirm = () => {
    window.location.reload();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>เพิ่มฝ่าย</ModalHeader>
      <ModalBody>
        {isSubmitted ? (
          <Alert color="success">
            <p>เพิ่มฝ่ายสำเร็จ!</p>
            <Button color="primary" onClick={handleConfirm}>
              ตกลง
            </Button>
          </Alert>
        ) : (
          <Form>
            <FormGroup>
              <Label>ปีการศึกษา</Label>
              <Input type="text" value={year} disabled />
            </FormGroup>
            <FormGroup>
              <Label>ปีงบประมาณ</Label>
              <Input type="text" value={session} disabled />
            </FormGroup>
            <FormGroup>
              <Label>แหล่งงบประมาณ</Label>
              <Input type="text" value={incomeTypeName} disabled />
            </FormGroup>
            <FormGroup>
              <Label for="departments">เลือกฝ่าย</Label>
              {availableDepartments.map((department) => (
                <FormGroup check key={department}>
                  <Label check>
                    <Input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(department)}
                      checked={selectedDepartments.includes(department)}
                    />{" "}
                    {department}
                  </Label>
                </FormGroup>
              ))}
            </FormGroup>
            {formError && <Alert color="danger">{formError}</Alert>}
          </Form>
        )}
      </ModalBody>
      {!isSubmitted && (
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? "กำลังเพิ่ม..." : "เพิ่มฝ่าย"}
          </Button>
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
};

export default AddBudgetForm;
