import React from "react";

const Header = () => {
  return (
    <div className="transfer-header">
      <h4>รายการโอนงบประมาณ</h4>
    </div>
  );
};

export default Header;
