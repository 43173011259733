import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { deleteExpenditure } from "../../actions/budget/addAction";
import { fetchExpenditureDetails } from "../../actions/budget/fetchAction";

const DeleteExpenseModal = ({
  isOpen,
  toggle,
  yearId,
  sessionValue,
  sessionId,
  incomeTypeId,
  budgetName,
}) => {
  const dispatch = useDispatch();
  const [selectedExpenditureId, setSelectedExpenditureId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const authData = useSelector((state) => state.authState);
  const expenditureItems = useSelector(
    (state) => state.budgetState.expenditure.data.items
  );

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await dispatch(
        deleteExpenditure({
          expenditure_id: selectedExpenditureId,
        })
      );
      toggle();
      dispatch(
        fetchExpenditureDetails({
          group_year_fk: yearId,
          session_fk: sessionValue,
          group_income_fk: incomeTypeId,
          budget_name: budgetName,
          accessToken: authData.accessToken,
        })
      );
    } catch (error) {
      console.error("Error deleting expenditure:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>ลบรายการรายจ่าย</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="expenditureSelect">เลือกโครงการที่ต้องการลบ</Label>
            <Input
              type="select"
              id="expenditureSelect"
              value={selectedExpenditureId}
              onChange={(e) => setSelectedExpenditureId(e.target.value)}
            >
              <option value="">-- เลือกรายการ --</option>
              {expenditureItems.map((item) => (
                <option key={item._id} value={item._id}>
                  {item.expenditure_name} (คงเหลือ: {item.remain_amount})
                </option>
              ))}
            </Input>
          </FormGroup>
          {selectedExpenditureId && (
            <div>
              <h5>ตรวจสอบข้อมูลก่อนลบ</h5>
              <Table bordered>
                <thead>
                  <tr>
                    <th>ชื่อรายการ</th>
                    <th>ระดับ</th>
                    <th>งบคงเหลือ</th>
                  </tr>
                </thead>
                <tbody>
                  {expenditureItems
                    .filter((item) => item._id === selectedExpenditureId)
                    .map((item) => (
                      <tr key={item._id}>
                        <td>{item.expenditure_name}</td>
                        <td>
                          {item.level === "PRIMARY" ? "ประถมศึกษา" : "อนุบาล"}
                        </td>
                        <td>{item.remain_amount}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </div>
          )}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={handleDelete}
          disabled={!selectedExpenditureId || isSubmitting}
        >
          {isSubmitting ? "กำลังลบ..." : "ยืนยันการลบ"}
        </Button>
        <Button color="secondary" onClick={toggle}>
          ยกเลิก
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteExpenseModal;
