import React, { useState } from "react";
import Header from "../components/WithdrawalForm/Header";
import StepIndicator from "../components/WithdrawalForm/StepIndicator";
import Step1Form from "../components/WithdrawalForm/Step1Form/Step1Form";
import Step2Summary from "../components/WithdrawalForm/Step2Summary/Step2Summary";
import Step3Result from "../components/WithdrawalForm/Step3Result/Step3Result";
import FormActions from "../components/WithdrawalForm/FormActions/FormActions";
import ErrorHandling from "../components/WithdrawalForm/ErrorHandling/ErrorHandling";
import { useDispatch, useSelector } from "react-redux";
import { addWithdrawal } from "../actions/budget/addAction";

const WithdrawalForm = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState(null);

  const dispatch = useDispatch();
  const authData = useSelector((state) => state.authState);

  const handleFormDataChange = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      await dispatch(addWithdrawal({ formData, authData })).unwrap();
      setCurrentStep(3);
    } catch (error) {
      setError(error.message);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <Step1Form
            formData={formData}
            handleFormDataChange={handleFormDataChange}
          />
        );
      case 2:
        return <Step2Summary formData={formData} error={error} />;
      case 3:
        return <Step3Result formData={formData} error={error} />;
      default:
        return (
          <Step1Form
            formData={formData}
            handleFormDataChange={handleFormDataChange}
          />
        );
    }
  };

  return (
    <div>
      <Header title="แบบฟอร์มการเบิกงบประมาณ" />
      <StepIndicator currentStep={currentStep} />
      <ErrorHandling error={error} />
      {renderStepContent()}
      <FormActions
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        setError={setError}
        handleSubmit={handleSubmit}
        formData={formData}
      />
      <br />
    </div>
  );
};

export default WithdrawalForm;
