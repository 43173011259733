import React, { Fragment, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { useSelector } from "react-redux";
import Header from "../../components/List/Header/Header";
import Breadcrumb from "../../components/List/Breadcrumb/Breadcrumb";
import Navs from "../../components/List/Navs/Navs";
import WithdrawalList from "../../components/List/WithdrawalList/WithdrawalList";
import TransferList from "../../components/List/TransferList/TransferList";
import "./List.css";
import { Spinner } from "reactstrap";

const List = () => {
  const authState = useSelector((state) => state.authState);
  const allowedPositions = [
    "ADMIN",
    "MANAGEMENT",
    "HEADFINANCE",
    "FINANCE",
    "PLANNER",
    "PROCUREMENT",
  ];

  // Check if accessToken is missing
  if (!authState.accessToken) {
    return (
      <Container className="list-page">
        <Row>
          <Col>
            {authState.isLoading ? (
              <Fragment>
                <Container className="list-page">
                  <Row>
                    <Col>
                      <Spinner color="primary" />
                    </Col>
                  </Row>
                </Container>
              </Fragment>
            ) : (
              <Container className="list-page">
                <Row>
                  <Col>
                    <h4>ไม่พบข้อมูล</h4>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container className="list-page">
      <Header />
      <Breadcrumb />
      <Row>
        <Col>
          <Navs>
            <div label="Withdrawals">
              <WithdrawalList />
            </div>
            {allowedPositions.includes(authState.position) && (
              <div label="Transfers">
                <TransferList />
              </div>
            )}
          </Navs>
        </Col>
      </Row>
    </Container>
  );
};

export default List;
