// components/Layout/Layout.js
import React, { Fragment } from "react";
import CustomNavbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <Fragment>
      <CustomNavbar />
      <div className="container mt-3 flex-grow-1">
        <Outlet />
      </div>
      <Footer />
    </Fragment>
  );
};

export default Layout;
