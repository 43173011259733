import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSessionsByYear } from "../../actions/budget/fetchAction";
import { FormGroup, Label, Input, Form, Spinner } from "reactstrap";
import SelectIncomeTypeField from "./SelectIncomeTypeField";
import Alert from "../../config/Alert";

const SelectSessionField = ({ year }) => {
  const dispatch = useDispatch();
  const { data, isLoading, error } = useSelector(
    (state) => state.budgetState.sessions
  );
  const authData = useSelector((state) => state.authState);

  useEffect(() => {
    if (year) {
      console.log("year: " + year);
      dispatch(
        fetchSessionsByYear({ year, accessToken: authData.accessToken })
      );
    }
  }, [dispatch, year, authData]);

  const [selectedSession, setSelectedSession] = useState("");
  const [selectedSessionId, setSelectedSessionId] = useState("");

  const handleChange = (e) => {
    const sessionValue = e.target.value;
    setSelectedSession(sessionValue);
    const sessionObject = data.find(
      (session) => session.session === sessionValue
    );
    setSelectedSessionId(sessionObject?._id || "");
  };

  return (
    <>
      <Form>
        <FormGroup>
          <Label for="selectSession">เลือกปีงบประมาณ</Label>
          {isLoading && <Spinner color="primary" />}
          {error && <Alert color="danger">{error}</Alert>}
          <Input
            type="select"
            name="selectSession"
            id="selectSession"
            value={selectedSession}
            onChange={handleChange}
            disabled={isLoading}
          >
            <option value="">กรุณาเลือกปีงบประมาณ</option>
            {data.map((session) => (
              <option key={session._id} value={session.session}>
                {session.session}
              </option>
            ))}
          </Input>
        </FormGroup>
      </Form>
      {selectedSession && selectedSessionId && (
        <SelectIncomeTypeField
          year={year}
          session={selectedSession}
          sessionId={selectedSessionId}
        />
      )}
    </>
  );
};

export default SelectSessionField;
