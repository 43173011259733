import { createSlice } from "@reduxjs/toolkit";
import { fetchYearReport } from "../actions/reportAction";

const initialState = {
  isLoading: false,
  error: null,
  reportData: null,
};

const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {
    resetState: (state) => {
      state.isLoading = false;
      state.error = null;
      state.reportData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchYearReport.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.reportData = null;
      })
      .addCase(fetchYearReport.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
        state.reportData = action.payload;
      })
      .addCase(fetchYearReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload || "Failed to fetch yearly report";
        state.reportData = null;
      });
  },
});

export const { resetState } = reportSlice.actions;

export default reportSlice.reducer;
