import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export const generateExcel = (reportData) => {
  const worksheetData = [];

  // Add header row
  worksheetData.push([
    "budgetYear",
    "",
    "",
    "",
    "",
    "",
    "งบตั้งต้น",
    "การโอน",
    "การเบิก",
    "งบคงเหลือ",
  ]);

  // Add year level data
  worksheetData.push([
    reportData.budgetYear,
    "",
    "",
    "",
    "",
    "",
    reportData.yearInitAmount,
    reportData.yearTransfer?.amount || 0,
    reportData.yearWithdrawal,
    reportData.yearRemainAmount,
  ]);

  // Add income level data with null check
  if (reportData.incomeList && Array.isArray(reportData.incomeList)) {
    reportData.incomeList.forEach((income) => {
      worksheetData.push([
        "",
        income.incomeName,
        "",
        "",
        "",
        "",
        income.incomeInitAmount || 0,
        income.incomeTransfer?.amount || 0,
        income.incomeWithdrawal || 0,
        income.incomeRemainAmount || 0,
      ]);

      // Add budget level data with indentation and null check
      if (income.budgetList && Array.isArray(income.budgetList)) {
        income.budgetList.forEach((budget) => {
          worksheetData.push([
            "",
            "",
            budget.budgetName,
            "",
            "",
            "",
            budget.budgetInitAmount || 0,
            budget.budgetTransfer?.amount || 0,
            budget.budgetWithdrawal || 0,
            budget.budgetRemainAmount || 0,
          ]);

          // Add expenditure level data
          if (budget.expenditureList && Array.isArray(budget.expenditureList)) {
            budget.expenditureList.forEach((expenditure) => {
              worksheetData.push([
                "",
                "",
                "",
                expenditure.expenditureName,
                "",
                "",
                expenditure.expenditureInitAmount || 0,
                expenditure.expenditureTransfer?.amount || 0,
                expenditure.expenditureWithdrawal || 0,
                expenditure.expenditureRemainAmount || 0,
              ]);

              // Add project level data
              if (
                expenditure.projectList &&
                Array.isArray(expenditure.projectList)
              ) {
                expenditure.projectList.forEach((project) => {
                  worksheetData.push([
                    "",
                    "",
                    "",
                    "",
                    project.projectName,
                    "",
                    project.projectInitAmount || 0,
                    project.projectTransfer?.amount || 0,
                    project.projectWithdrawal || 0,
                    project.projectRemainAmount || 0,
                  ]);

                  // Add activity level data
                  if (
                    project.activityList &&
                    Array.isArray(project.activityList)
                  ) {
                    project.activityList.forEach((activity) => {
                      worksheetData.push([
                        "",
                        "",
                        "",
                        "",
                        "",
                        activity.activityName,
                        activity.activityInitAmount || 0,
                        activity.activityTransfer?.amount || 0,
                        activity.activityWithdrawal || 0,
                        activity.activityRemainAmount || 0,
                      ]);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  // Create a new workbook and worksheet
  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Report");

  // Create the Excel file and trigger download
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
  saveAs(blob, "report.xlsx");
};
