import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { fetchProjectDetails } from "../actions/budget/fetchAction";
import ExpenditureData from "../components/Project/ExpenditureData";
import ProjectErrorHandling from "../components/Project/ProjectErrorHandling";
import ExpenditureErrorHandling from "../components/Project/ExpenditureErrorHandling";
import {
  Spinner,
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import classnames from "classnames";
import Breadcrumb from "../components/Project/Breadcrumb";
import ProjectDetails from "../components/Project/ProjectDetails";
import ProjectBudgetChart from "../components/Project/ProjectBudgetChart";
import ActivityBudgetChart from "../components/Project/ActivityBudgetChart";
import LeftSidebar from "../components/Project/LeftSidebar";

const Project = () => {
  const { projectName } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { budgetId, expenditureId, accessToken } = location.state;

  const {
    data: expenditureData,
    isLoading: expenditureIsLoading,
    error: expenditureError,
  } = useSelector((state) => state.budgetState.expenditure);

  const {
    data: projectData,
    isLoading: projectIsLoading,
    error: projectError,
  } = useSelector((state) => state.budgetState.project);

  useEffect(() => {
    dispatch(
      fetchProjectDetails({ budget_list_id: expenditureId, accessToken })
    );
  }, [dispatch, expenditureId, accessToken]);

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const hasProjects = projectData?.data && projectData.data.length > 0;

  const refreshProjects = () => {
    dispatch(
      fetchProjectDetails({ budget_list_id: expenditureId, accessToken })
    );
  };

  const getLevelName = (level) => {
    switch (level) {
      case "PRIMARY":
        return "ประถมศึกษา";
      case "KINDERGARTEN":
        return "อนุบาล";
      default:
        return level;
    }
  };

  useEffect(() => {
    if (expenditureData) {
      // console.log("expenditureData: " + JSON.stringify(expenditureData));
    }
  }, [expenditureData]);

  const totalBudget =
    expenditureData &&
    expenditureData.items &&
    expenditureData.items.find((item) => item._id === expenditureId)
      ?.init_amount;

  return (
    <Fragment>
      <Container>
        <h4>โครงการ: {projectName}</h4>
        <Breadcrumb projectName={projectName} />
        <Row>
          <Col sm="12">
            <h5>ข้อมูลรายจ่าย</h5>
            {expenditureIsLoading && <Spinner color="primary" />}
            <ExpenditureErrorHandling error={expenditureError} />
            {!expenditureIsLoading && !expenditureError && (
              <ExpenditureData
                details={{ ...location.state }}
                expenditureData={expenditureData.items && expenditureData.items}
                getLevelName={getLevelName}
              />
            )}
          </Col>
        </Row>
        <br />
        <Row>
          <Col sm="3">
            {!expenditureIsLoading && !expenditureError && (
              <LeftSidebar
                details={{ ...location.state }}
                hasProjects={hasProjects}
                expenditureId={expenditureId}
                refreshProjects={refreshProjects}
              />
            )}
          </Col>
          <Col sm="9">
            <h5>ข้อมูลโครงการและกิจกรรม</h5>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  ข้อมูล
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  แผนภูมิ
                </NavLink>
              </NavItem>
            </Nav>
            <br />
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <h5>รายละเอียดโครงการ</h5>
                    {projectIsLoading && <Spinner color="primary" />}
                    <ProjectErrorHandling error={projectError} />
                    {!projectIsLoading && !projectError && (
                      <div>
                        <ProjectDetails projectData={projectData} />
                      </div>
                    )}
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <h5>แผนภูมิ</h5>
                    {projectIsLoading && <Spinner color="primary" />}
                    <ProjectErrorHandling error={projectError} />
                    {!projectIsLoading &&
                      !projectError &&
                      projectData &&
                      projectData.data && (
                        <div>
                          <UncontrolledAccordion defaultOpen="1">
                            <AccordionItem>
                              <AccordionHeader targetId="1">
                                แผนภูมิโครงการ
                              </AccordionHeader>
                              <AccordionBody accordionId="1">
                                <ProjectBudgetChart
                                  projectData={projectData.data}
                                  totalBudget={totalBudget}
                                />
                              </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                              <AccordionHeader targetId="2">
                                แผนภูมิกิจกรรม
                              </AccordionHeader>
                              <AccordionBody accordionId="2">
                                <ActivityBudgetChart
                                  projectData={projectData.data}
                                />
                              </AccordionBody>
                            </AccordionItem>
                          </UncontrolledAccordion>
                        </div>
                      )}
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
      <br />
    </Fragment>
  );
};

export default Project;
