import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Table,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { addActivity } from "../../actions/budget/addAction";

const AddActivityForm = ({
  isOpen,
  toggle,
  expenditureId,
  refreshProjects,
}) => {
  const dispatch = useDispatch();
  const { data: projectData, error: projectError } = useSelector(
    (state) => state.budgetState.project
  );
  const [selectedProject, setSelectedProject] = useState("");
  const [activityName, setActivityName] = useState("");
  const [initAmount, setInitAmount] = useState("");
  const [resTeacher, setResTeacher] = useState("");
  const [showSummary, setShowSummary] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [projectDetails, setProjectDetails] = useState({
    totalInitAmount: 0,
    totalRemainAmount: 0,
  });

  useEffect(() => {
    if (projectData && projectData.data && projectData.data.length > 0) {
      if (selectedProject) {
        const project = projectData.data.find(
          (project) => project.project_name === selectedProject
        );
        if (project) {
          const totalInitAmount = project.activity_list.reduce(
            (sum, activity) => sum + activity.init_amount,
            0
          );
          const totalRemainAmount = project.activity_list.reduce(
            (sum, activity) => sum + activity.remain_amount,
            0
          );
          setProjectDetails({ totalInitAmount, totalRemainAmount });
        }
      }
    }
  }, [selectedProject, projectData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!selectedProject || !activityName || !initAmount || !resTeacher) {
      setError("กรุณากรอกข้อมูลให้ครบทุกช่อง.");
      return;
    }
    setError(null);
    setShowSummary(true);
  };

  const confirmSubmit = () => {
    const payload = {
      project_name: selectedProject,
      budget_list_id: expenditureId,
      activity: {
        activity_name: activityName,
        res_teacher: resTeacher,
        init_amount: initAmount,
      },
    };

    console.log("Payload to be submitted:", payload);

    dispatch(addActivity(payload))
      .then(() => {
        setSuccess(true);
        setShowSummary(false);
      })
      .catch((err) => {
        setError(err.message || "Error submitting the form. Please try again.");
      });
  };

  const handleClose = () => {
    setError(null);
    setSuccess(false);
    toggle();
    refreshProjects();
  };

  return (
    <Modal isOpen={isOpen} toggle={handleClose}>
      <Form onSubmit={handleSubmit}>
        <ModalHeader toggle={handleClose}>เพิ่มกิจกรรม</ModalHeader>
        <ModalBody>
          {error && (
            <Alert color="danger">
              {typeof error === "string" ? error : "เกิดข้อผิดพลาด"}
            </Alert>
          )}

          {projectError && (
            <Alert color="danger">
              {typeof projectError === "string"
                ? projectError
                : "เกิดข้อผิดพลาดในการดึงข้อมูลโครงการ"}
            </Alert>
          )}

          {showSummary ? (
            <div>
              <h5>สรุปข้อมูลที่จะส่งไป</h5>
              <Table bordered>
                <thead>
                  <tr>
                    <th>ชื่อโครงการ</th>
                    <td>{selectedProject}</td>
                  </tr>
                  <tr>
                    <th>รหัสรายจ่าย</th>
                    <td>{expenditureId}</td>
                  </tr>
                  <tr>
                    <th>ชื่อกิจกรรม</th>
                    <td>{activityName}</td>
                  </tr>
                  <tr>
                    <th>งบตั้งต้น</th>
                    <td>{initAmount}</td>
                  </tr>
                  <tr>
                    <th>ครูที่รับผิดชอบ</th>
                    <td>{resTeacher}</td>
                  </tr>
                </thead>
              </Table>
              <Button
                color="primary"
                onClick={confirmSubmit}
                className="mt-2 w-100"
              >
                ยืนยันและส่งข้อมูล
              </Button>
              <Button
                color="secondary"
                onClick={() => setShowSummary(false)}
                className="mt-2 w-100"
              >
                กลับไปแก้ไข
              </Button>
            </div>
          ) : success ? (
            <div>
              <Alert color="success">เพิ่มกิจกรรมสำเร็จ!</Alert>
              <Button
                color="primary"
                onClick={handleClose}
                className="mt-2 w-100"
              >
                ตกลง
              </Button>
            </div>
          ) : (
            <>
              <FormGroup>
                <Label for="projectSelect">เลือกโครงการ</Label>
                <Input
                  type="select"
                  name="project"
                  id="projectSelect"
                  value={selectedProject}
                  onChange={(e) => setSelectedProject(e.target.value)}
                  disabled={success}
                >
                  <option value="" disabled>
                    -- กรุณาเลือกโครงการ --
                  </option>
                  {projectData &&
                    projectData.data &&
                    projectData.data.map((project) => (
                      <option key={project._id} value={project.project_name}>
                        {project.project_name}
                      </option>
                    ))}
                </Input>
              </FormGroup>
              {selectedProject && (
                <div>
                  <p>
                    งบตั้งต้นทั้งหมดของกิจกรรม: {projectDetails.totalInitAmount}
                  </p>
                  <p>
                    งบคงเหลือทั้งหมดของกิจกรรม:{" "}
                    {projectDetails.totalRemainAmount}
                  </p>
                </div>
              )}
              <FormGroup>
                <Label for="activityName">ชื่อกิจกรรม</Label>
                <Input
                  type="text"
                  name="activityName"
                  id="activityName"
                  value={activityName}
                  onChange={(e) => setActivityName(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="initAmount">งบตั้งต้น</Label>
                <Input
                  type="number"
                  name="initAmount"
                  id="initAmount"
                  value={initAmount}
                  onChange={(e) => setInitAmount(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="resTeacher">ครูที่รับผิดชอบ</Label>
                <Input
                  type="text"
                  name="resTeacher"
                  id="resTeacher"
                  value={resTeacher}
                  onChange={(e) => setResTeacher(e.target.value)}
                />
              </FormGroup>
            </>
          )}
        </ModalBody>
        {!showSummary && !success && (
          <ModalFooter>
            <Button type="submit" color="primary">
              บันทึก
            </Button>{" "}
            <Button color="secondary" onClick={handleClose}>
              ยกเลิก
            </Button>
          </ModalFooter>
        )}
      </Form>
    </Modal>
  );
};

export default AddActivityForm;
