import React from "react";
import { TabPane, Card, CardBody, CardTitle, CardText } from "reactstrap";
import LinkDocument from "./LinkDocument";

const WithdrawalDetails = ({ withdrawal, year, session }) => (
  <TabPane tabId="1">
    <Card className="mt-3">
      <CardBody>
        <CardTitle tag="h5">รายการเบิก</CardTitle>
        <CardText>รหัสรายการเบิก: {withdrawal._id}</CardText>
        <CardText>ชื่อรายการเบิก: {withdrawal.withdrawal_name}</CardText>
        <CardText>ประเภทการเบิก: {withdrawal.withdrawal_type}</CardText>
        <CardText>
          วันเวลา: {new Date(withdrawal.createdAt).toLocaleString()}
        </CardText>
        <CardText>
          จำนวนเงินที่ต้องการเบิก: {withdrawal.amount_request.toLocaleString()}
        </CardText>
        {withdrawal.amount_actual && (
          <CardText>
            จำนวนเงินที่เบิกได้จริง: {withdrawal.amount_actual.toLocaleString()}
          </CardText>
        )}
        {withdrawal.link_doc ? (
          <CardText>
            เอกสาร:{" "}
            <a
              href={withdrawal.link_doc}
              target="_blank"
              rel="noopener noreferrer"
            >
              {withdrawal.link_doc}
            </a>
          </CardText>
        ) : null}
        <LinkDocument withdrawal={withdrawal} year={year} session={session} />
        {withdrawal.comment ? (
          <CardText>หมายเหตุ: {withdrawal.comment}</CardText>
        ) : null}
      </CardBody>
    </Card>
    <Card className="mt-3">
      <CardBody>
        <CardTitle tag="h5">สถานะ | ขั้นตอน</CardTitle>
        <CardText>สถานะ: {withdrawal.status.name}</CardText>
        <CardText>ขั้นตอน: {withdrawal.status.stage}</CardText>
      </CardBody>
    </Card>
    <Card className="mt-3">
      <CardBody>
        <CardTitle tag="h5">ผู้ทำรายการ</CardTitle>
        <CardText>ผู้เบิกงบประมาณ: {withdrawal.withdrawal_username}</CardText>
        {withdrawal.res_planner_username && (
          <CardText>
            ผู้ตรวจสอบแบบฟอร์ม: {withdrawal.res_planner_username}
          </CardText>
        )}
        {withdrawal.res_procurement_username && (
          <CardText>
            ผู้ตรวจสอบเอกสาร: {withdrawal.res_procurement_username}
          </CardText>
        )}
        {withdrawal.res_finance_username && (
          <CardText>ผู้ดูแลการเบิก: {withdrawal.res_finance_username}</CardText>
        )}
      </CardBody>
    </Card>
  </TabPane>
);

export default WithdrawalDetails;
