import React, { Fragment, useState, useEffect } from "react";
import {
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  CardTitle,
  Alert,
} from "reactstrap";

const AmountRequest = ({ formData, handleFormDataChange }) => {
  const [error, setError] = useState("");

  const handleChange = (e) => {
    if (e.target.name === "amount_request") {
      const valueAmount = e.target.value;
      let maxAmount = 0;

      if (formData.withdrawal_type === "activity") {
        maxAmount = formData.activity_remain || 0;
      } else if (formData.withdrawal_type === "expenditure") {
        maxAmount = formData.expenditure_remain || 0;
      }

      if (Number(valueAmount) > maxAmount) {
        setError(`จำนวนเงินที่ต้องการเบิกต้องไม่เกิน ${maxAmount}`);
      } else {
        setError("");
      }
      handleFormDataChange({ amount_request: valueAmount });
    } else if (e.target.name === "withdrawal_name") {
      const valueName = e.target.value;
      handleFormDataChange({ withdrawal_name: valueName });
    }
  };

  return (
    <Fragment>
      {(formData.withdrawal_type === "expenditure" && formData.expenditure) ||
      (formData.withdrawal_type === "activity" && formData.activity) ? (
        <Card className="mb-4">
          <CardBody>
            <CardTitle tag="h3" className="mb-3">
              ข้อมูลจำนวนเงินที่ต้องการเบิก
            </CardTitle>
            <FormGroup>
              <Label for="withdrawal_name">ชื่อรายการเบิก</Label>
              <Input
                type="text"
                name="withdrawal_name"
                id="withdrawal_name"
                value={formData.withdrawal_name || ""}
                onChange={handleChange}
              />

              {/* {error && (
                <Fragment>
                  <br />
                  <Alert color="danger">{error}</Alert>
                </Fragment>
              )} */}
            </FormGroup>
            <FormGroup>
              <Label for="amount_request">จำนวนเงินที่ต้องการเบิก</Label>
              <Input
                type="number"
                name="amount_request"
                id="amount_request"
                value={formData.amount_request || ""}
                onChange={handleChange}
              />

              {error && (
                <Fragment>
                  <br />
                  <Alert color="danger">{error}</Alert>
                </Fragment>
              )}
            </FormGroup>

            <FormGroup>
              <Label for="description">
                * เมื่อใส่จำนวนเบิกแล้ว ให้คลิกปุ่ม "ส่งเรื่องเบิกงบประมาณ"
                เพื่อไปยัง Step ถัดไป
              </Label>
            </FormGroup>
          </CardBody>
        </Card>
      ) : null}
    </Fragment>
  );
};

export default AmountRequest;
