export const sortByDate = (data, order = "asc") => {
  return [...data].sort((a, b) => {
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return order === "asc" ? dateA - dateB : dateB - dateA;
  });
};

export const sortByAmount = (data, order = "asc") => {
  return [...data].sort((a, b) => {
    const amountA = a.amount_actual || a.amount_request;
    const amountB = b.amount_actual || b.amount_request;
    return order === "asc" ? amountA - amountB : amountB - amountA;
  });
};

export const sortByStatus = (data, order = "asc") => {
  return [...data].sort((a, b) => {
    const statusA = a.status.name.toLowerCase();
    const statusB = b.status.name.toLowerCase();
    if (statusA < statusB) return order === "asc" ? -1 : 1;
    if (statusA > statusB) return order === "asc" ? 1 : -1;
    return 0;
  });
};
