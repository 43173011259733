import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { config } from "../../config/config";
import handleError from "../handleError";

const Finance_API = config.Finance_API;

// Fetch Budget Years
export const fetchBudgetYears = createAsyncThunk(
  "budget/fetchBudgetYears",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const response = await axios.get(
        `${Finance_API}/api/budget/budgetyears`,
        { headers }
      );
      return response.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

// Fetch Budget Years Onlu status OPEN
export const fetchBudgetYearsOnlyOpen = createAsyncThunk(
  "budget/fetchBudgetYearsOnlyOpen",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const response = await axios.get(
        `${Finance_API}/api/budget/budgetyears/open`,
        { headers }
      );
      return response.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

// Fetch Sessions By Year
export const fetchSessionsByYear = createAsyncThunk(
  "budget/fetchSessionsByYear",
  async (data, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const response = await axios.get(
        `${Finance_API}/api/budget/getsession/${data.year}`,
        { headers }
      );
      return response.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

// Fetch Income Types
export const fetchIncomeTypes = createAsyncThunk(
  "budget/fetchIncomeTypes",
  async ({ year, session }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const response = await axios.get(
        `${Finance_API}/api/budget/getincometypes/${year}/${session}`,
        { headers }
      );
      return response.data.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

export const fetchBudgetItemList = createAsyncThunk(
  "budget/fetchBudgetItemList",
  async (
    { group_year_fk, session_fk, group_income_fk },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const body = {
        group_year_fk,
        session_fk,
        group_income_fk,
      };

      const response = await axios.post(
        `${Finance_API}/api/budget/getbudgetitemlist`,
        body,
        { headers }
      );
      return response.data.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

export const fetchExpenditureDetails = createAsyncThunk(
  "budget/fetchExpenditureDetails",
  async (
    { group_year_fk, session_fk, group_income_fk, budget_name },
    { getState, rejectWithValue }
  ) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const body = {
        group_year_fk,
        session_fk,
        group_income_fk,
        budget_name,
      };
      const response = await axios.post(
        `${Finance_API}/api/budget/getexpenditurelist`,
        body,
        { headers }
      );
      return response.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);

export const fetchProjectDetails = createAsyncThunk(
  "project/fetchProjectDetails",
  async ({ budget_list_id }, { getState, rejectWithValue }) => {
    try {
      const { authState } = getState();
      const token = authState.accessToken;
      const headers = { "x-auth-token": token };
      const body = { budget_list_id };
      const response = await axios.post(
        `${Finance_API}/api/project/getprojectlist`,
        body,
        { headers }
      );
      // console.log("response.data: " + JSON.stringify(response.data));
      return response.data;
    } catch (error) {
      return handleError(error, rejectWithValue);
    }
  }
);
